import * as React from 'react';
import { connect } from 'react-redux';
import { __, __r } from 'react-i18n';
import { Link } from 'react-router';
import styled from 'styled-components';
import { Loader } from 'eshop-defaults';
import { onlyPublic } from '../../utilities/auth';
import { rem } from 'polished';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';

interface State {
  loading: boolean;
  message: string;
  success: boolean;
}

interface Props {
  API: any;
  params: {
    code: string;
  };
}

class Activation extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: '',
      success: false,
    };
  }

  public componentDidMount() {
    const { API } = this.props;

    if (!isSSR()) {
      API.activateUser({
        activationCode: this.props.params.code,
      })
        .then(() => {
          this.setState({
            loading: false,
            message: __(
              'Aktivácia prebehla úspešne. Pre pokračovanie sa prosím prihláste.',
            ),
            success: true,
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            message: __(
              'Pri aktivácií účtu nastala neznáma chyba. Skúste to prosím znova neskôr.',
            ),
            success: false,
          });
        });
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.loading === true && (
          <Loader
            dim={false}
            style={{ marginTop: '100px', paddingBottom: '50px' }}
            loading={true}
          />
        )}
        <AuthContainer>
          {this.state.loading === false && this.state.message && (
            <Message>{this.state.message}</Message>
          )}

          {this.state.loading === false && this.state.success === true && (
            <StyledLink
              to={__r('routes:prihlasenie', '/prihlasenie')}
              style={{ textDecoration: 'none' }}
            >
              {__('Prihlásiť sa užívateľ')}
            </StyledLink>
          )}
        </AuthContainer>
      </React.Fragment>
    );
  }
}

const AuthContainer = styled.div`
  margin-top: 80px;
  width: 40%;
  margin-left: 30%;
  margin-bottom: 80px;
  text-align: center;

  @media only screen and (max-width: 550px) {
    width: 80%;
    margin-left: 10%;
  }
`;

const Message = styled.h1`
  font-size: ${rem(24)};
  font-weight: 500;
  line-height: ${rem(32)};
  margin-bottom: ${rem(32)};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration-color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 300;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  display: inline-block;
  text-decoration: underline;
  margin: 0;
  margin-left: ${rem(4)};
`;

export default connect()(Activation);
