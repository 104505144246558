import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  FlexCol,
  FlexRow,
  CorporateArticleList as ArticleList,
  SitemapList,
  DsiPagination as Pagination,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import {
  loadCMSCategoriesTree,
  loadCMSArticles,
  loadBlogMenu,
  loadSearchCMSArticles,
} from './actions';
import {
  sitemapSelector,
  allArticlesSelector,
  sitemapIsFetchingSelector,
  articlesIsFetchingSelector,
  sitemapByIdSelector,
  linksIsFetchingSelector,
  allLinksSelector,
  currentArticleNameSelector,
  searchArticlesSelector,
  searchArticlesIsFetchngSelector,
} from './selectors';
import API, { ThenArg } from '../../services/API';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { Navigation } from '../../components/Navigation/Navigation';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { langSelector } from '../App/selectors';
import { setIsBreadcrumbVisible } from '../App/actions';
import { __ } from 'react-i18n';

interface Props {
  dispatch: (action: any) => void;
  isFetching: boolean;
  articles: ThenArg<typeof API.loadArticles>;
  lang: string;
}

class ArticlesSearch extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, location } = props;
    const {
      query: { q, offset, tag },
    } = location;

    dispatch(setIsBreadcrumbVisible(false));
    await dispatch(loadSearchCMSArticles(q, 15, offset, tag));
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
  }

  public render() {
    const {
      location: {
        query: { q },
      },
    } = this.props;
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: `${__('Vyhľadávanie článkov')}: ${q}` }} />
          {this.renderArticles()}
        </Wrapper>
      </>
    );
  }

  public renderArticles = () => {
    const {
      location,
      location: {
        query: { q, tag },
        pathname,
      },
      articles: allArticles,
      isFetching,
    } = this.props;
    const articles = prop(allArticles, 'articles');
    const total = prop(allArticles, 'total', 0);
    // const subid = location.query.subid ? location.query.subid.toString() : '-1';
    const offset = prop(location, 'query.offset', 0);
    const firstArticleTags = prop(articles, '0.tags', []);
    const tagObj = firstArticleTags
      ? firstArticleTags.find(t => t.id.toString() === tag)
      : null;

    return (
      <ContentWrapper>
        <ArticlesWrapper>
          {q ? (
            <Title>
              {`${__('Hľadaný výraz')}: `}
              <b>"{q}"</b>
            </Title>
          ) : tagObj ? (
            <Title>
              {`${__('Tag')}: `}
              <b>"{tagObj.name}"</b>
            </Title>
          ) : null}
          <ArticleList
            sitemapsById={{}}
            articles={articles}
            isFetching={isFetching}
            hasListItems={true}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={total}
            limit={15}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </ArticlesWrapper>
      </ContentWrapper>
    );
  };

  public handleOffsetChange = (newOffset: number) => {
    // const query = this.props.location.query.q;
    // const searchQuery = query
    //   ? `?q=${query}&offset=${newOffset}`
    //   : `?offset=${newOffset}`;
    // this.props.dispatch(
    //   push({
    //     pathname: this.props.location.pathname,
    //     search: searchQuery,
    //   }),
    // );
  };

  public getPaginationQuery = () => {
    const { location } = this.props;
    const {
      query: { q, tag },
    } = location;

    return q
      ? `${location.pathname}?q=${q}`
      : tag
      ? `${location.pathname}?tag=${tag}`
      : `${location.pathname}?`;
  };
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0 ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(16)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${rem(80)};
  flex-direction: row;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const ArticlesWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${rem(56)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(32)};
  `}

  ${({ theme }) => theme.mediab.l925`
    padding: 0;
  `}

  ${({ theme }) => theme.mediab.m580`
    padding-top: ${rem(16)};
  `}
`;

const NavigationWrapper = styled(FlexRow)`
  max-width: ${rem(304)};
  flex: 1 1 ${rem(304)};

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const Title = styled.h1`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const mapStateToProps = state => {
  return {
    isFetching: searchArticlesIsFetchngSelector(state),
    articles: searchArticlesSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ArticlesSearch' })(withRouter(ArticlesSearch)),
);
