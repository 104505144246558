import { prop } from 'eshop-defaults/lib';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { stripHtmlTags } from '.';
import { getImagePath } from './product';
import { resolveBaseUrlByLang } from '../configureTrans';

export function isEmptyObj(obj) {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return true;
}

export function getFirstValidMetaTagValue(values: any[]) {
  for (const value of values) {
    if (value && !isEmptyObj(value)) return value;
  }
  return null;
}

export function getCmsMetaTags(contentData: any) {
  const content = prop(contentData, 'json_content');
  if (!content) {
    return {};
  }

  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }
  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }
  if (
    !metaTags ||
    !metaTags.og_description ||
    Object.keys(metaTags.og_description).length === 0
  ) {
    metaTags.description =
      content && content.annotation ? stripHtmlTags(content.annotation) : '';
  }

  const ogTitle =
    prop(content, 'meta.og_title') ||
    prop(content, 'meta.title') ||
    prop(content, 'name');
  if (ogTitle) {
    metaTags.og_title = ogTitle;
  }

  const ogDescription =
    prop(content, 'meta.og_description') ||
    prop(content, 'meta.description') ||
    prop(content, 'annotation');
  if (ogDescription) {
    metaTags.og_description = ogDescription ? stripHtmlTags(ogDescription) : '';
  }

  const ogImage = prop(content, 'meta.og_image') || prop(contentData, 'image');
  if (ogImage) {
    metaTags.og_image = getImagePath(ogImage, { width: 1000, height: 1000 });
  }

  return metaTags;
}

export function getEbookMetaTags(ebook: any) {
  const content = prop(ebook, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getAuthorMetaTags(author: any) {
  const content = prop(author, 'json_content');
  let metaTags: any = {};
  if (content && content.meta) {
    metaTags = content.meta;
  }

  if (!metaTags || !metaTags.title || !metaTags.title.length) {
    metaTags.title = content && content.name;
  }

  return metaTags;
}

export function getProductMetaTags(product, lang, hostname) {
  const baseUrl = resolveBaseUrlByLang(hostname, lang);

  const productPublish = prop(product, 'publish', []).find(
    p => p.lang_id === lang,
  );
  let metaTags: any = {};
  if (productPublish && productPublish.content.id !== null) {
    metaTags = prop(productPublish, 'content.json_content.meta');

    if (!metaTags.title || !metaTags.title.length) {
      metaTags.title = prop(productPublish, 'product_name') || product.name;
    }

    if (!metaTags.description) {
      metaTags.description = prop(
        productPublish,
        'content.json_content.short_description',
      )
        ? prop(productPublish, 'content.json_content.short_description')
        : stripHtmlTags(
            prop(productPublish, 'content.json_content.annotation'),
          );
    }
  }
  if (!metaTags.og_title) {
    metaTags.og_title =
      prop(product, 'publish.0.content.json_content.meta.og_title', '') ||
      prop(productPublish, 'product_name') ||
      prop(product, 'name', '');
  }

  if (!metaTags.og_type) {
    metaTags.og_type =
      prop(product, 'publish.0.content.json_content.meta.og_type', '') ||
      'website';
  }

  if (!metaTags.og_description) {
    metaTags.og_description =
      prop(product, 'publish.0.content.json_content.meta.og_description', '') ||
      stripHtmlTags(
        prop(product, 'publish.0.content.json_content.short_description', ''),
      );
  }

  if (!metaTags.og_url) {
    metaTags.og_url =
      prop(product, 'publish.0.content.json_content.meta.og_url', '') ||
      `${baseUrl}${prop(product, 'url', '').replace(new RegExp(`^/`), '')}`;
  }

  if (!metaTags.og_image) {
    metaTags.og_image =
      prop(product, 'publish.0.content.json_content.meta.og_image', '') ||
      getImagePath(prop(product, 'picture'), { width: 1005, height: 1005 });
  }

  if (!metaTags.og_image_width) {
    metaTags.og_image_width = 1005;
  }
  if (!metaTags.og_image_height) {
    metaTags.og_image_height = 1005;
  }
  return metaTags;
}

export function getCategoryMetaTags(category, lang, hostname) {
  const baseUrl = resolveBaseUrlByLang(hostname, lang);

  if (!category) {
    return null;
  }
  // const content = prop(category, 'content', {});
  const metaTags: any =
    prop(category, 'publish.0.content.json_content.meta') ||
    prop(category, 'content.0.json_content.meta', {});
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (!metaTags.title || isEmptyObj(metaTags.title)) {
    metaTags.title =
      prop(category, 'publish.0.content.json_content.meta.title') ||
      prop(category, 'publish.0.category_name') ||
      prop(category, 'content.0.name', prop(category, 'category_name', '')) ||
      prop(category, 'category_name', '');
  }

  if (!metaTags.description || isEmptyObj(metaTags.description)) {
    metaTags.description =
      prop(category, 'publish.0.content.json_content.meta.description') ||
      prop(category, 'content.0.json_content.meta.description', '') ||
      prop(category, 'publish.0.category_description') ||
      prop(category, 'publish.0.content.json_content.body', '')
        ? stripHtmlTags(
            prop(category, 'publish.0.content.json_content.body', ''),
          )
        : stripHtmlTags(prop(category, 'content.0.json_content.body', ''));
  }

  if (!metaTags.keywords || isEmptyObj(metaTags.keywords)) {
    metaTags.keywords =
      prop(category, 'publish.0.content.json_content.meta.keywords') ||
      prop(category, 'content.0.json_content.meta.keywords', '');
  }

  metaTags.og_title =
    prop(category, 'publish.0.content.json_content.meta.og_title') ||
    prop(category, 'publish.0.category_name') ||
    prop(category, 'content.0.json_content.meta.og_title', '') ||
    prop(category, 'category_name', '');

  metaTags.og_type =
    prop(category, 'publish.0.content.json_content.meta.og_type ') ||
    prop(category, 'content.0.json_content.meta.og_type', '') ||
    'website';

  metaTags.og_description =
    prop(category, 'publish.0.content.json_content.meta.og_description') ||
    prop(category, 'content.0.json_content.meta.og_description', '') ||
    metaTags.description;

  metaTags.og_url =
    prop(category, 'publish.0.content.json_content.meta.og_url') ||
    prop(category, 'content.0.json_content.meta.og_url', '') ||
    `${baseUrl}${resolveCategoryUrl(
      prop(category, 'category_id'),
      prop(category, 'url'),
      'CATEGORYTEXT/URL',
    ).replace(new RegExp(`^/`), '')}`;

  metaTags.og_image =
    prop(category, 'publish.0.content.json_content.meta.og_image') ||
    prop(category, 'content.0.json_content.meta.og_image', '') ||
    getImagePath(prop(category, 'image'), { width: 1005, height: 1005 });

  metaTags.og_image_width = 1005;
  metaTags.og_image_height = 1005;

  return metaTags;
}

export function getFooterArticleMetaTags(article) {
  // const content = prop(category, 'content', {});
  const metaTags: any = {};
  // if (content && content.id !== null) {
  //   const tags = prop(content.json_content, 'meta');
  //   if (tags && !isEmptyObj(tags)) {
  //     metaTags = tags;
  //   }
  // }

  if (article && (!metaTags.title || isEmptyObj(metaTags.title))) {
    metaTags.title = article.name;
  }

  return metaTags;
}
