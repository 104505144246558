import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { FlexRowCenter, FlexCol } from 'eshop-defaults';
import {
  homeArticlesSelector,
  notFoundArticlesSelector,
} from '../Home/selectors';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { langSelector } from '../Category/selectors';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { loadHomeArticles, loadNotFoundArticles } from '../Home/actions';
import NotFoundPageArticles from '../../components/Article/NotFoundPageArticles';
import { prop } from '../../utilities';
import { setIsBreadcrumbVisible, setNotFound } from '../App/actions';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.blogColors.primary};
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
`;

const WrapperContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  padding: 0 ${rem(12)};

  ${({ theme }) => theme.mediab.m580`
    padding: 0 ;
  `};
`;

const TopWrapper = styled(FlexCol)`
  padding: ${rem(200)} 0;
  border-bottom: ${rem(1)} solid #606060;
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m810`
    border: 0;
  `};

  ${({ theme }) => theme.mediab.m580`
    padding: ${rem(70)} ${rem(6)};
  `};
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(72)};
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: ${rem(24)};
`;

const NotFoundWrapper = styled(FlexRowCenter)`
  text-align: center;
  margin-top: ${rem(16)};
  font-size: ${rem(36)};
  color: white;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
  `}
`;

const Icon = styled.img`
  width: ${rem(60)};
  margin-left: ${rem(10)};

  ${({ theme }) => theme.mediab.m580`
     width: ${rem(30)};
  `}
`;

const HomeLink = styled(Link)`
  background-color: transparent;
  color: white;
  font-weight: 500;
  padding: ${rem(16)};
  border: ${rem(1)} solid #606060;
  width: initial;
  margin: 0 auto;
  text-decoration: none;
  max-width: ${rem(215)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${rem(20)};
  color: white;
  font-weight: 500;
  margin: 0;
  margin-bottom: ${rem(32)};
`;

const DesktopWrapper = styled.div`
  ${({ theme }) => theme.mediab.m810`
    display: none;
  `}
`;

interface Props {
  lang: string;
  articles: {
    isFetching: boolean;
    articlesData: ThenArg<typeof API.loadArticles>;
  };
  dispatch: any;
  notFoundArticles: ThenArg<typeof API.loadArticles>;
}

class NotFound extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(parentProps) {
    const { dispatch } = parentProps;
    try {
      dispatch(setNotFound(true));
      dispatch(setIsBreadcrumbVisible(false));
      await Promise.all([
        (await dispatch(loadHomeArticles()),
        await dispatch(loadNotFoundArticles())),
      ]);
      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
  }

  public render() {
    const { articles, lang, location } = this.props;
    const isFetchingNotFoundArticles = prop(
      this.props.notFoundArticles,
      'isFetching',
      false,
    );

    let notFoundArticles = [];
    const pathname = this.props?.location?.pathname;
    if (this.props.notFoundArticles?.articles?.length) {
      notFoundArticles = prop(this.props.notFoundArticles, 'articles', [])
        .filter(article => {
          if (
            pathname &&
            article.url &&
            pathname.replace(/^\/+/, '') === article.url.replace(/^\/+/, '')
          ) {
            return false;
          }
          return true;
        })
        .slice(0, 3);
    }

    if (lang === 'cz' && location.pathname === '/cz') {
      return <Loader small={false} />;
    }

    return (
      <Wrapper>
        <MetaTags tags={{ title: __('Stránka nenájdená') }} />
        <WrapperContainer>
          <TopWrapper>
            <Text>404</Text>
            <NotFoundWrapper>
              {__('Hľadaná stránka neexistuje')}
              <Icon alt="" src="/images/blog_assets/crying.png" />
            </NotFoundWrapper>
            <HomeLink to="/">{__('Prejsť na hlavnú stránku')}</HomeLink>
          </TopWrapper>
          <DesktopWrapper>
            <Title>{__('Alebo si pozrite najnovšie články...')}</Title>
            <NotFoundPageArticles
              isFetching={isFetchingNotFoundArticles}
              articles={notFoundArticles}
            />
          </DesktopWrapper>
        </WrapperContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: homeArticlesSelector(state),
    lang: langSelector(state),
    notFoundArticles: notFoundArticlesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NotFound' })(withRouter(NotFound)),
);
