import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexColCenter, FlexRow, FlexRowCenter, prop } from 'eshop-defaults';
import { css } from '../../theme/styled-components';

const Wrapper = styled(FlexRow)`
  position: relative;
 width: 100%;
 justify-content: space-between;
    height: 100%;
  /*  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 30vw; */

  justify-content: space-around;

  @media (max-width: 1920px) {
    /* padding: 0 8vw; */
  }

  @media (max-width: 1320px) {
    /* padding: 0 ${rem(24)}; */
  }

  @media (max-width: 920px) {
    /* padding: 0 ${rem(24)}; */
  }

  @media (max-width: 580px) {
    /* display: none; */
  }
`;

const GalleryImageWrapper = styled(FlexColCenter)`
  cursor: pointer;
  margin-right: ${rem(16)};
  margin-bottom: ${rem(24)};
  border: ${({ active, theme }) =>
    active
      ? `${rem(1)} solid ${theme.blogColors.primary}`
      : `${rem(1)} solid ${theme.blogColors.border}`};
`;

const sharedGalleryImage = css`
  max-width: 100%;
  height: auto;
  max-height: ${rem(600)};
  padding: ${rem(8)};
`;

const GalleryImage = styled.img`
  ${sharedGalleryImage}
`;

const CurrentImageWrapper = styled(FlexColCenter)`
  margin: ${rem(16)};
  width: 100%;
  height: 100%;
  /* max-height: ${rem(590)}; */
`;

const ImageArrowsWrapper = styled(FlexRowCenter)`
  width: 100%;
  position: relative;
`;

const ArrowButton = styled.button`
  height: ${rem(36)};
  width: ${rem(36)};
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const PreviousButton = styled(ArrowButton)`
  margin-right: ${rem(12)};
  margin-left: ${rem(12)};
  visibility: ${({ isCurrentFirst }) =>
    isCurrentFirst ? 'hidden' : 'initial'};
`;

const NextButton = styled(ArrowButton)`
  margin-left: ${rem(12)};
  margin-right: ${rem(12)};
  visibility: ${({ isCurrentLast }) => (isCurrentLast ? 'hidden' : 'initial')};
`;

const CurrentImageName = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.blogColors.primary};
  margin-bottom: ${rem(16)};
  margin-top: ${rem(16)};
`;

const CurrentImage = styled.img`
  /* max-width: ${rem(800)};
  max-height: ${rem(600)}; */
  /* width: 100%; */
  width: auto;
  height: auto;
  max-height: 95%;
  /* margin-bottom: ${rem(32)}; */
`;

const GalleryWrapper = styled(FlexColCenter)`
  /* max-height: ${rem(700)}; */
  margin: 0 ${rem(16)};
  padding-left: ${rem(24)};
  border-left: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  overflow-y: auto;
  /* padding-top: ${({ total }) =>
    total > 5 ? `${(total - 5) * 175}px` : 0}; */

  ::-webkit-scrollbar {
    border-radius: 0;
    width: ${rem(4)};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    border-radius: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.blogColors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.blogColors.primary};
  }
`;

const HeightWrapper = styled.div`
  height: 100%;
`;

const CancelButton = styled.img`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(16)};
  width: 16px;
  height: auto;
  cursor: pointer;
  z-index: 100;
`;

interface Props {
  items: any[];
  currentIndex: number;
  setCurrentImage: any;
  closeModal: () => void;
}

class ImageGallery extends React.Component<Props> {
  public componentDidMount() {
    document.addEventListener('keydown', this.arrowsFunction, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('keyup', this.arrowsFunction, false);
  }

  public arrowsFunction = event => {
    const { items, currentIndex, setCurrentImage } = this.props;
    const total = items.length;
    const isCurrentFirst = currentIndex === 0;
    const isCurrentLast = currentIndex + 1 === total;

    // right arrow
    if (event.keyCode === 39 && !isCurrentLast) {
      setCurrentImage(currentIndex + 1);
    }
    // left arrow
    else if (event.keyCode === 37 && !isCurrentFirst) {
      setCurrentImage(currentIndex - 1);
    }
  };

  public render() {
    const { items, currentIndex, setCurrentImage } = this.props;
    const total = items.length;
    const isCurrentFirst = currentIndex === 0;
    const isCurrentLast = currentIndex + 1 === total;
    const currentName = prop(items[currentIndex], 'name', '');
    const currentOriginal = prop(items[currentIndex], 'original', '');
    return (
      <Wrapper>
        <ImageArrowsWrapper>
          <PreviousButton
            isCurrentFirst={isCurrentFirst}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => setCurrentImage(currentIndex - 1)}
          >
            <img src="/images/blog_assets/back-arrow.svg" alt="ikonka šípky" />
          </PreviousButton>
          <CurrentImageWrapper>
            <CurrentImage alt={currentName} src={currentOriginal} />
            <CurrentImageName>{currentName}</CurrentImageName>
          </CurrentImageWrapper>
          <NextButton
            isCurrentLast={isCurrentLast}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => setCurrentImage(currentIndex + 1)}
          >
            <img src="/images/blog_assets/right-arrow.svg" alt="ikonka šípky" />
          </NextButton>
          <CancelButton
            alt=""
            onClick={this.props.closeModal}
            src={'/images/cancel.svg'}
          />
        </ImageArrowsWrapper>
        {total > 1 && (
          <GalleryWrapper total={items.length}>
            <HeightWrapper>
              {items &&
                items.map((image, i) => (
                  <GalleryImageWrapper
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => setCurrentImage(i)}
                    active={currentIndex === i}
                    key={i}
                  >
                    <GalleryImage alt={image.name} src={image.thumbnail} />
                  </GalleryImageWrapper>
                ))}
            </HeightWrapper>
          </GalleryWrapper>
        )}
      </Wrapper>
    );
  }
}

export default ImageGallery;
