const namespace = 'User';

export const REQUEST_DELETE_DELIVERY_ADDRESS = `${namespace}/REQUEST_DELETE_DELIVERY_ADDRESS`;
export const RECEIVE_DELETE_DELIVERY_ADDRESS_SUCCESS = `${namespace}/RECEIVE_DELETE_DELIVERY_ADDRESS_SUCCESS`;
export const RECEIVE_DELETE_DELIVERY_ADDRESS_ERROR = `${namespace}/RECEIVE_DELETE_DELIVERY_ADDRESS_ERROR`;

export const REQUEST_REQUEST_ACTIVE_ORDERS = `${namespace}/REQUEST_REQUEST_ACTIVE_ORDERS`;
export const RECEIVE_ACTIVE_ORDERS_SUCCESS = `${namespace}/RECEIVE_ACTIVE_ORDERS_SUCCESS`;
export const RECEIVE_ACTIVE_ORDERS_ERROR = `${namespace}/RECEIVE_ACTIVE_ORDERS_ERROR`;
export const REQUEST_ORDER_DATA = `${namespace}/REQUEST_ORDER_DATA`;
export const RECEIVE_ORDER_DATA_SUCCESS = `${namespace}/RECEIVE_ORDER_DATA_SUCCESS`;
export const RECEIVE_ORDER_DATA_ERROR = `${namespace}/RECEIVE_ORDER_DATA_ERROR`;
