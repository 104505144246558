function getHostname(lang): string {
  if (process.env.REACT_APP_BASE_URL) {
    return lang === 'sk'
      ? process.env.REACT_APP_BASE_URL
      : `${process.env.REACT_APP_BASE_URL}/${lang}`;
  }

  return '';
}

interface Breadcrumbs {
  url: string;
  name: string;
}

export function getArticleBreadcrumbMicrodata(article, lang): Breadcrumbs[] {
  const hostname: string = getHostname(lang);
  return [{ name: 'Domov', url: hostname }];
}

export function getCategoryBreadcrumbMicrodata(category, lang): Breadcrumbs[] {
  const hostname: string = getHostname(lang);

  return [{ name: 'Domov', url: hostname }];
}

export function getAvailability(
  availability: string,
  onStock: number,
  isNew: boolean,
  saleEnded: boolean,
  isWaiting: boolean,
): any {
  if (isWaiting) {
    return 'BackOrder';
  } else if (saleEnded) {
    return 'Discontinued';
  } else if (onStock > 0) {
    return 'InStock';
  } else if (isNew) {
    return 'PreOrder';
  } else if (availability === 'Vypredané') {
    return 'OutOfStock';
  }
  return 'OutOfStock';
}
