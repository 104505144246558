import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiOrders as Orders,
  PaginationSimple,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { StyledTitle } from './MyDashboard';
import { LoadAllButton } from './MyInvoices';
import { prop } from '../../utilities';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  refetchData: any;
  user: any;
}

function MyOrders({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  refetchData,
  user,
}: Props) {
  const { limit, offset, total } = ordersData;

  const loadAllData = () => {
    refetchData({
      limit: total,
      id: currentId,
    });
  };

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Objednávky')} (${prop(ordersData, 'total') || 0})`}
      </StyledTitle>
      {options && options.length > 1 && (
        <>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <br />
        </>
      )}
      <Orders
        isFetching={isFetchingOrders}
        orders={orders}
        refetchData={refetchData}
        isB2B={user && user.b2b}
      />
      {total > limit && (
        <PaginationSimple
          totalItems={total}
          limit={limit}
          offset={offset}
          handleOffsetChange={handleOffsetChange}
        />
      )}
      {total > limit && (
        <LoadAllButton onClick={loadAllData}>
          {__('Zobraziť všetky')}
        </LoadAllButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyOrders;
