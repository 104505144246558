import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
} from './myAccountSlice';
import MyPayables from '../../components/MyAccount/MyPayables';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
}

interface State {
  currentId: string;
}

class MyPayablesContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_PAYABLES));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchOrders(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
    };
  }

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
    } = this.props;
    const { currentId } = this.state;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: 'Ja', value: user.id.toString() },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Záväzky' }} />
        <MyPayables
          orders={ordersById[currentId]}
          ordersData={ordersData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrders}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ currentId: id });
    this.props.dispatch(fetchOrders(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchOrders(currentId, LIMIT, offset));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyPayablesContainer' })(MyPayablesContainer),
);
