import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import OutsideClick from '../_helpers/Default/OutsideClick';
import { AddModal } from './Product';
import { AddToWithCount } from './AddToWithCount';
import {
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  hideAddToCartModalVisibility,
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalProductSelector,
  wasAddedToCartModalSelector,
} from '../../containers/Cart/cartSlice';
import { prop } from '../../utilities';
import { getImagePath } from '../../utilities/product';

interface Props {
  currency?: string;
  modalVisible: boolean;
  dispatch: any;
  modalProduct: any;
}

function AddToCartModalWindow({ modalVisible, dispatch, modalProduct }: Props) {
  const closeModal = () => {
    dispatch(setAddToCartModalVisibility(false, null));
  };
  if (!modalVisible) {
    return null;
  }

  return (
    <>
      <Dimmer height={100} zIndex={990} topZero={true} />
      <OutsideClick handleFunction={closeModal}>
        <AddModal>
          <AddToWithCount
            type={'cart'}
            handleClose={closeModal}
            productName={
              prop(modalProduct, 'publish.0.product_name') ||
              prop(modalProduct, 'name')
            }
            picture={getImagePath(prop(modalProduct, 'picture'), {
              width: 80,
              height: 80,
            })}
            price={
              prop(modalProduct, 'product_detail.main_good.final_price') ||
              prop(modalProduct, 'main_good_price') ||
              prop(modalProduct, 'main_good.final_price')
            }
            currency={prop(modalProduct, 'currency')}
            plu={prop(modalProduct, 'product_plu')}
            dispatch={dispatch}
            product={modalProduct}
          />
        </AddModal>
      </OutsideClick>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 500;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;
`;

const CloseButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const mapStateToProps = state => {
  return {
    lang: state.general.lang,
    modalVisible: addToCartModalVisibleSelector(state),
    modalProduct: addToCartModalProductSelector(state),
  };
};

export default connect(mapStateToProps)(AddToCartModalWindow);
