import {
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES_SUCCESS,
  RECEIVE_CATEGORIES_ERROR,
  REQUEST_TOP_PRODUCTS_CATEGORY,
  RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  REQUEST_CATEGORY_PRODUCTS,
  RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  RECEIVE_CATEGORY_PRODUCTS_ERROR,
  SET_CATEGORY_FILTER,
  REQUEST_CATEGORY_BRANDS,
  RECEIVE_CATEGORY_BRANDS_SUCCESS,
  RECEIVE_CATEGORY_BRANDS_ERROR,
  SET_CATEGORY_FILTERS,
  RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  REQUEST_SITEMAP_ARTICLES,
  RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  RECEIVE_SITEMAP_ARTICLES_ERROR,
  SET_CATEGORY_ARTICLES_FILTERS,
  REQUEST_SITEMAP_DATA,
  RECEIVE_SITEMAP_DATA_SUCCESS,
  RECEIVE_SITEMAP_DATA_ERROR,
  RECEIVE_SUBCATEGORIES_ERROR,
  RECEIVE_SUBCATEGORIES_SUCCESS,
  REQUEST_SUBCATEGORIES,
} from './constants';

const INITIAL_STATE: any = {
  category: {
    products: {
      sort: '',
      sortDir: '',
      products: {
        offset: 0,
      },
    },
    data: null,
  },
};

export const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CATEGORY_FILTER: {
      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            sort: action.payload.sort,
            sortDir: action.payload.sortDir,
          },
        },
      };
    }

    case REQUEST_CATEGORY_PRODUCTS: {
      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            products: null,
            isFetching: true,
          },
        },
      };
    }
    case RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS: {
      // const newProducts = action.payload.products.products;
      // const combinedProducts = state.category.products.products.push(
      //   newProducts,
      // );
      // action.payload.products.products = combinedProducts;

      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            isFetching: false,
            products: {
              ...action.payload.products,
              products: [
                ...state.category.products.products.products,
                ...action.payload.products.products,
              ],
            },
          },
        },
      };
    }
    case RECEIVE_CATEGORY_PRODUCTS_SUCCESS: {
      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            isFetching: false,
            products: action.payload.products,
          },
        },
      };
    }
    case SET_CATEGORY_FILTERS: {
      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            pathname: action.payload.filters.pathname,
            products: {
              ...state.category.products.products,
              offset: action.payload.filters.offset,
            },
          },
        },
      };
    }
    case SET_CATEGORY_ARTICLES_FILTERS: {
      return {
        ...state,
        offset: action.payload.filters.offset,
      };
    }
    case RECEIVE_CATEGORY_PRODUCTS_ERROR: {
      return {
        ...state,
        category: {
          ...state.category,
          products: {
            ...state.category.products,
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }
    case REQUEST_TOP_PRODUCTS_CATEGORY: {
      return {
        ...state,
        category: {
          ...state.category,
          topProducts: {
            ...state.category.topProducts,
            isFetching: true,
          },
        },
      };
    }

    case RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: {
          ...state.category,
          topProducts: {
            ...state.category.topProducts,
            isFetching: false,
            topProducts: action.payload.topProducts,
          },
        },
      };
    }

    case RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR: {
      return {
        ...state,
        category: {
          ...state.category,
          topProducts: {
            ...state.category.topProducts,
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }

    case REQUEST_SITEMAP_ARTICLES: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_SITEMAP_ARTICLES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        articles: action.payload.articles,
      };
    }

    case RECEIVE_SITEMAP_ARTICLES_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case REQUEST_SITEMAP_DATA: {
      return {
        ...state,
      };
    }

    case RECEIVE_SITEMAP_DATA_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }

    case RECEIVE_SITEMAP_DATA_ERROR: {
      return {
        ...state,
        dataError: action.payload.error,
      };
    }

    case REQUEST_CATEGORIES: {
      return {
        ...state,
        isFetching: true,
        category: { ...state.category, data: null },
      };
    }

    case RECEIVE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        category: { ...state.category, data: action.payload.category },
        attribs: action.payload.attribs,
      };
    }

    case RECEIVE_CATEGORIES_ERROR: {
      return {
        ...state,
        isFetching: false,
        category: { ...state.category, error: action.payload.error },
      };
    }

    case REQUEST_CATEGORY_BRANDS: {
      return {
        ...state,
        brands: {
          ...state.brands,
          isFetching: true,
        },
      };
    }

    case RECEIVE_CATEGORY_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: {
          ...state.brands,
          isFetching: false,
          data: action.payload.category,
        },
      };
    }

    case RECEIVE_CATEGORY_BRANDS_ERROR: {
      return {
        ...state,
        brands: {
          ...state.brands,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_SUBCATEGORIES: {
      return {
        ...state,
        isFetching: true,
        subTree: null,
      };
    }

    case RECEIVE_SUBCATEGORIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        subTree: action.payload.categories,
      };
    }

    case RECEIVE_SUBCATEGORIES_ERROR: {
      return {
        ...state,
        isFetching: false,
        subTree: { ...state.subTree, error: action.payload.error },
      };
    }

    // case REQUEST_COMPATIBLE_PRODUCTS: {
    //   if (!action.payload) {
    //     return state;
    //   }
    //   return {
    //     ...state,
    //     compatibleProducts: {
    //       ...state.compatibleProducts,
    //       [action.payload.productId]: {
    //         isFetching: true,
    //         data: null,
    //       },
    //     },
    //   };
    // }

    // case RECEIVE_COMPATIBLE_PRODUCTS_SUCCESS: {
    //   if (!action.payload.productId) {
    //     return state;
    //   }
    //   return {
    //     ...state,
    //     compatibleProducts: {
    //       ...state.compatibleProducts,
    //       [action.payload.productId]: {
    //         isFetching: false,
    //         data: [...action.payload.connections.products],
    //       },
    //     },
    //   };
    // }

    default: {
      return state;
    }
  }
};
