import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  DsiFinishedOrder as FinishedOrder,
  DsiFinishedContest as FinishedContest,
  FlexCol,
  FlexRowCenter,
  InfoText,
  prop,
  TextP,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import styled from '../../theme/styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import { redirectCsob, redirectVub } from '../../utilities/order';
import { handleGAPurchase } from './cartSlice';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

class FinishedContestContainer extends React.Component<
  Props & WithRouterProps
> {
  public static async getInitialProps(props) {}

  constructor(props) {
    super(props);
  }

  public render() {
    const {
      location,
      params: { orderPublicId },
    } = this.props;

    const status = prop(location, 'query.status');
    const isErrorQuery = (prop(location, 'query.status') || '').includes('?');
    const isError = isErrorQuery
      ? status.includes('error')
      : status === 'error';
    const isEmail = isErrorQuery
      ? !isError && status.includes('email')
      : status === 'email';
    const isPaid = isErrorQuery
      ? !isError && status.includes('success')
      : status === 'success';

    return (
      <>
        <Wrapper className="container container--wide">
          <FinishedContest
            isFetching={false}
            error={isError}
            paid={isPaid}
            isEmail={isEmail}
          />
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.link};
  text-decoration: underline;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedContest' })(
    withRouter(FinishedContestContainer),
  ),
);
