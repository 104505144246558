import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  fetchCustomerDetails,
  fetchCustomerFinancialDetails,
  ordersArraySelector,
} from './myAccountSlice';
import MyOrders from '../../components/MyAccount/MyOrders';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { prop } from '../../utilities';

const INVOICES = [
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
    notPaid: true,
  },
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
  },
];

const LIMIT = 20;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  orders: any;
}

interface State {
  currentId: string;
}

class MyOrdersContainer extends React.Component<Props, State> {
  private loadResultsTimeout;

  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDERS));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchOrders(null, LIMIT, 0)),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: '',
    };
  }

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
      orders,
    } = this.props;
    const { currentId } = this.state;
    const isChild = !!(childUsers && childUsers.find(c => user.id === c.id));
    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: user.meno ? `${user.meno} ${user.priezvisko}` : user.firma,
            value: user.outer_id,
          }))
          .map(a => a)
      : [];
    const options = [
      { name: __('Všetky pobočky'), value: '' },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Objednávky') }} />
        <MyOrders
          orders={prop(orders, 'orders')}
          invoices={INVOICES}
          ordersData={prop(orders, 'pagination') || {}}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrders}
          refetchData={this.refetchData}
          user={user}
        />
      </>
    );
  }

  public refetchData = async ({ id, limit, fromDate, toDate }) => {
    const { orders } = this.props;
    const { currentId } = this.state;

    if (this.loadResultsTimeout) {
      clearTimeout(this.loadResultsTimeout);
    }

    this.loadResultsTimeout = setTimeout(async () => {
      await this.props.dispatch(
        fetchOrders(
          currentId || id,
          prop(orders, 'pagination.limit') || LIMIT,
          0,
          fromDate,
          toDate,
        ),
      );
    }, 1000);
  };

  public handleSelectChange = async e => {
    const { orders } = this.props;
    const id = e.target.value;
    this.setState({ currentId: id });

    await this.props.dispatch(
      fetchOrders(id, prop(orders, 'pagination.limit') || LIMIT, 0),
    );
  };

  public handleOffsetChange = async e => {
    const { orders, ordersData } = this.props;
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;

    await this.props.dispatch(
      fetchOrders(
        currentId,
        prop(orders, 'pagination.limit') || LIMIT,
        offset,
        prop(orders, 'activeFilters.filter_from'),
        prop(orders, 'activeFilters.filter_to'),
      ),
    );
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
    orders: ordersArraySelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyOrdersContainer' })(MyOrdersContainer),
);
