import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
  DsiCountSetter as CountSetter,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { formatPrice } from '../../utilities';
import { addItemToCart } from '../../containers/Cart/cartSlice';
import { Loader } from '../_helpers/Loader/Loader';

interface Props {
  type: string;
  handleClose: () => void;
  productName: string;
  picture?: string;
  price?: string;
  currency?: string;
  plu?: string;
  dispatch: any;
  product: any;
}

function AddToWithCount(props) {
  const {
    type,
    handleClose,
    productName,
    picture = '/images/icons/placeholder.svg',
    price = '',
    currency = '',
    plu,
    product,
    dispatch,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState(1);

  const handleCountChange = (newCount: string) => {
    if (!isNaN(parseInt(newCount, 10))) {
      setCount(parseInt(newCount, 10));
    } else {
    }
  };

  const handleAddToCart = () => {
    handleClose();
    setIsLoading(true);
    dispatch(addItemToCart(product, count, false, true));
  };

  return (
    <>
      <Wrapper>
        <Heading>
          <Title>{__('Pridanie do košíka')}</Title>
          <IconWrapper onClick={handleClose}>
            <StyledSvgIcon
              icon={IconType.close}
              width={24}
              height={24}
              fill={'#414141'}
            />
          </IconWrapper>
        </Heading>
        {isLoading ? (
          <Loader />
        ) : (
          <ProductWrapper>
            <FlexRowCenterVertical>
              <ProductImg
                src={picture ? picture : '/images/icons/placeholder.svg'}
                alt={__('Obrázok produktu')}
                width={56}
                height={56}
              />
              <FlexCol>
                <Name>{`${productName}`}</Name>
                <Plu>{plu}</Plu>
                <Price>
                  {isNaN(price * count)
                    ? formatPrice(price, currency)
                    : formatPrice(price * count, currency)}
                </Price>
              </FlexCol>
            </FlexRowCenterVertical>
            <CountSetter
              count={count}
              minValue={1}
              onChange={handleCountChange}
            />
          </ProductWrapper>
        )}
        <Buttons>
          <CloseButton onClick={handleClose}>{__('Zavrieť')}</CloseButton>
          <StyledButton onClick={handleAddToCart}>
            {__('Pridať do košíka')}
          </StyledButton>
        </Buttons>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 500;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};
  justify-content: space-between;
  flex-flow: row wrap;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;

  flex-flow: row wrap;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
  `}
`;

const CloseButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediab.m580`
    width:100%;
    margin-bottom:${rem(16)};
  `}
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const Plu = styled(Name)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.inactiveFontColor};
`;

const Price = styled(Name)`
  min-width: ${rem(56)};
  text-align: left;

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
  `}
`;

const AddToCartButton = styled(Button)`
  text-decoration: none;
`;

export { AddToWithCount };
