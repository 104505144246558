import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import Sellers from '../../components/Sellers/Sellers';
import { MetaTags } from 'eshop-defaults';
import { __, __r } from 'react-i18n';
import GiftCardContest from '../../components/GiftCardContest/GiftCardContest';
import {
  fetchWebContents,
  giftContestCartSelector,
  giftContestGiftCardInfoSelector,
  giftContestOrderInfoSelector,
  giftContestShowCartProblemsSelector,
  giftContestWebContentsSelector,
  giftIsFetchingSelector,
  giftWrongCodeSelector,
} from './giftCardContentSlice';
import { WithRouterProps, withRouter } from 'react-router';
import { Loader } from 'eshop-defaults';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  dispatch: any;
  lang: string;
  giftcardId: string;
  webContents: any;
  giftCardInfo: any;
  contentsCart: any;
  showCartProblems: boolean;
  orderInfo: any;
  isFetching: boolean;
  wrongCode: boolean;
}

class GiftCardContestContainer extends React.Component<
  Props & WithRouterProps
> {
  public static async getInitialProps(props) {
    const { dispatch, giftcardId, lang } = props;
    dispatch(fetchWebContents());
  }

  public render() {
    const {
      dispatch,
      giftcardId,
      lang,
      webContents,
      giftCardInfo,
      contentsCart,
      showCartProblems,
      orderInfo,
      router,
      isFetching,
      wrongCode,
    } = this.props;

    if (orderInfo) {
      router.push(
        `${__r('routes:vyhra-dokoncena', '/vyhra-dokoncena')}/${
          orderInfo.orderPublicId
        }`,
      );
    }

    return (
      <>
        <GiftCardContest
          dispatch={dispatch}
          lang={lang}
          webContents={webContents}
          giftCardInfo={giftCardInfo}
          contentsCart={contentsCart}
          showCartProblems={showCartProblems}
          orderInfo={orderInfo}
          isFetching={isFetching}
          wrongCode={wrongCode}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    webContents: giftContestWebContentsSelector(state),
    giftCardInfo: giftContestGiftCardInfoSelector(state),
    contentsCart: giftContestCartSelector(state),
    showCartProblems: giftContestShowCartProblemsSelector(state),
    orderInfo: giftContestOrderInfoSelector(state),
    isFetching: giftIsFetchingSelector(state),
    wrongCode: giftWrongCodeSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'GiftCardContestContainer' })(
    withRouter(GiftCardContestContainer),
  ),
);
