import * as React from 'react';
import styled from '../../theme/styled-components';
import {
  DsiCartContactInfoPart as CartContactInfoPart,
  DsiButtonPrimary,
  FlexCol,
  StyledFormInput,
  prop,
} from 'eshop-defaults';
import { rem } from 'polished';
import RenderedBody from '../Article/RenderedBody';
import FormInput from '../_helpers/Default/FormInput';
import { __ } from 'react-i18n';
import { SearchFormInput } from 'eshop-defaults/lib/components/Dsi/Sellers/SearchFormInput';
import Button from '../_helpers/forms/Button/Button';
import { useState } from 'react';
import {
  checkContestCode,
  finishContents,
  updateContentsCart,
} from '../../containers/GiftCardContest/giftCardContentSlice';
import {
  getInitialStateFromCartData,
  getInitialStateFromCartDataForContest,
} from '../Cart/Cart';
import { updateCart } from '../../containers/Cart/cartSlice';
import { Loader } from 'eshop-defaults';

interface Props {
  dispatch: any;
  lang: string;
  webContents: any;
  giftCardInfo: any;
  contentsCart: any;
  showCartProblems: boolean;
  orderInfo: any;
  isFetching: boolean;
  wrongCode: boolean;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function GiftCardContest({
  dispatch,
  lang,
  webContents,
  giftCardInfo,
  contentsCart,
  showCartProblems,
  orderInfo,
  isFetching,
  wrongCode,
}: Props) {
  const topWebContent =
    webContents && webContents.length ? webContents[0] : null;
  const [contestCode, setContestCode] = useState('');
  const initialState = getInitialStateFromCartDataForContest(
    contentsCart,
    false,
  );
  const [state, dispatchState] = React.useReducer(reducer, initialState);
  const formRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    //dispatch(checkContestCode("58Q9LN1LUR"));
  }, []);

  function handleFieldBlur(e) {
    setContestCode(e.target.value);
  }

  function onCheckCodeButtonClick() {
    dispatch(checkContestCode(contestCode));
  }

  function onFinishButtonClick(e) {
    const form = prop(formRef, 'current.elements');

    if (form) {
      const newData = {
        ...contentsCart,
        delivery_address: {
          ...contentsCart.delivery_address,
          city: prop(form['city'], 'value', ''),
          country: prop(form['country'], 'value', ''),
          street: prop(form['street'], 'value', ''),
          street_number: prop(form['street_number'], 'value', ''),
          zip: prop(form['zip'], 'value', ''),
          person: {
            name: prop(form['name'], 'value', ''),
            surname: prop(form['surname'], 'value', ''),
            phone:
              prop(form['phone_prefix'], 'value', '') +
              prop(form['phone'], 'value', ''),
            email: prop(form['email'], 'value', ''),
            phonePrefix: prop(form['phone_prefix'], 'value', ''),
          },
        },
      };
      dispatch(finishContents(newData));
    }
  }

  const winnerProduct = giftCardInfo?.products[0];
  const isOrdered = !!orderInfo;
  const isWinner = !isOrdered && !!winnerProduct;

  if (isOrdered) {
  }

  const handleUpdateCart = data => {
    dispatch(updateContentsCart({ ...data, ...state }));
  };

  return (
    <Wrapper>
      {isFetching && <CustomLoader loading={true} />}
      {!isOrdered && !isWinner && topWebContent ? (
        <RenderedBody
          inputBody={topWebContent.json_content.body}
          articleTitle={''}
        />
      ) : (
        <></>
      )}
      <GiftCodeWrapper>
        <GiftCodeInnerWrapper>
          <CodeInput
            name="code_input"
            onChange={() => {}}
            label={__('Váš výherný kód')}
            onBlur={handleFieldBlur}
          />
          <CheckButton redBorder={true} onClick={onCheckCodeButtonClick}>
            {' '}
            {__('Odoslať')}{' '}
          </CheckButton>
        </GiftCodeInnerWrapper>
        {wrongCode ? (
          <ErrorText> {__('Zadaný kód nie je správny')} </ErrorText>
        ) : (
          <></>
        )}
      </GiftCodeWrapper>
      {isWinner ? (
        <WinnerContentWrapper>
          <WinnerTextWrapper>
            <p>
              {__('Gratulujeme, vyhrávate')} {'"'}
              {winnerProduct.name}
              {'"'}.{' '}
              {__(
                'Výhra Vám bude doručena kurýrem DPD během několika dní. Vyplňte prosím dodací údaje, kam máme výhru zaslat.',
              )}
            </p>
          </WinnerTextWrapper>
          <CartFormWrapper>
            <CartContactInfoPart
              data={contentsCart}
              isFetching={isFetching}
              updateCart={handleUpdateCart}
              deliveryAddresses={[]}
              deliveryAddressesIsFetching={isFetching}
              showProblems={showCartProblems}
              isB2B={false}
              lang={lang}
              state={state}
              dispatchState={() => {}}
              formRef={formRef}
              isB2CUser={false}
              disableNonContentsFields={true}
            />
          </CartFormWrapper>
          <FinishButton onClick={onFinishButtonClick} disabled={isFetching}>
            {' '}
            {__('Odoslať darček')}{' '}
          </FinishButton>
        </WinnerContentWrapper>
      ) : (
        <></>
      )}

      {isOrdered ? <></> : <></>}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  padding: ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `};
`;

const CodeInput = styled(StyledFormInput)``;

const CustomLoader = styled(Loader)`
  margin-top: ${rem(100)};
`;

const CheckButton = styled(DsiButtonPrimary)`
  margin-left: ${rem(16)};
  align-self: flex-end;
  min-height: ${rem(50)};
  height: ${rem(50)};

  ${({ theme }) => theme.mediab.l925`
    margin-left: ${rem(0)};
    margin-top: ${rem(12)};
    width: 100%;
  `}
`;

const FinishButton = styled(DsiButtonPrimary)`
  margin-top: ${rem(25)};
`;

const GiftCodeWrapper = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 50%;
`;

const GiftCodeInnerWrapper = styled.div`
  display: flex;
  align-self: center;
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    flex-direction: column;
  `}
  width: 100%
`;

const WinnerTextWrapper = styled.p`
  margin-top: ${rem(25)};
  color: black;
`;

const CartFormWrapper = styled.div`
  margin-top: ${rem(25)};
`;

const WinnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

const ErrorText = styled.div`
  color: red;
  margin-left: ${rem(17)};
  margin-top: ${rem(5)};
`;

export default GiftCardContest;
