import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputStyles } from '../FormStyles';

const SelectWrapper = styled.select`
  ${InputStyles};
  border-radius: ${rem(4)};
  &:focus {
  }
  &:not(:disabled) {
    &:hover {
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.select--block {
    display: block;
    width: 100%;
  }
`;

const Option = styled.option`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
`;

const SelectDefaultWrapper = styled(SelectWrapper)`
  background-color: ${({ theme }) => theme.color.white};
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgOCA0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA4IDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMjgyODI4O308L3N0eWxlPjxnPjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMCwwIDgsMCA0LDQgIi8+PC9nPjwvc3ZnPg==);
  background-position: right ${rem(15)} center;
  background-repeat: no-repeat;
  background-size: ${rem(8)} ${rem(4)};
  box-shadow: 0px 1px 4px #00000014;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: ${rem(10)};

  &:focus {
    outline: none;
  }
`;

export interface SelectIO {
  name: string;
  id: string;
  className?: string;
  options: Array<{
    name: string;
    value: number | string;
  }>;
  placeholder?: string;
  disabled?: boolean;
  onChange?: any;
  value?: string;
}

class SelectDefault extends React.Component<SelectIO> {
  public render() {
    const {
      id,
      name,
      className,
      options,
      placeholder,
      disabled,
      onChange,
      value,
    } = this.props;

    return (
      <SelectDefaultWrapper
        disabled={disabled}
        name={name}
        id={id}
        onChange={onChange}
        className={className}
        value={value}
      >
        {/* {placeholder === '' ? <Option value={''}>{placeholder}</Option> : ''} */}
        {placeholder ? <Option value={''}>{placeholder}</Option> : null}
        {options.map((option, i) => {
          return (
            <Option value={option.value} key={i}>
              {option.name}
            </Option>
          );
        })}
      </SelectDefaultWrapper>
    );
  }

  public handleChange = e => {
    this.props.onChange(e);
  };
}

export { SelectDefault };
