import { createSelector } from 'reselect';
import { prop } from 'eshop-defaults';

export const homeDomainSelector = state => state.homepage || {};
export const generalDomainSelector = state => state.general || {};

export const homeArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'articles', { articlesData: [], isFetching: false }),
);

export const articleIsFetchingSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const articleErrorSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'error'),
);

export const mostReadArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'mostRead', { mostReadArticles: [], isFetching: false }),
);

export const recommendedArticlesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'recommended', {
      recommendedArticles: [],
      isFetching: false,
    }),
);

export const recommendedArticlesOptionsSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'recommended.recommendedArticlesOptions', []),
);

export const fastNewsSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'fastNews', { fastNewsArticles: [], isFetching: false }),
);

export const ebooksSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'ebooks', { ebooks: [], isFetching: false }),
);

export const topArticleSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'topArticle', {
    topArticleData: {
      articles: [],
      isFetching: false,
    },
  }),
);

export const adBannerSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'adBanner'),
);

export const topBrandsSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'brands'),
);

export const favoriteProductsSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'favoriteProducts', { products: null, isFetching: false }),
);

export const recommendedProductsSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'recommendedProducts', {
      products: null,
      isFetching: false,
    }),
);

export const shoppingConsultantsSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'shoppingConsultants', {
      articles: null,
      isFetching: false,
    }),
);

export const topTechnologiesSelector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'topTechnologies', { articles: null, isFetching: false }),
);

export const productList1Selector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'productList1', { productsData: null, isFetching: false }),
);

export const productList2Selector = createSelector(
  homeDomainSelector,
  substate =>
    prop(substate, 'productList2', { productsData: null, isFetching: false }),
);

export const homeCategoriesSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'homeCategories'),
);

export const homeCategoriesIsFetchingSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const reviewsSelector = createSelector(homeDomainSelector, substate =>
  prop(substate, 'reviews'),
);

export const notFoundArticlesSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'notFoundArticles'),
);

export const blogArticlesSelector = createSelector(
  homeDomainSelector,
  substate => prop(substate, 'articles'),
);
