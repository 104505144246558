import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import APIClass, { ThenArg } from '../../services/API';
import { startFetching, stopFetching } from '../../utilities/redux';
import { AppThunk } from '../../rootReducer';
import { strictEqual } from 'assert';
import { getImagePath } from '../../utilities/product';
import { langSelector } from '../App/selectors';

export interface ActionItemInterface {
  endedDate: Date;
  title: string;
  description: string;
  imageUrl: string;
  url: string;
}

interface ActionsState {
  jblActions: {
    data: Array<ActionItemInterface> | null;
    isFetching: boolean;
  };
}

const initialState: ActionsState = {
  jblActions: {
    data: [],
    isFetching: false,
  },
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    startFethingActions(state) {
      startFetching(state, 'jblActions');
    },

    setActions(
      state,
      action: PayloadAction<{
        actions: Array<ActionItemInterface>;
      }>,
    ) {
      stopFetching(state, 'jblActions');
      state.jblActions.data = action.payload.actions;
    },

    stopFetchingActions(state) {
      stopFetching(state, 'jblActions');
    },
  },
});

export const {
  startFethingActions,
  setActions,
  stopFetchingActions,
} = actionsSlice.actions;

export default actionsSlice.reducer;

export const fetchActions = (): AppThunk => async (dispatch, getState, API) => {
  try {
    dispatch(startFethingActions());
    const lang = langSelector(getState());
    const actions: /*ThenArg<typeof APIClass.loadLandingPages>*/ any = await API.loadLandingPages(
      { withContent: 1, lang, limit: 100 },
    );
    dispatch(
      setActions({
        actions: actions.web_landing_pages.map(action => {
          return {
            endedDate: action.dateTo ? new Date(action.dateTo) : null,
            title: action.name ? action.name : '',
            description: action.content
              ? action.content.json_content.anotation
              : '',
            imageUrl:
              action.content &&
              action.content.photogallery &&
              action.content.photogallery.length
                ? getImagePath(
                    action.content.photogallery[0].document_path,
                    {
                      width: 800,
                      height: 600,
                    },
                    false,
                    false,
                    true,
                  )
                : null,
            url: `/akcie/${action.id}${action.url}`,
          };
        }),
      }),
    );
  } catch (e) {
    dispatch(stopFetchingActions());
  }
};

const actionsDomainSelector = state => state.actions;

export const actionsSelector = createSelector(actionsDomainSelector, substate =>
  prop(substate, 'jblActions.data', []),
);
