import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, FlexCol, prop } from 'eshop-defaults';
import ArticleTags from './ArticleTags';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { Link } from 'react-router';
import { getImagePath } from '../../utilities/product';
import { connectSsr } from 'ssr-service';
import API from '../../services/API';
import { formatDate } from '../../utilities';

const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 400;
`;

const Wrapper = styled(FlexCol)`
  margin: ${rem(48)} 0;
  padding: ${rem(32)};
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);

  ${({ theme }) => theme.mediab.m580`
    padding: ${rem(16)};
    margin: ${rem(32)} 0;
  `}
`;

const Title = styled(Text)`
  font-size: ${rem(24)};
  font-weight: 500;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  padding-bottom: ${rem(32)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    padding-bottom: ${rem(16)};
    margin-bottom: ${rem(16)};
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-flow: row;
  margin: 0 auto;
  width: 100%;

  &:hover {
    opacity: 0.9;
  }

  /* ${({ theme }) => theme.mediab.l1050`
    flex-flow: column;
  `} */

  ${({ theme }) => theme.mediab.m580`
    border: 0;
    padding: 0;
  `}
`;

const Image = styled.img`
  width: ${rem(200)};
  height: ${rem(200)};
  margin-right: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    width: ${rem(80)};
    height: ${rem(80)};
    margin-right: ${rem(16)};
  `}
`;

const RightSideWrapper = styled(FlexCol)`
  justify-content: center;
`;

export const ArticleTitle = styled(Text)`
  font-size: ${rem(20)};
  font-weight: 700;
  margin: ${rem(24)} 0;

  ${({ theme }) => theme.mediab.m580`
    margin: 0;
    font-size: ${rem(16)};
    font-weight: 700;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: ${rem(16)};
    max-height: ${rem(48)};
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
`;

export const ArticleAuthorDateWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

export const ArticleAuthorDateText = styled(Text)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.blogColors.gray};
`;

export const DotDelimeter = styled.span`
  margin: 0 ${rem(16)};
  height: ${rem(3)};
  width: ${rem(3)};
  background-color: ${({ theme }) => theme.blogColors.gray};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

interface Props {
  id: string;
  article: any;
}

class ReadTooArticle extends React.Component<Props, {}> {
  public static async getInitialProps(props) {
    return { article: await API.loadArticleDetail(props.id) };
  }

  public render() {
    const { article } = this.props;

    if (!article) {
      return null;
    }

    const { name, created_date, tags, url, author, photogallery } = article;
    const authorName = prop(author, 'name', '');
    const titleImage = prop(photogallery[0], 'document_path');

    return (
      <Wrapper>
        <Title>{__('Prečítajte si tiež')}</Title>
        <StyledLink to={`/${url}`}>
          <Image
            alt={name}
            src={
              titleImage
                ? getImagePath(titleImage, { width: 300, height: 300 })
                : 'https://via.placeholder.com/290x160.png'
            }
          />
          <RightSideWrapper>
            <DesktopWrapper>
              <ArticleTags tags={tags} />
            </DesktopWrapper>
            <ArticleTitle>{name}</ArticleTitle>
            <ArticleAuthorDateWrapper>
              <ArticleAuthorDateText>{authorName}</ArticleAuthorDateText>
              <DotDelimeter />
              <ArticleAuthorDateText>
                {formatDate(created_date, 'DD.MM.YYYY')}
              </ArticleAuthorDateText>
            </ArticleAuthorDateWrapper>
          </RightSideWrapper>
        </StyledLink>
      </Wrapper>
    );
  }
}

export default connectSsr({ displayName: 'ReadTooArticle' })(ReadTooArticle);
