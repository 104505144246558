import * as React from 'react';
import {
  getOtherLang,
  resolveBaseUrlByLang,
  resolveOtherBaseUrlByLang,
} from '../../configureTrans';
import { prop } from '../../utilities';

interface Props {
  langChanged: boolean;
  lang: string;
  langVersions: any[];
}

function CMSLanguageRedirectChecker({
  langChanged,
  lang,
  langVersions,
}: Props) {
  React.useEffect(() => {
    if (langChanged) {
      const currentUrl = prop(window, 'location.origin');
      const langVersion = langVersions.find(
        lv =>
          (lv.lang_id && lv.lang_id !== lang) || (lv.lang && lv.lang !== lang),
      );
      const url = prop(langVersion, 'url');
      const otherLang = getOtherLang(lang);
      window.location.href = `${currentUrl
        .replace(`.${lang}`, `.${getOtherLang(lang || '')}`)
        .replace(
          otherLang === 'cz' ? 'laica' : 'laicaitaly',
          otherLang === 'cz' ? 'laicaitaly' : 'laica',
        )
        .replace(
          otherLang === 'cz' ? 'kenwood-electronics' : 'kenwood',
          otherLang === 'cz' ? 'kenwood' : 'kenwood-electronics',
        )
        .replace(
          otherLang === 'cz' ? 'harmankardon' : 'harmanshop',
          otherLang === 'cz' ? 'harmanshop' : 'harmankardon',
        )}`;
    }
  }, [lang, langChanged]);

  return null;
}

export default CMSLanguageRedirectChecker;
