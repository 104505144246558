import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

// INTERFACES

interface DimmerProps {
  height: number | null;
  zIndex?: number;
  topZero?: boolean;
}

/*****************/

// Styled Components

const Wrapper: any = styled.div<{ zIndex: number; topZero?: boolean }>`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: ${({ topZero }) => (topZero ? 0 : rem(48))};
  z-index: ${({ zIndex }) => zIndex};
`;

/*****************/

const Dimmer: React.FunctionComponent<DimmerProps> = ({
  height,
  zIndex = 5,
  topZero,
}) => {
  return <Wrapper height={height} zIndex={zIndex} topZero={topZero} />;
};

export default Dimmer;
