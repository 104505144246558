import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  fetchChildUsers,
  fetchFavorites,
  requestsIsFetchingSelector,
  childUsersSelector,
  favoritesDataSelector,
  favoritesByIdSelector,
} from './myAccountSlice';
import MyFavorites from '../../components/MyAccount/MyFavorites';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { homeCategoriesSelector, topBrandsSelector } from '../Home/selectors';

const LIMIT = 10;

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
  favoritesById: object;
  favoritesData: any;
  childUsers: any;
  favoriteCategories: any;
  favoriteBrands: any;
}

interface State {
  currentId: string;
}

class MyFavoritesContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_FAVORITES));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchFavorites(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
    };
  }

  public render() {
    const {
      favoritesById,
      favoritesData,
      isFetching,
      favoriteCategories,
      favoriteBrands,
    } = this.props;
    return (
      <>
        <MetaTags tags={{ title: 'Môj účet - Obľúbené' }} />
        <MyFavorites
          apiUrl={process.env.REACT_APP_API_BASE_URL as string}
          favorites={favoritesById}
          favoritesData={favoritesData}
          handleOffsetChange={this.handleOffsetChange}
          isFetching={isFetching}
          favoriteCategories={favoriteCategories}
          favoriteBrands={favoriteBrands}
        />
      </>
    );
  }

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchFavorites(currentId, LIMIT, offset));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    favoritesData: favoritesDataSelector(state),
    favoritesById: favoritesByIdSelector(state),
    isFetching: requestsIsFetchingSelector(state),
    childUsers: childUsersSelector(state),
    favoriteCategories: homeCategoriesSelector(state),
    favoritebrands: topBrandsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyFavorites' })(MyFavoritesContainer),
);
