import * as React from 'react';
import Footer from '../../components/Footer/Footer';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  contactInfoSelector,
  footerDataSelector,
} from '../App/selectors';

interface Props {
  lang: string;
  sitemap: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  contactInfo: {
    email: string;
    phone: string;
  };
  socialUrls: {
    [key: string]: string;
  };
  currentLocation: string;
}

class FooterContainer extends React.PureComponent<Props> {
  public render() {
    const {
      sitemap,
      contactInfo: { email, phone },
      socialUrls,
      currentLocation,
    } = this.props;

    return (
      <Footer
        currentLocation={currentLocation}
        phone={phone}
        email={email}
        items={sitemap}
        socialUrls={socialUrls}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contactInfo: contactInfoSelector(state),
    sitemap: footerDataSelector(state),
  };
};

export default connect(mapStateToProps)(FooterContainer);
