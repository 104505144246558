import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { __, __r } from 'react-i18n';
import {
  Action as ActionItem,
  FlexCol,
  DsiTitle as Title,
} from 'eshop-defaults';

import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { ActionItemInterface } from '../../containers/Actions/actionsSlice';
import {
  getOtherLang,
  resolveBaseUrlByLang,
  resolveOtherBaseUrlByLang,
} from '../../configureTrans';
import { prop } from '../../utilities';

interface Props {
  lang?: string;
  dispatch: any;
  actions: Array<ActionItemInterface>;
  langChanged: boolean;
}

function Action({ dispatch, actions, lang, langChanged }: Props) {
  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.ACTIONS));
  }, [dispatch]);
  React.useEffect(() => {
    if (langChanged) {
      const currentUrl = prop(window, 'location.origin');
      const otherLang = getOtherLang(lang || '');
      window.location.href = `${currentUrl
        .replace(`.${lang}`, `.${getOtherLang(lang || '')}`)
        .replace(
          otherLang === 'cz' ? 'laica' : 'laicaitaly',
          otherLang === 'cz' ? 'laicaitaly' : 'laica',
        )
        .replace(
          otherLang === 'cz' ? 'kenwood-electronics' : 'kenwood',
          otherLang === 'cz' ? 'kenwood' : 'kenwood-electronics',
        )
        .replace(
          otherLang === 'cz' ? 'harmankardon' : 'harmanshop',
          otherLang === 'cz' ? 'harmanshop' : 'harmankardon',
        )}/${__r('routes:akcie', '/akcie')}`;
    }
  }, [lang, langChanged]);

  const renderActions = (): JSX.Element => {
    return (
      <Wrapper>
        <Title marginTop={0} marginBottom={46}>
          {__('Akcie')}
        </Title>
        <ActionsWrapper>
          {actions.map(action => {
            const { endedDate, title, description, imageUrl, url } = action;
            return (
              <ActionItem
                title={title}
                description={description}
                endedDate={endedDate}
                imageUrl={imageUrl}
                url={url}
              />
            );
          })}
        </ActionsWrapper>
      </Wrapper>
    );
  };

  return renderActions();
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  padding: ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `};
`;

const ActionsWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${rem(16)};
  grid-auto-rows: minmax(100px, auto);

  ${({ theme }) => theme.mediab.l925`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${({ theme }) => theme.mediab.m580`
    grid-template-columns: repeat(1, 1fr);
  `};
`;

export default Action;
