import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { __ } from 'react-i18n';
import { debounce, throttle } from 'lodash';
import {
  FlexCol,
  DsiTitle as Title,
  MapSearchFormInput,
  DsiSvgIcon as SvgIcon,
  MapZoom,
  SellersList,
} from 'eshop-defaults';
import { prop } from 'eshop-defaults/lib';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import Map from './Map';

import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { store } from '../..';
import { resolveDomainByHostname } from '../../configureTrans';
import { useDebounce } from '../../utilities/hooks';

interface Props {
  lang: string;
  dispatch: any;
  hostname: string;
}

export interface MarkerInterface {
  location: { lat: Number; lng: Number };
  zip: String;
  city: String;
  visibility: Boolean;
  placeId: string;
  name: string;
  address: string;
  id: number;
}

function Sellers({ dispatch, lang, hostname }: Props) {
  let updateDataTimeout: any = null;
  const [shops, setShops] = React.useState([]);
  const [shopsData, setShopsData] = React.useState({});
  const [data, setData] = React.useState({ shops: [] });
  const [markers, setMarkers] = React.useState<Array<MarkerInterface>>([]);
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [tolerance, setTolerance] = React.useState(16); // in km
  const [searchTerm, setSearchTerm] = React.useState('');
  const [areShownAllMarkers, setAreShownAllMarkers] = React.useState(true);
  const [selectedMarker, setSelectedMarker] = React.useState<any>(null);
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/shops`;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await fetch(
          `${apiUrl}?domainId=${resolveDomainByHostname(hostname)}${
            lang ? `&langId=${lang}` : ''
          }`,
        );
        const dataRes = await result.json();
        formatData(dataRes);
        setShops(dataRes.shops);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [apiUrl]);

  const formatData = dataRes => {
    const shops = dataRes.shops.reduce((accumulator, seller) => {
      const { name } = seller;
      if (!accumulator.hasOwnProperty(name)) {
        accumulator[`${name}`] = [];
      }
      accumulator[`${name}`].push(seller);
      return accumulator;
    }, {});
    setData({ shops: shops });
    formatMarkers(dataRes.shops);
  };

  const formatMarkers = shops => {
    let tmpMarkers = shops.map(shop => {
      const marker: MarkerInterface = {
        location: { lat: 0, lng: 0 },
        zip: '',
        city: '',
        visibility: true,
        placeId: '',
        name: '',
        address: '',
        id: 0,
      };
      const {
        gps_latitude,
        gps_longitude,
        zip,
        city,
        place_id,
        name,
        address,
      } = shop;
      marker.location.lat = gps_latitude;
      marker.location.lng = gps_longitude;
      marker.zip = zip;
      marker.city = city;
      marker.placeId = place_id;
      marker.name = name;
      marker.address = address;
      marker.id = shop.id;
      return marker;
    });
    setMarkers(tmpMarkers);
  };

  // lat:49.0018324 lng:21.2393119 Presov
  // http://www.movable-type.co.uk/scripts/latlong.html
  const distance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // km
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in km
    return d;
  };

  const filterByDistance = (filterValue = '') => {
    const tmpMarkers = markers.map((marker: MarkerInterface) => {
      if (
        distance(
          49.0018324,
          21.2393119,
          marker.location.lat,
          marker.location.lng,
        ) < tolerance
      ) {
        marker.visibility = true;
      } else {
        marker.visibility = false;
      }
      return marker;
    });
    setMarkers(tmpMarkers);
  };

  const filterListByMarker = shop => {
    if (
      !selectedMarker ||
      !prop(selectedMarker, 'location.lat.hi') ||
      !prop(selectedMarker, 'location.lat.lo') ||
      !prop(selectedMarker, 'location.lng.lo') ||
      !prop(selectedMarker, 'location.lng.hi')
    ) {
      return true;
    }
    if (
      shop.gps_latitude < selectedMarker.location.lat.hi &&
      shop.gps_latitude > selectedMarker.location.lat.lo &&
      shop.gps_longitude < selectedMarker.location.lng.hi &&
      shop.gps_longitude > selectedMarker.location.lng.lo
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterShops = () => {
    let new_data = {};

    for (const [key, value] of Object.entries(data.shops)) {
      const new_arr = (value as any).filter(item => {
        return (
          filterListByMarker(item) &&
          (item.city
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .includes(searchTerm.toLowerCase()) ||
            item.zip.includes(searchTerm))
        );
      });

      if (new_arr.length > 0) {
        new_data[key] = new_arr;
      }
    }

    setShopsData(new_data);
  };

  const request = React.useCallback(
    debounce(() => {
      filterShops();
    }, 500),
    [shops, selectedMarker, searchTerm],
  );

  React.useEffect(() => {
    request();
  }, [shops, selectedMarker]);

  const changeInputEvent = event => {
    setIsFiltering(true);
    setSearchTerm(event.target.value);
    // if (updateDataTimeout) {
    //   clearTimeout(updateDataTimeout);
    // }

    // updateDataTimeout = setTimeout(() => {
    //   filterByCityZip(event.target.value);
    //   setIsLoading(false);
    // }, 400);

    // debouncedCallback(event.target.value);
  };

  const filterByCityZip = searchValue => {
    if (searchValue.trim() === '' || searchValue.length < 2) {
      if (!areShownAllMarkers) {
        showAllMarkers();
        setShopsData(data.shops);
      }
      setIsFiltering(false);
      return;
    }

    let change = false;
    let tempShowAllMarkers = true;
    const searchValueTemp = searchValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const tmpMarkers = markers.map((marker: MarkerInterface) => {
      if (
        marker.city
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(searchValueTemp) ||
        (marker.zip &&
          marker.zip
            .replace(/\s/g, '')
            .toLowerCase()
            .includes(searchValueTemp))
      ) {
        if (!marker.visibility) {
          change = true;
        }
        marker.visibility = true;
        tempShowAllMarkers = true;
      } else {
        if (marker.visibility) {
          change = true;
        }
        marker.visibility = false;
        tempShowAllMarkers = false;
      }
      return marker;
    });

    if (!change) {
      setIsFiltering(false);
      return;
    }

    const ne = {};

    for (const [key, value] of Object.entries(data.shops)) {
      const new_arr = (value as any).filter(item => {
        if (
          item.city
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .includes(searchValue.toLowerCase()) ||
          item.zip.includes(searchValue)
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (new_arr.length > 0) {
        ne[key] = new_arr;
      }
    }

    setMarkers(tmpMarkers);
    setAreShownAllMarkers(tempShowAllMarkers);
    setShopsData(ne);
    setIsFiltering(false);
  };

  // React.useEffect(() => {
  //   if (updateDataTimeout) {
  //     clearTimeout(updateDataTimeout);
  //   }

  //   updateDataTimeout = setTimeout(() => {
  //     console.log('inside timeout filter', searchTerm);
  //     filterByCityZip(searchTerm);
  //   }, 400);
  // }, [searchTerm, filterByCityZip]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  React.useEffect(
    () => {
      filterByCityZip(debouncedSearchTerm);
    },
    [debouncedSearchTerm], // Only call effect if debounced search term changes
  );

  // const debouncedCallback = React.useMemo(
  //   () => debounce(value => filterByCityZip(value), 500),
  //   [filterByCityZip],
  // );

  const showAllMarkers = () => {
    const tmpMarkers = markers.map((marker: MarkerInterface) => {
      marker.visibility = true;
      return marker;
    });
    setAreShownAllMarkers(true);
    setMarkers(tmpMarkers);
  };

  const setNewTolerance = value => {
    // setTolerance(value);
    // filterByDistance();
  };

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.SELLERS));
  }, [dispatch]);

  const handleSearch = e => {
    // console.log('search');
  };

  const handleSetCurrentPosition = () => {
    // console.log('current position');
  };

  const handleZoom = map => {
    if (!map) {
      return;
    }
    const location = Object.values(map.getBounds());
    if (location.length === 2) {
      setSelectedMarker({
        location: { lat: location[0], lng: location[1] },
      });
    }
  };

  if (isError) return null;

  return (
    <>
      <Wrapper>
        <Title marginTop={0} marginBottom={56} isCenter={true}>
          {__('Autorizovaní predajcovia')}
        </Title>
        <SellersWrapper>
          <InputWrapper>
            <StyledSearchIcon
              icon={IconType.search}
              alt={__('Hľadať podľa mesta alebo PSČ')}
              cursor={'pointer'}
              onClick={handleSearch}
            />
            <MapSearchFormInput
              name="place"
              type="text"
              placeholder={__('Zadajte mesto alebo PSČ')}
              value={searchTerm}
              onChange={changeInputEvent}
            />
            <StyledCurrentPositionIcon
              icon={IconType.location}
              alt={__('Nastaviť aktuálnu pozíciu')}
              cursor={'pointer'}
              onClick={handleSetCurrentPosition}
            />
          </InputWrapper>
          {/* <MapZoom tolarance={tolerance} setTolerance={setNewTolerance} /> */}
        </SellersWrapper>
      </Wrapper>
      <MapWrapper>
        <Map markers={markers} lang={lang} handleZoom={handleZoom} />
      </MapWrapper>
      <ListWrapper>
        <ListSellersWrapper>
          <SellersList
            sellers={shopsData}
            hasSortiment={resolveDomainByHostname(hostname) === '1'}
          />
        </ListSellersWrapper>
      </ListWrapper>
    </>
  );
}

const MapWrapper = styled(FlexCol)`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.grid.container.desktop}px`};
  margin-bottom: ${rem(44)};
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  padding: ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `};
`;

const ListWrapper = styled(Wrapper)`
  ${({ theme }) => theme.mediab.l1150`
    overflow: scroll;
  `};
`;

const SellersWrapper = styled.div`
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: ${rem(1184)};
`;

const ListSellersWrapper = styled(SellersWrapper)`
  ${({ theme }) => theme.mediab.l1150`
    width: initial;
  `};
`;

const InputWrapper = styled.div`
  max-width: ${rem(704)};
  position: relative;
  margin: 0 auto;
  margin-bottom: ${rem(32)};
`;

const StyledSearchIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.inactiveFontColor};
  position: absolute;
  top: 50%;
  left: ${rem(19)};
  transform: translateY(-50%);
`;

const StyledCurrentPositionIcon = styled(SvgIcon)`
  position: absolute;
  top: 50%;
  right: ${rem(17)};
  transform: translateY(-50%);
`;

export default Sellers;
