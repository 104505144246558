import { FlexCol, OrderCancelRequestComponent } from 'eshop-defaults';
import { rem } from 'polished';
import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import {
  fetchFinishedOrder,
  fetchOrderHtmlBody,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
  orderBodyDataSelector,
} from '../MyAccount/myAccountSlice';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { sendCancelOrderRequest } from './actions';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  orderBody: ThenArg<typeof API.getOrderBodyHtml>;
  params: {
    orderPublicId: string;
  };
}

interface State {
  emailSent: boolean;
}

class OrderCancelRequest extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      emailSent: false,
    };
  }

  public static async getInitialProps(props) {
    const { dispatch } = props;
    await dispatch(fetchFinishedOrder(props.params.orderPublicId));
    await dispatch(fetchOrderHtmlBody(props.params.orderPublicId));
  }

  public async onConfirmClick() {
    await sendCancelOrderRequest(this.props.params.orderPublicId);
    this.setState({
      emailSent: true,
    });
  }

  public render() {
    const { orderData, orderBody } = this.props;

    return (
      <OrderCancelRequestComponent
        order={orderData}
        emailSent={this.state.emailSent}
        onConfirmClick={() => {
          this.onConfirmClick();
        }}
        bodyHtml={orderBody && orderBody.body ? orderBody.body : undefined}
      />
    );
  }
}

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${rem(36)};
`;

const mapStateToProps = state => {
  return {
    orderData: finishedOrderDataSelector(state),
    orderBody: orderBodyDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrderCancelRequest' })(OrderCancelRequest),
);
