import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiBackorder as Backorder,
  PaginationSimple,
} from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import API, { ThenArg } from '../../services/API';

interface Props {
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
}

function MyBackorder({
  currentId,
  orders,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
}: Props) {
  const { limit, offset, total } = ordersData;

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {__('Backorder')}
      </StyledTitle>
      {options && options.length > 1 && (
        <Select
          value={currentId}
          options={options}
          onChange={handleSelectChange}
        />
      )}
      <Backorder
        currency={'EUR'}
        isFetching={isFetchingOrders}
        orders={orders}
        user={null}
      />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyBackorder;
