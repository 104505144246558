import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const userDomainSelector = state => state.user;

const ordersDomainSelector = createSelector(
  userDomainSelector,
  substate => substate.orders,
);

const allOrdersDomainSelector = createSelector(
  ordersDomainSelector,
  substate => substate.all,
);

export const allOrdersDataSelector = createSelector(
  allOrdersDomainSelector,
  substate => prop(substate, 'data.orders', []),
);

export const allOrdersIsFetchingSelector = createSelector(
  allOrdersDomainSelector,
  substate => substate.isFetching,
);

const activeOrdersDomainSelector = createSelector(
  ordersDomainSelector,
  substate => substate.active,
);

export const activeOrdersDataSelector = createSelector(
  activeOrdersDomainSelector,
  substate => substate.data.orders,
);

export const activeOrdersIsFetchingSelector = createSelector(
  activeOrdersDomainSelector,
  substate => substate.isFetching,
);

const userInfoDomainSelector = createSelector(
  userDomainSelector,
  substate => substate.userInfo,
);

export const userInfoSelector = createSelector(
  userInfoDomainSelector,
  substate => substate.data,
);

export const userInfoIsFetchingSelector = createSelector(
  userInfoDomainSelector,
  substate => substate.isFetching,
);

const userDeliveryAddressesDomainSelector = createSelector(
  userDomainSelector,
  substate => substate.deliveryAddresses,
);

export const userDeliveryAddressesIsFetchingSelector = createSelector(
  userDeliveryAddressesDomainSelector,
  substate => substate.isFetching,
);

export const userDeliveryAddressesDataSelector = createSelector(
  userDeliveryAddressesDomainSelector,
  substate => substate.data,
);

export const updatedDeliveryAddressSelector = createSelector(
  userDeliveryAddressesDomainSelector,
  substate => substate.newDeliveryAddress,
);

const userPointsDomainSelector = createSelector(
  userDomainSelector,
  substate => substate.points,
);

export const userPointsDataSelector = createSelector(
  userPointsDomainSelector,
  substate => substate.data,
);

export const userPointsIsFetchingSelector = createSelector(
  userPointsDomainSelector,
  substate => substate.isFetching,
);

const userPasswordChangeDomainSelector = createSelector(
  userDomainSelector,
  substate => substate.passwordChange,
);

export const userPasswordChangePropsSelector = createSelector(
  userPasswordChangeDomainSelector,
  substate => {
    return {
      passwordCheck: substate.password_check,
      newPassword: substate.new_password,
      currentPassword: substate.current_password,
    };
  },
);

export const userPasswordIsFetchingSelector = createSelector(
  userPasswordChangeDomainSelector,
  substate => substate.isFetching,
);

export const userPasswordOnSuccessSelector = createSelector(
  userPasswordChangeDomainSelector,
  substate => prop(substate, 'message', null),
);

export const userPasswordOnErrorSelector = createSelector(
  userPasswordChangeDomainSelector,
  substate => prop(substate, 'error', null),
);
