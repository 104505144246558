import styled from 'styled-components';
import * as React from 'react';
import { FlexColCenter, FlexRowCenter } from 'eshop-defaults';
import { theme } from '../../../theme/theme';
import { rem } from 'polished';

interface Props {
  showX?: boolean;
  size: any;
  closeModal: () => void;
  style?: React.CSSProperties;
  modalError?: string;
  onErrorClose?: any;
}

export default class Modal extends React.Component<Props, {}> {
  public componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }
  public componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }
  public escFunction = event => {
    if (event.keyCode === 27) {
      this.props.closeModal();
    }
  };

  public render() {
    let showX = true;
    let width = '50%';

    if (this.props.showX === false) {
      showX = this.props.showX;
    }

    if (this.props.size) {
      if (this.props.size === 'small') {
        width = '20%';
      } else if (this.props.size === 'medium') {
        width = '50%';
      } else if (this.props.size === 'big') {
        width = '85%';
      } else {
        width = this.props.size;
      }
    }

    const errorStyle: any = {};

    if (this.props.modalError) {
      errorStyle.borderRadius = '0px';
      errorStyle.borderRadius = '0';
      errorStyle.borderTopLeftRadius = '6px';
      errorStyle.borderTopRightRadius = '6px';
    }

    return (
      <React.Fragment>
        <ModalStyle />
        <ModalHolder onClick={this.props.closeModal}>
          <ModalBox
            width={width}
            style={{ ...errorStyle, ...this.props.style }}
            onClick={this.stopClosing}
          >
            {showX && (
              <CancelButton
                alt=""
                onClick={this.props.closeModal}
                src={'/images/cancel.svg'}
              />
            )}

            {this.props.children}
          </ModalBox>

          {this.props.modalError && (
            <ModalError style={{ width }} onClick={this.stopClosing}>
              <span>{this.props.modalError}</span>
              <CancelErrorButton
                alt=""
                onClick={this.props.onErrorClose}
                src={'/images/cancel-white.svg'}
              />
            </ModalError>
          )}
        </ModalHolder>
      </React.Fragment>
    );
  }

  private stopClosing = e => {
    e.stopPropagation();
  };
}

const CancelErrorButton = styled.img`
  cursor: pointer;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
`;

const CancelButton = styled.img`
  position: absolute;
  top: ${rem(30)};
  left: 66vw;
  width: 16px;
  height: auto;
  cursor: pointer;
  z-index: 100;

  @media all and (max-width: 900px) {
    left: 65vw;
  }

  @media all and (max-width: 650px) {
    left: 63vw;
  }

  @media all and (max-width: 450px) {
    left: 61vw;
  }
`;

const ModalHolder = styled(FlexColCenter)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 95000;
`;

interface ModalBoxProps {
  width: string;
}
const ModalBox = styled.div`
  position: relative;
  height: 85%;
  opacity: 1;
  background: #ffffff;
  padding: 25px;
  border-radius: ${theme.borderRadius};
  padding-bottom: 10px;
  width: ${(props: ModalBoxProps) => props.width};

  @media all and (max-width: 500px) {
    width: 90%;
  }
`;

const ModalStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.2;
  z-index: ${theme.index.modal};
`;

const ModalError = styled(FlexRowCenter)`
  position: relative;
  padding: 10px;
  text-align: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: ${theme.fontSizeS};
  color: #ffffff;
  background-color: rgb(238, 34, 123);
`;
