import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  customerInfoSelector,
  deliveryAddressesSelector,
  fetchCustomerDetails,
  fetchCustomerFinancialDetails,
  fetchDeliveryAddresses,
} from './myAccountSlice';
import MyInfo from '../../components/MyAccount/MyInfo';
import API, { ThenArg } from '../../services/API';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';
import { langSelector } from '../App/selectors';

interface Props {
  user: any;
  dispatch: any;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  lang: string;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
}

class MyInfoContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, user } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_INFO), { user });
      await Promise.all([
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
        await dispatch(fetchDeliveryAddresses()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public render() {
    const { customerInfo, user, lang, deliveryAddresses } = this.props;
    if (!customerInfo) {
      return null;
    }

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Moje údaje') }} />
        <MyInfo
          customerInfo={customerInfo}
          userId={user && user.id}
          isB2B={user && user.b2b ? true : false}
          lang={lang}
          deliveryAddresses={deliveryAddresses}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
    lang: langSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyInfoContainer' })(MyInfoContainer),
);
