import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// import { __ } from 'react-i18n';
import { __r } from 'react-i18n/lib';

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
}

/**
 * Zobrazi obsah len ak je uzivatel prihlaseny
 * @param ProtectedComponent
 * @returns {any}
 */
export const authProtected = ProtectedComponent => {
  class ContentProtector extends React.PureComponent<Props> {
    public static getDerivedStateFromProps(props) {
      const { user, isFetching, dispatch } = props;
      if (!isFetching && (!user || !user.id)) {
        dispatch(push('/prihlasenie'));
      }
      return {};
    }

    public state = {};

    public render() {
      const { user } = this.props;
      if (user && user.id) {
        return <ProtectedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapStateToProps = state => ({
    user: state.auth.user,
    isFetching: state.auth.isFetchingUser,
  });

  return connect(mapStateToProps)(ContentProtector);
};

/**
 * Zobrazi obsah len ak nie je prihlaseny uzivatel
 * @param PublicComponent
 * @returns {any}
 */
export const onlyPublic = PublicComponent => {
  class Content extends React.PureComponent<Props> {
    public static getDerivedStateFromProps(props) {
      const { user, isFetching, dispatch } = props;
      if (!isFetching && user && user.id) {
        dispatch(push(__r('routes:moj-ucet', '/moj-ucet')));
      }
      return {};
    }

    public state = {};

    public render() {
      const { user, isFetching } = this.props;
      if (!isFetching && (!user || !user.id)) {
        return <PublicComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapStateToProps = state => ({
    user: state.auth.user,
    isFetching: state.auth.isFetchingUser,
  });

  return connect(mapStateToProps)(Content);
};
