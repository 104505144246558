import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';

const ParallaxImageWrapper = styled.div`
  background-image: ${({ src }) => `url('${src}')`};

  /* Set a specific height */
  height: ${rem(600)};

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.fullwidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  &.articleTitle {
    margin-bottom: ${rem(48)};

    ${({ theme }) => theme.mediab.m580`
      margin-bottom: ${rem(16)};
      max-width: 100%;
      background-attachment: scroll;
    `}
  }

  @media all and (max-width: 960px) {
    width: 100%;
    height: auto;
    padding-bottom: 56.25%;
  }

  ${({ theme }) => theme.mediab.m580`
      background-attachment: scroll;
  `}
`;

interface Props {
  src: string;
  fullwidth?: boolean;
  articleTitle?: boolean;
}

const ParallaxImage: React.SFC<Props> = ({ src, fullwidth, articleTitle }) => {
  let className = '';
  if (articleTitle) {
    className += ' articleTitle ';
  }
  if (fullwidth) {
    className += ' fullwidth ';
  }

  return <ParallaxImageWrapper className={className} src={src} />;
};

export default ParallaxImage;
