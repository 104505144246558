import { SearchType } from './types';

export const searchReducer = (
  state: any = { searchedProducts: null, searchParams: { total: 0 } },
  action: SearchType,
) => {
  switch (action.type) {
    case 'Search/REQUEST_PRODUCTS': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'Search/RECEIVE_PRODUCTS_SUCCESS': {
      let newProducts;
      if (!state.searchedProducts) {
        newProducts = action.payload.productsSearchResult.products;
      } else {
        newProducts = [
          ...state.searchedProducts,
          ...action.payload.productsSearchResult.products,
        ];
      }

      return {
        ...state,
        isFetching: false,
        searchedProducts: newProducts,
        searchParams: {
          offset: action.payload.productsSearchResult.offset,
          limit: action.payload.productsSearchResult.limit,
          total: action.payload.productsSearchResult.total,
          priceMin: action.payload.productsSearchResult.price_min,
          priceMax: action.payload.productsSearchResult.price_max,
        },
      };
    }
    case 'Search/RECEIVE_PRODUCTS_ERROR': {
      let { searchedProducts } = state;
      if (searchedProducts === null) {
        searchedProducts = [];
      }
      return {
        ...state,
        isFetching: false,
        searchedProducts,
        error: action.payload.error,
      };
    }

    case 'Search/REMOVE_FETCHED_PRODUCTS': {
      return {
        ...state,
        searchedProducts: null,
      };
    }

    default: {
      return state;
    }
  }
};
