import * as React from 'react';
import { __ } from 'react-i18n/lib';
import GalleryGrid from './GalleryGrid';
import ReadTooArticle from './ReadTooArticle';
import ParallaxImage from './ParallaxImage';
import { replaceMatchesInBody } from '../../utilities/article';
import styled from 'styled-components';
import ImageGallery from './ImageGallery';
import { getImagePath } from '../../utilities/product';
import Modal from '../../components/_helpers/Default/Modal';
import { rem } from 'polished';
import { CmsFaq } from './CmsFaq';
import { CmsSearch } from './CmsSearch';
import { CmsTable } from './CmsTable';
import { FAQMicrodata } from '@bart.sk-ecommerce/react-microdata';
import LightBoxImage from './LightBoxImage';

// imports function for spliting html to parts by tag
// tslint:disable-next-line:no-var-requires
require('split-html');

const CmsContent = styled.div`
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.blogColors.primary};
  line-height: ${rem(26)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.3333;
    margin-bottom: ${rem(24)};
    margin-top: 0;
  }

  & img {
    margin: ${rem(32)} 0;

    ${({ theme }) => theme.mediab.l1150`
      margin: ${rem(24)} 0;
    `}
  }

  & strong {
    /* line-height: ${rem(32)}; */
    /* margin: 0 0 ${rem(16)}; */
    display: inline-block;
  }

  & li strong {
    margin: 0;
  }

  & li {
    margin-bottom: ${rem(8)};
  }

  & p {
    line-height: ${rem(26)};
    font-family: ${({ theme }) => theme.blogFonts.primaryFont};
    font-size: ${rem(18)};
    color: ${({ theme }) => theme.blogColors.primary};
  }

  & .article-link {
    line-height: ${rem(16)};
    font-size: ${rem(20)};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    padding: ${rem(18)} ${rem(32)};
    border-radius: ${rem(4)};
  }

  iframe {
    max-width: 100%;
  }
`;

interface Props {
  inputBody: string;
  type?: string;
  articleTitle: string;
  articleType?: string;
  filesToDownloads?: any;
}

declare global {
  interface Window {
    splitHtml: any;
  }
}

const RenderedBody: React.SFC<Props> = ({
  inputBody,
  type,
  articleTitle,
  articleType,
  filesToDownloads,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [galleryPhotos, setGalleryPhotos] = React.useState([]);

  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchTermChange = event => {
    const target = event.target;
    const value = target.value;
    setSearchTerm(value);
  };

  const removeEmptyTags = body => {
    return body
      .replace(/<parallax> <\/parallax>/g, '')
      .replace(/<photogallery> <\/photogallery>/g, '')
      .replace(/<readtoo> <\/readtoo>/g, '')
      .replace(/<faq> <\/faq>/g, '');
    // .replace(/<table> <\/table>/g, '');
  };

  const createBodyArray = body => {
    body = removeEmptyTags(body);

    let newBodyArray = [];

    if (window && window.splitHtml && body[0] === '<') {
      // img regex - \<img ([\s\S]*?data\-parallax[\s\S]*?) \/>
      let newBody = body;
      // const allParallaxMatchesRegex = /\[PARALLAX([\s\S]*?)\]/g;
      const allParallaxMatchesRegex = /\\<parallax>([\s\S]*?)<\/parallax>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allParallaxMatchesRegex,
        'parallax',
        [
          { matchValueId: 'data-src', newValueId: 'src' },
          { matchValueId: 'data-alt', newValueId: 'alt' },
          { matchValueId: 'data-fullwidth', newValueId: 'fullwidth' },
        ],
      );
      // const allReadtooMatchesRegex = /\[READTOO([\s\S]*?)\]/g;
      const allReadtooMatchesRegex = /\\<readtoo>([\s\S]*?)<\/readtoo>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allReadtooMatchesRegex,
        'readtoo',
        [{ matchValueId: 'data-id', newValueId: 'id' }],
      );

      const allPhotogalleryMatchesRegex = /\\<photogallery>([\s\S]*?)<\/photogallery>/g;
      newBody = replaceMatchesInBody(
        newBody,
        allPhotogalleryMatchesRegex,
        'photogallery',
        [
          { matchValueId: 'data-images', newValueId: 'images' },
          { matchValueId: 'data-columns', newValueId: 'columns' },
        ],
      );

      const allFAQMatchesRegex = /\\<p data--type="faq"([\s\S]*?)>/g;
      newBody = replaceMatchesInBody(newBody, allFAQMatchesRegex, 'p', [
        { matchValueId: 'data-question', newValueId: 'question' },
        { matchValueId: 'data-answer', newValueId: 'answer' },
      ]);

      const lightBoxRegex = /<a[^>]*class="[^"]*is-lightbox[^"]*"[^>]*>([\s\S]*?)<\/a>/g;
      newBody = replaceMatchesInBody(
        newBody,
        lightBoxRegex,
        'lightbox class="lightbox"',
        [{ matchValueId: 'href', newValueId: 'image-src' }],
      );

      // const allSearchInputMatchesRegex = /\\<input data--type="searchbar"([\s\S]*?)\/>/g;
      // newBody = replaceMatchesInBody(
      //   newBody,
      //   allSearchInputMatchesRegex,
      //   'input',
      //   [],
      // );

      // const allTableMatchesRegex = /\\<table([\s\S]*?)\/>/g;
      // newBody = replaceMatchesInBody(
      //   newBody,
      //   allTableMatchesRegex,
      //   'table',
      //   [],
      // );

      const htmlArray = (window as any).splitHtml(newBody, 'parallax');
      const newReadtooHtmlArray: any = [];
      if (newBody.includes('<readtoo')) {
        htmlArray.map((html, i) => {
          if (html.includes('<readtoo')) {
            const newArray = (window as any).splitHtml(html, 'readtoo');
            newArray.map(a => newReadtooHtmlArray.push(a));
          } else {
            newReadtooHtmlArray.push(html);
          }
        });
      } else {
        htmlArray.map((html, i) => {
          newReadtooHtmlArray.push(html);
        });
      }

      newBodyArray = newReadtooHtmlArray;

      const newPhotogalleryHtmlArray: any = [];
      if (newBody.includes('<photogallery') && newBodyArray) {
        newBodyArray.map((html: string, i) => {
          if (html.includes('<photogallery')) {
            const newArray = (window as any).splitHtml(html, 'photogallery');
            newArray.map(a => newPhotogalleryHtmlArray.push(a));
          } else {
            newPhotogalleryHtmlArray.push(html);
          }
        });
      } else {
        newBodyArray.map((html, i) => {
          newPhotogalleryHtmlArray.push(html);
        });
      }
      newBodyArray = newPhotogalleryHtmlArray;

      const newFAQHtmlArray: any = [];
      if (newBody.includes('<p data--type="faq"') && newBodyArray) {
        newBodyArray.map((html: string, i) => {
          if (html.includes('<p data--type="faq"')) {
            const newArray = (window as any).splitHtml(html, 'p');
            newArray.map(a => newFAQHtmlArray.push(a));
          } else {
            newFAQHtmlArray.push(html);
          }
        });
      } else {
        newBodyArray.map((html, i) => {
          newFAQHtmlArray.push(html);
        });
      }
      newBodyArray = newFAQHtmlArray;

      const newLightBoxArray: any = [];
      if (newBody.includes('<lightbox class="lightbox"') && newBodyArray) {
        newBodyArray.map((html: string, i) => {
          if (html.includes('<lightbox class="lightbox"')) {
            const newArray = (window as any).splitHtml(html, 'lightbox');
            newArray.map(a => newLightBoxArray.push(a));
          } else {
            newLightBoxArray.push(html);
          }
        });
      } else {
        newBodyArray.map((html, i) => {
          newLightBoxArray.push(html);
        });
      }

      newBodyArray = newLightBoxArray;

      // const newTableHtmlArray: any = [];
      // if (newBody.includes('<table') && newBodyArray) {
      //   newBodyArray.map((html: string, i) => {
      //     if (html.includes('<table')) {
      //       const newArray = (window as any).splitHtml(html, 'table');
      //       newArray.map(a => newTableHtmlArray.push(a));
      //     } else {
      //       newTableHtmlArray.push(html);
      //     }
      //   });
      // } else {
      //   newBodyArray.map((html, i) => {
      //     newTableHtmlArray.push(html);
      //   });
      // }
      // newBodyArray = newTableHtmlArray;
    }

    return newBodyArray;
  };

  const renderContent = body => {
    body = body
      ? body.replace(new RegExp(' alt ', 'g'), ` alt="${articleTitle}" `)
      : '';
    const newBodyArray = createBodyArray(body);
    return (
      <>
        {newBodyArray && newBodyArray.length > 0 ? (
          <FAQMicrodata
            items={newBodyArray
              .filter((part: any) => part.includes('<p data--type="faq"'))
              .map((part: any) => {
                const questionSplit = part.split('question="');
                let question = '';
                if (questionSplit.length > 1) {
                  question = questionSplit[1].split('"')[0];
                }

                const answerSplit = part.split('answer="');
                let answer = '';
                if (answerSplit.length > 1) {
                  answer = answerSplit[1].split('"')[0];
                }
                return {
                  question,
                  answer,
                };
              })}
          />
        ) : null}

        {newBodyArray && newBodyArray.length > 0 ? (
          newBodyArray.map((part: any, i) => {
            if (part.includes('<parallax')) {
              const srcSplit = part.split('src="');
              let source = '';
              if (part && srcSplit.length > 1) {
                source = srcSplit[1].split('"')[0];
              }

              const fullwidthSplit = part.split('fullwidth="');
              let fullWidth = false;

              if (
                part &&
                srcSplit.length > 1 &&
                type !== 'ebook' &&
                fullwidthSplit.length > 1
              ) {
                fullWidth =
                  fullwidthSplit[1].split('"')[0] === 'true' ? true : false;
              }
              return <ParallaxImage fullwidth={fullWidth} src={source} />;
            }
            if (part.includes('<readtoo')) {
              const srcSplit = part.split('id="');
              let id = '';
              if (part && srcSplit.length > 1) {
                id = srcSplit[1].split('"')[0];
              }
              return <ReadTooArticle id={id} />;
            }

            if (part.includes('<photogallery')) {
              const gridSplit = part.split('columns="');
              let columns = 0;
              if (gridSplit.length > 1) {
                columns = gridSplit[1].split('"')[0];
              }

              const imagesSplit = part.split('images="');
              let imagesIds;
              if (imagesSplit.length > 1) {
                imagesIds = imagesSplit[1].split('"')[0].split(',');
              }
              return (
                <GalleryGrid
                  articleTitle={articleTitle}
                  type={type}
                  openModal={openModal}
                  columns={columns}
                  imagesIds={imagesIds}
                />
              );
            }

            if (part.includes('<p data--type="faq"')) {
              const questionSplit = part.split('question="');
              let question = '';
              if (questionSplit.length > 1) {
                question = questionSplit[1].split('"')[0];
              }

              const answerSplit = part.split('answer="');
              let answer = '';
              if (answerSplit.length > 1) {
                answer = answerSplit[1].split('"')[0];
              }

              return <CmsFaq question={question} answer={answer} />;
            }

            if (part.includes('<lightbox')) {
              const regex = /image-src="([^"]+)"/;
              const match = part.match(regex);

              if (match) {
                const imageSrc = match[1];
                return <LightBoxImage src={imageSrc} openModal={openModal} />;
              }
            }

            // if (part.includes('<input data--type="searchbar"')) {
            //   return (
            //     <CmsSearch
            //       handleSearchTermChange={handleSearchTermChange}
            //       searchTerm={searchTerm}
            //     />
            //   );
            // }

            // if (part.includes('<table')) {
            //   return <CmsTable table={part} searchTerm={searchTerm} />;
            // }

            if (articleType === 'podpora/firmware') {
              return (
                <>
                  <CmsSearch
                    handleSearchTermChange={handleSearchTermChange}
                    searchTerm={searchTerm}
                  />
                  <CmsTable
                    filesToDownloads={filesToDownloads}
                    searchTerm={searchTerm}
                  />
                </>
              );
            }

            return (
              <CmsContent key={i} dangerouslySetInnerHTML={{ __html: part }} />
            );
          })
        ) : (
          <CmsContent dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </>
    );
  };

  const openModal = (array, index) => {
    const fullscreenImages = array.map(doc => {
      const regex = /query=([^&]+)/;
      const match = doc.document_path.match(regex);
      if (match) {
        const query = match[1];
        doc.document_path = query;
      }

      if (doc.document_path.startsWith('http')) {
        return {
          original: doc.document_path,
          originalWebP: doc.document_path,
          thumbnail: doc.document_path,
          thumbnailWebP: doc.document_path,
          name:
            (doc.document_description && doc.document_description !== '') ||
            doc.document_name ||
            '',
        };
      } else {
        const img = getImagePath(doc.document_path, {
          width: 800,
          height: 600,
        });
        const imgWebP = getImagePath(
          doc.document_path,
          {
            width: 800,
            height: 600,
          },
          false,
          true,
        );

        const thumbImg = getImagePath(doc.document_path, {
          width: 87,
          height: 104,
        });

        const thumbImgWebP = getImagePath(
          doc.document_path,
          {
            width: 87,
            height: 104,
          },
          false,
          true,
        );

        return {
          original: img,
          originalWebP: imgWebP,
          thumbnail: thumbImg,
          thumbnailWebP: thumbImgWebP,
          name:
            (doc.document_description && doc.document_description !== '') ||
            doc.document_name ||
            '',
        };
      }
    });

    setIsOpen(true);
    setPhotoIndex(index);
    setGalleryPhotos(fullscreenImages);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setCurrentImage = i => {
    setPhotoIndex(i);
  };

  return (
    <>
      {' '}
      {isOpen && (
        <Modal size="big" showX={false} closeModal={closeModal}>
          <ImageGallery
            closeModal={closeModal}
            setCurrentImage={setCurrentImage}
            items={galleryPhotos}
            currentIndex={photoIndex}
          />
        </Modal>
      )}
      {renderContent(inputBody)}
    </>
  );
};

export default RenderedBody;
