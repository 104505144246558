import { css } from 'styled-components';

export const transitionDefaults = {
  duration: {
    default: '.3s',
  },
  function: {
    default: 'ease',
  },
};

const defaultDuration = transitionDefaults.duration.default;
const defaultFunction = transitionDefaults.function.default;

export const transition = (
  property: string,
  duration?: string,
  timingFunction?: string,
) => {
  return css`
    transition: ${property} ${duration ? duration : defaultDuration}
      ${timingFunction ? timingFunction : defaultFunction};
  `;
};

export const transitions = (
  properties: string,
  duration?: string,
  timingFunction?: string,
) => {
  return css`
    transition-property: ${properties};
    transition-duration: ${duration ? duration : defaultDuration};
    transition-timing-function: ${timingFunction
      ? timingFunction
      : defaultFunction};
  `;
};
