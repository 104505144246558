import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { DsiSvgIcon } from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';

interface Props {
  question: string;
  answer: string;
}

export function CmsFaq({ question, answer }: Props) {
  const [isOpened, setIsOpened] = React.useState(false);

  const toggleIsOpened = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Wrapper isOpened={isOpened} onClick={toggleIsOpened}>
      <Question>
        {question}
        <IconCover isOpened={isOpened}>
          <DsiSvgIcon
            icon={IconType.arrowDown}
            alt={isOpened ? 'Skryť odpoveď' : 'Zobraziť odpoveď'}
            width={16}
            height={16}
            viewBox="-2 -2 16 16"
            fill="#414141"
          />
        </IconCover>
      </Question>
      {isOpened && <Answer>{answer}</Answer>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isOpened: boolean }>`
  max-width: 100%;
  width: 100%;
  background: ${props => (props.isOpened ? rgba('#fff', 0.56) : '#fff')};
  border-radius: ${rem(4)};
  box-shadow: 0 ${rem(1)} ${rem(4)} #00000014;
  padding: ${rem(16)};
  position: relative;
  margin-bottom: ${rem(8)};
`;

const Question = styled.h3`
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Answer = styled.p`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-top: ${rem(16)};
  margin-bottom: 0;
`;

const IconCover = styled.div<{ isOpened: boolean }>`
  transform: ${props => (props.isOpened ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: ${rem(16)};
  height: ${rem(16)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
