import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { getImagePath } from '../../utilities/product';
import { FlexColCenter, Picture } from 'eshop-defaults';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import SliderComponent from '../_helpers/Slider/Slider';
import API from '../../services/API';
import { connectSsr } from 'ssr-service';
import { css } from '../../theme/styled-components';

const DesktopWrapper = styled.div`
  /* display: grid;
  grid-template-columns: ${({ columns }) => ` repeat(${columns}, 1fr)`};
  grid-gap: ${rem(16)}; */
  display: flex;
    flex-wrap: wrap;
  
  margin: ${rem(64)} 0;

  width: ${({ type }) => (type === 'ebook' ? '100%' : '100vw')};
  position: relative;
  left: ${({ type }) => (type === 'ebook' ? 0 : '50%')};
  right: ${({ type }) => (type === 'ebook' ? 0 : '50%')};
  margin-left: ${({ type }) => (type === 'ebook' ? 0 : '-50vw')};
  margin-right: ${({ type }) => (type === 'ebook' ? 0 : '-50vw')};
  padding: ${({ type }) => (type === 'ebook' ? 0 : '0 30vw')};

  @media (max-width: 1920px) {
    padding: ${({ type }) => (type === 'ebook' ? 0 : '0 8vw')};
  }

  @media (max-width: 1320px) {
    padding: ${({ type }) => (type === 'ebook' ? 0 : `0 ${rem(24)};`)};
  }

  @media (max-width: 920px) {
    padding: ${({ type }) => (type === 'ebook' ? 0 : `0 ${rem(24)};`)};
  }

  @media (max-width: 580px) {
    display: none;
  }
`;

const StyledMobileWrapper = styled(MobileWrapper)`
  margin-top: ${rem(24)};
`;

const GalleryImageWrapper = styled(FlexColCenter)`
  cursor: pointer;
  flex: 1;
  min-width: ${({ columns }) => `${100 / columns - 2}%`};
  margin-right: ${rem(16)};
  margin-bottom: ${rem(16)};
`;

const sharedGalleryImage = css`
  max-width: 100%;
  height: auto;
  max-height: ${rem(600)};
  border: none;
  border-radius: ${rem(4)};
`;

const GalleryImage = styled.img`
  ${sharedGalleryImage}
`;

interface Props {
  columns: number;
  imagesIds: [];
  openModal: (array, index) => void;
  photogalleryArray: any;
  type?: string;
  articleTitle: string;
}

class GalleryGrid extends React.Component<Props, {}> {
  public static async getInitialProps(props) {
    const abbc = await API.listPhotogalleryImages(
      {},
      { images_ids: props.imagesIds },
    );
    return {
      photogalleryArray: await API.listPhotogalleryImages(
        {},
        { images_ids: props.imagesIds },
      ),
    };
  }

  public openModalWithThisGalleryImages = i => {
    const { openModal, photogalleryArray } = this.props;
    if (photogalleryArray) {
      openModal(photogalleryArray.photogallery, i);
    }
  };

  public render() {
    const { columns, photogalleryArray, type, articleTitle } = this.props;
    if (!photogalleryArray || !photogalleryArray.photogallery) {
      return null;
    }
    const { photogallery } = photogalleryArray;

    return (
      <>
        <DesktopWrapper type={type}>
          {photogallery &&
            photogallery.map((doc, i) => {
              const alt = doc.document_description || articleTitle;
              return (
                <GalleryImageWrapper
                  columns={columns}
                  onClick={this.openModalWithThisGalleryImages.bind(this, i)}
                  key={doc.document_id}
                >
                  <Picture
                    image={doc.document_path}
                    width={500}
                    height={300}
                    customStyles={sharedGalleryImage}
                  />
                </GalleryImageWrapper>
              );
            })}
        </DesktopWrapper>
        <StyledMobileWrapper columns={columns}>
          <SliderComponent
            type="photogallery"
            slides={photogallery.map((doc, i) => ({
              img: doc.document_path,
              name: i.toString(),
            }))}
          />
        </StyledMobileWrapper>
      </>
    );
  }
}

export default connectSsr({ displayName: 'GalleryGrid' })(GalleryGrid);
