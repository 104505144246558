import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { FlexCol, DsiFavorites as Favorites } from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import { FavoriteBrands } from '../Home/FavoriteBrands';

interface Props {
  favorites: any;
  favoritesData: any;
  favoriteCategories: any;
  favoriteBrands: any;
  apiUrl: string;
  handleOffsetChange: (e) => void;
  isFetching: boolean;
}

function MyFavorites({
  favorites,
  favoriteCategories,
  favoriteBrands,
  apiUrl,
  isFetching,
}: Props) {
  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {__('Obľúbené')}
      </StyledTitle>
      <Favorites
        isFetching={isFetching}
        products={favorites.products}
        addToRequest={''}
        addToCart={''}
        apiUrl={apiUrl}
        categories={favoriteCategories}
      />
      <FavoriteBrands brands={favoriteBrands} />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;
  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyFavorites;
