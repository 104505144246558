import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import {
  loadBlogArticles,
  loadFavoriteCategories,
  loadHomeBanner,
  loadHomeFavoritesProducts,
  loadHomeRecommendedProducts,
  loadHomeShoppingConsultants,
  loadHomeTopTechnologies,
  loadReviews,
  loadTopBrands,
} from './actions';
import {
  homeTitleSelector,
  langSelector,
  titleSelector,
} from '../Category/selectors';
import {
  adBannerSelector,
  topBrandsSelector,
  productList1Selector,
  productList2Selector,
  homeCategoriesSelector,
  homeCategoriesIsFetchingSelector,
  reviewsSelector,
  blogArticlesSelector,
  favoriteProductsSelector,
  recommendedProductsSelector,
  shoppingConsultantsSelector,
  topTechnologiesSelector,
} from './selectors';
import Home from '../../components/Home/Home';
import { addItemToCart } from '../Cart/cartSlice';
import selectors, {
  generalSettingsSelector,
  hostnameSelector,
  socialUrlsDataSelector,
} from '../App/selectors';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  banners: any;
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  adBanner: any;
  router: any;
  location: any;
  productList1: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  productList2: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  homeCategories: ThenArg<typeof API.loadCategories>;
  categoriesIsFetching: boolean;
  dimmerVisible: boolean;
  brands: any;
  reviews: ThenArg<typeof API.loadArticles>;
  themes: ThenArg<typeof API.loadArticles>;
  articlesIsFetching: false;
  socialUrls: {
    [key: string]: string;
  };
  hostname: string;
  blogArticles: ThenArg<typeof API.loadArticles>;
  favoriteProducts: any;
  recommendedProducts: any;
  shoppingConsultants: any;
  topTechnologies: any;
  settings: any;
}

class HomeContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await Promise.all([
        dispatch(loadHomeBanner()),
        dispatch(loadBlogArticles()),
        dispatch(loadHomeShoppingConsultants()),
        dispatch(loadHomeTopTechnologies()),
      ]);

      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    if (window) {
      Promise.all([
        dispatch(loadHomeFavoritesProducts()),
        dispatch(loadHomeRecommendedProducts()),
      ]);
    }
  }

  public render() {
    const {
      homeCategories,
      categoriesIsFetching,
      productList2,
      productList1,
      dimmerVisible,
      dispatch,
      adBanner,
      brands,
      reviews,
      themes,
      socialUrls,
      lang,
      hostname,
      blogArticles,
      favoriteProducts,
      recommendedProducts,
      shoppingConsultants,
      topTechnologies,
      settings,
      defaultTitle,
    } = this.props;
    return (
      <Home
        categories={homeCategories}
        addToCart={this.addToCart}
        addToRequest={this.addToRequest}
        categoriesIsFetching={categoriesIsFetching}
        productList1={productList1}
        productList2={productList2}
        dimmerVisible={dimmerVisible}
        dispatch={dispatch}
        adBanner={adBanner}
        brands={brands}
        reviews={reviews}
        themes={themes}
        socialUrls={socialUrls}
        lang={lang}
        hostname={hostname}
        blogArticles={blogArticles}
        favoriteProducts={favoriteProducts}
        recommendedProducts={recommendedProducts}
        shoppingConsultants={shoppingConsultants}
        topTechnologies={topTechnologies}
        mainSlideInterval={
          settings?.homepageSlideInterval
            ? parseInt(settings.homepageSlideInterval) * 1000
            : undefined
        }
        title={defaultTitle}
      />
    );
  }

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count));
  };

  private addToRequest = () => {
    // console.log('add to request');
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: homeTitleSelector(state),
    lang: langSelector(state),
    productList1: productList1Selector(state),
    productList2: productList2Selector(state),
    adBanner: adBannerSelector(state),
    brands: topBrandsSelector(state),
    homeCategories: homeCategoriesSelector(state),
    categoriesIsFetching: homeCategoriesIsFetchingSelector(state),
    reviews: reviewsSelector(state),
    socialUrls: socialUrlsDataSelector(state),
    hostname: hostnameSelector(state),
    blogArticles: blogArticlesSelector(state),
    favoriteProducts: favoriteProductsSelector(state),
    recommendedProducts: recommendedProductsSelector(state),
    shoppingConsultants: shoppingConsultantsSelector(state),
    topTechnologies: topTechnologiesSelector(state),
    settings: generalSettingsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HomeContainer' })(HomeContainer),
);
