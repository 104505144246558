import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { withRouter, WithRouterProps } from 'react-router';
import {
  cartDataSelector,
  cartIsFetchingSelector,
  cartErrorSelector,
  updateCart,
  fetchCart,
  cartShowProblemsSelector,
  cartZoneIdSelector,
  setCartError,
  fetchDeliveryPaymentInfo,
  cartDelPayInfoByIdSelector,
  cartDelPayInfoIsFetchingSelector,
  resetCart,
  addItemToCart,
  removeItemFromCart,
  addGiftCard,
  removeGiftCard,
  couponErrorSelector,
  cartFreeDelPayInfoSelector,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import {
  deliveryAddressesIsFetchingSelector,
  deliveryAddressesSelector,
  fetchDeliveryAddresses,
} from '../MyAccount/myAccountSlice';
import { hostnameSelector, langSelector, userSelector } from '../App/selectors';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { __r } from 'react-i18n';
import { Cart } from '../../components/Cart/Cart';
import { CART_COOKIE_ID, removeCartCookie } from './helpers';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { USER_COOKIE } from 'react-auth/lib';
import * as cookie from 'react-cookies';
import { prop } from '../../utilities';

interface Props {
  dispatch: (action: any) => void;
  children: any;
  user: any;
  data: any;
  cookies: any;
  error: { details: { description: string } };
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  deliveryAddressesIsFetching: boolean;
  showProblems: boolean;
  zoneId: number;
  delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>;
  isFetchingDelAndPayInfo: boolean;
  lang: string;
  couponError: string | null;
  freeDelivery: ThenArg<typeof API.getFreeDeliveryInfo>;
  hostname: string;
}

interface State {
  couponCode: string;
}

class CartContainer extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: '',
    };
  }

  public static async getInitialProps(props) {
    const { dispatch, cookies, location } = props;
    try {
      dispatch(
        setBreadCrumbPath(BreadCrumbType.CART, __r('routes:kosik', '/kosik')),
      );

      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      await Promise.all[
        await dispatch(fetchDeliveryAddresses())
        //await dispatch(fetchCart(false, cartCookie)),
        //await dispatch(fetchDeliveryPaymentInfo())
      ];
      return;
    } catch (exp) {
      console.log(`Cart error: ${JSON.stringify(exp)}`);
      return;
    }
  }

  componentDidMount(): void {
    const { dispatch, cookies } = this.props;
    async function fc() {
      if (window) {
        const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
        Promise.all([
          (await dispatch(fetchCart(false, cartCookie)),
          await dispatch(fetchDeliveryPaymentInfo())),
        ]);
      }
    }
    fc();
  }

  public onCouponCodeChanges(couponCode) {
    this.setState({
      couponCode: couponCode,
    });
  }

  public onGiftCardModeSelected(selectType) {
    this.props.dispatch(
      updateCart(
        { ...this.props.data, cart_giftcard_mode: selectType },
        false,
        this.state.couponCode,
      ),
    );
  }

  public render() {
    const {
      //   children,
      data,
      delAndPayInfoById,
      dispatch,
      isFetchingDelAndPayInfo,
      //   error,
      deliveryAddresses,
      isFetching,
      deliveryAddressesIsFetching,
      //   dispatch,
      showProblems,
      user,
      //   zoneId,
      lang,
      couponError,
      freeDelivery,
      hostname,
    } = this.props;

    return (
      <>
        <Cart
          deliveryAddressesIsFetching={deliveryAddressesIsFetching}
          isFetching={isFetching}
          showProblems={true}
          deliveryAddresses={deliveryAddresses}
          user={user}
          dispatch={dispatch}
          actualPrice={88}
          freeDeliveryLimit={200}
          data={data}
          delAndPayInfoById={delAndPayInfoById}
          isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
          handleAcceptTermsChange={this.handleAcceptTermsChange}
          handleHeurekaTermsChange={this.handleHeurekaTermsChange}
          handleCompleteOrder={this.handleCompleteOrder}
          handleGoBack={this.handleGoBack}
          addToCart={this.addToCart}
          removeFromCart={this.removeFromCart}
          lang={lang}
          couponCode={this.state.couponCode}
          onCouponCodeChanged={couponCode => {
            this.onCouponCodeChanges(couponCode);
          }}
          addCoupon={this.addCoupon}
          removeCoupon={this.removeCoupon}
          freeDelivery={freeDelivery}
          couponError={couponError}
          hostname={hostname}
          onGiftCardModeSelected={selectType =>
            this.onGiftCardModeSelected(selectType)
          }
        />
      </>
    );
  }

  private handleAcceptTermsChange = e => {
    this.props.dispatch(
      updateCart(
        { ...this.props.data, terms_accept: e.target.value },
        false,
        this.state.couponCode,
      ),
    );
  };

  private handleHeurekaTermsChange = e => {
    this.props.dispatch(
      updateCart(
        { ...this.props.data, heureka: e.target.value },
        false,
        this.state.couponCode,
      ),
    );
  };

  private handleCompleteOrder = async publicId => {
    removeCartCookie();
    this.props.dispatch(resetCart());
    this.props.router.push(
      `${__r(
        'routes:dokoncena-objednavka',
        '/dokoncena-objednavka',
      )}/${publicId}`,
    );
  };

  private addCoupon = (cardNumber: string) => {
    this.props.dispatch(addGiftCard(cardNumber));
  };

  private removeCoupon = (cardNumber: string) => {
    this.props.dispatch(removeGiftCard(encodeURI(cardNumber)));
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    dispatch(
      addItemToCart(cartProduct, count, false, false, this.state.couponCode),
    );
  };

  private removeFromCart = (pId: number, gId: number) => {
    const { dispatch } = this.props;
    dispatch(removeItemFromCart(gId, pId, this.state.couponCode));
  };

  private handleGoBack = () => {
    this.props.router.goBack();
  };

  private createOrder = async (): Promise<string> => {
    const { dispatch } = this.props;
    let orderPublicId = '';

    try {
      const res = await API.createOrder(
        this.props.data.id,
        {},
        { ...this.props.data, step: 3 },
      );
      orderPublicId = res.orderPublicId;
    } catch (err) {
      if (
        err &&
        err.details.payload &&
        err.details.payload &&
        err.details.payload.cartProblems
      ) {
        dispatch(setCartError(err.details.payload.cartProblems));
      }
    }

    return orderPublicId;
  };
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    data: cartDataSelector(state),
    isFetching: cartIsFetchingSelector(state),
    error: cartErrorSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    deliveryAddressesIsFetching: deliveryAddressesIsFetchingSelector(state),
    showProblems: cartShowProblemsSelector(state),
    zoneId: cartZoneIdSelector(state),
    delAndPayInfoById: cartDelPayInfoByIdSelector(state),
    isFetchingDelAndPayInfo: cartDelPayInfoIsFetchingSelector(state),
    freeDelivery: cartFreeDelPayInfoSelector(state),
    couponError: couponErrorSelector(state),
    lang: langSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartContainer' })(withRouter(CartContainer)),
);
