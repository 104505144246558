import { em, rem } from 'polished';

const sizesNew = new Map<string, number>([
  ['xs', 568],
  ['sm', 768],
  ['md', 992],
  ['lg', 1200],
]);

export const media = (from: number | string = 0, to: number | string = 0) => {
  let newFrom: number = 0;
  let newTo: number = 0;

  newFrom = assignMediaBreakpoint(from, newFrom);
  newTo = assignMediaBreakpoint(to, newTo);
  if (newFrom !== 0 && newTo !== 0) {
    return `@media (min-width: ${em(newFrom)}) and (max-width: ${em(
      newTo - 1,
    )})`;
  } else if (newFrom !== 0) {
    return `@media (min-width: ${em(newFrom)})`;
  } else if (newTo !== 0) {
    return `@media (max-width: ${em(newTo - 1)})`;
  } else {
    throw new Error('at least one of 2 parameters is required');
  }
};

export const assignMediaBreakpoint = (
  breakpoint: number | string,
  newBreakpoint: number = 0,
) => {
  if (typeof breakpoint === 'string') {
    if (sizesNew.has(breakpoint)) {
      newBreakpoint = sizesNew.get(breakpoint) || 0;
    } else {
      throw new Error("breakpoint: '" + breakpoint + "' is not in sizes");
    }
  } else {
    newBreakpoint = breakpoint;
  }

  return newBreakpoint;
};

export const size = (width: number = 0, height: number = 0) => {
  return `width: ${rem(width)};
  height: ${rem(height !== 0 ? height : width)}`;
};
