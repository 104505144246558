import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import {
  DsiSvgIcon as SvgIcon,
  CmsSearchFormInput as SearchFormInput,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';

interface Props {
  searchTerm: string;
  handleSearchTermChange: any;
}

export function CmsSearch({ searchTerm, handleSearchTermChange }: Props) {
  return (
    <InputWrapper>
      <StyledSearchIcon icon={IconType.search} alt={__('Hľadať výraz')} />
      <SearchFormInput
        name="place"
        type="text"
        placeholder={__('Zadajte hľadaný výraz…')}
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  max-width: ${rem(704)};
  width: ${rem(320)};
  position: relative;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.s450`
    width: 100%;
  `}
`;

const StyledSearchIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.inactiveFontColor};
  position: absolute;
  top: 50%;
  left: ${rem(19)};
  transform: translateY(-50%);
`;
