import * as React from 'react';
import styled from '../../theme/styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import { ProductTitleWrapper, Slider, Title } from './Home';

interface Props {
  brands: any;
  marginLeft: number;
}

function FavoriteBrands(props) {
  const { brands, marginLeft } = props;

  return (
    <ContentWrapper marginLeft={marginLeft}>
      <ProductTitleWrapper>
        <Title>{__('Obľúbené značky')}</Title>
      </ProductTitleWrapper>
      <BrandsWrapper>
        <Slider type="brands" slides={brands} />
      </BrandsWrapper>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => (marginLeft ? rem(marginLeft) : 0)};
  margin-top: ${rem(28)};
  margin-bottom: ${rem(28)};
  ${({ theme }) => theme.mediab.l925`
     margin-left: 0;
  `}
`;

const BrandsWrapper = styled.div`
  width: 100%;
`;

export { FavoriteBrands };
