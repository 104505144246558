import { createSelector } from 'reselect';
import { prop } from '../../utilities';

export const cmsDomainSelector = state => state.cms || {};

export const supportCmsDomainSelector = state =>
  prop(state, 'cms.support') || {};

export const sitemapSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'sitemap', []),
);

export const sitemapByIdSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'byId', {}),
);

export const sitemapIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const allArticlesSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'allArticles', null),
);

export const articlesIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'isFetchingArticles', true),
);

export const supportSelector = createSelector(
  supportCmsDomainSelector,
  substate => prop(substate, 'sitemap', []),
);

export const linksIsFetchingSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'isFetchingLinks', true),
);

export const allLinksSelector = createSelector(cmsDomainSelector, substate =>
  prop(substate, 'links', null),
);

export const currentArticleNameSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'currentArticleName', null),
);

export const searchArticlesIsFetchngSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'searchArticles.isFetching', false),
);

export const searchArticlesSelector = createSelector(
  cmsDomainSelector,
  substate => prop(substate, 'searchArticles.data', []),
);
