const namespace = 'Article';

export const REQUEST_HOME_ARTICLES = `${namespace}/REQUEST_HOME_ARTICLES`;
export const RECEIVE_HOME_ARTICLES_SUCCESS = `${namespace}/RECEIVE_HOME_ARTICLES_SUCCESS`;
export const RECEIVE_HOME_ARTICLES_ERROR = `${namespace}/RECEIVE_HOME_ARTICLES_ERROR`;
export const RECEIVE_MORE_HOME_ARTICLES_SUCCESS = `${namespace}/RECEIVE_MORE_HOME_ARTICLES_SUCCESS`;

export const REQUEST_HOME_MOST_READ = `${namespace}/REQUEST_HOME_MOST_READ`;
export const RECEIVE_HOME_MOST_READ_SUCCESS = `${namespace}/RECEIVE_HOME_MOST_READ_SUCCESS`;
export const RECEIVE_HOME_MOST_READ_ERROR = `${namespace}/RECEIVE_HOME_MOST_READ_ERROR`;

export const REQUEST_HOME_RECOMMENDED = `${namespace}/REQUEST_HOME_RECOMMENDED`;
export const RECEIVE_HOME_RECOMMENDED_SUCCESS = `${namespace}/RECEIVE_HOME_RECOMMENDED_SUCCESS`;
export const RECEIVE_HOME_RECOMMENDED_ERROR = `${namespace}/RECEIVE_HOME_RECOMMENDED_ERROR`;

export const REQUEST_HOME_RECOMMENDED_OPTIONS = `${namespace}/REQUEST_HOME_RECOMMENDED_OPTIONS`;
export const RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS = `${namespace}/RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS`;
export const RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR = `${namespace}/RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR`;

export const REQUEST_HOME_FAST_NEWS = `${namespace}/REQUEST_HOME_FAST_NEWS`;
export const RECEIVE_HOME_FAST_NEWS_SUCCESS = `${namespace}/RECEIVE_HOME_FAST_NEWS_SUCCESS`;
export const RECEIVE_HOME_FAST_NEWS_ERROR = `${namespace}/RECEIVE_HOME_FAST_NEWS_ERROR`;

export const REQUEST_HOME_TOP_ARTICLE = `${namespace}/REQUEST_HOME_TOP_ARTICLE`;
export const RECEIVE_HOME_TOP_ARTICLE_SUCCESS = `${namespace}/RECEIVE_HOME_TOP_ARTICLE_SUCCESS`;
export const RECEIVE_HOME_TOP_ARTICLE_ERROR = `${namespace}/RECEIVE_HOME_TOP_ARTICLE_ERROR`;

export const REQUEST_HOME_EBOOKS = `${namespace}/REQUEST_HOME_EBOOKS`;
export const RECEIVE_HOME_EBOOKS_SUCCESS = `${namespace}/RECEIVE_HOME_EBOOKS_SUCCESS`;
export const RECEIVE_HOME_EBOOKS_ERROR = `${namespace}/RECEIVE_HOME_EBOOKS_ERROR`;

export const SET_HOME_RECOMMENDED_FILTER = `${namespace}/SET_HOME_RECOMMENDED_FILTER`;
export const SET_HOME_MOST_READ_FILTER = `${namespace}/SET_HOME_MOST_READ_FILTER`;

export const RECEIVE_HOME_BANNER_SUCCESS = `${namespace}/RECEIVE_HOME_BANNER_SUCCESS`;

export const RECEIVE_HOME_TOP_BRANDS_SUCCESS = `${namespace}/RECEIVE_HOME_TOP_BRANDS_SUCCESS`;

export const SET_HOME_ARTICLES_FILTERS = `${namespace}/SET_HOME_ARTICLES_FILTERS`;

export const REQUEST_HOME_TOP_TECHNOLOGIES = `${namespace}/REQUEST_HOME_TOP_TECHNOLOGIES`;
export const RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS = `${namespace}/RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS`;
export const RECEIVE_HOME_TOP_TECHNOLOGIES_ERROR = `${namespace}/RECEIVE_HOME_TOP_TECHNOLOGIES_ERROR`;

export const REQUEST_HOME_SHOPPING_CONSULTANTS = `${namespace}/REQUEST_HOME_SHOPPING_CONSULTANTS`;
export const RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS = `${namespace}/RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS`;
export const RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR = `${namespace}/RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR`;

export const REQUEST_HOME_FAVORITE_PRODUCTS = `${namespace}/REQUEST_HOME_FAVORITE_PRODUCTS`;
export const RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS`;
export const RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR = `${namespace}/RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR`;

export const REQUEST_HOME_RECOMMENDED_PRODUCTS = `${namespace}/REQUEST_HOME_RECOMMENDED_PRODUCTS`;
export const RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS`;
export const RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR = `${namespace}/RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR`;

export const REQUEST_HOME_PRODUCT_LIST1 = `${namespace}/REQUEST_HOME_PRODUCT_LIST1`;
export const RECEIVE_HOME_PRODUCT_LIST1_SUCCESS = `${namespace}/RECEIVE_HOME_PRODUCT_LIST1_SUCCESS`;
export const RECEIVE_HOME_PRODUCT_LIST1_ERROR = `${namespace}/RECEIVE_HOME_PRODUCT_LIST1_ERROR`;

export const REQUEST_HOME_PRODUCT_LIST2 = `${namespace}/REQUEST_HOME_PRODUCT_LIST2`;
export const RECEIVE_HOME_PRODUCT_LIST2_SUCCESS = `${namespace}/RECEIVE_HOME_PRODUCT_LIST2_SUCCESS`;
export const RECEIVE_HOME_PRODUCT_LIST2_ERROR = `${namespace}/RECEIVE_HOME_PRODUCT_LIST2_ERROR`;

export const REQUEST_HOME_CATEGORIES = `${namespace}/REQUEST_HOME_CATEGORIES`;
export const RECEIVE_HOME_CATEGORIES_SUCCESS = `${namespace}/RECEIVE_HOME_CATEGORIES_SUCCESS`;
export const RECEIVE_HOME_CATEGORIES_ERROR = `${namespace}/RECEIVE_HOME_CATEGORIES_ERROR`;

export const REQUEST_REVIEWS = `${namespace}/REQUEST_REVIEWS`;
export const RECEIVE_REVIEWS_SUCCESS = `${namespace}/RECEIVE_REVIEWS_SUCCESS`;
export const RECEIVE_REVIEWS_ERROR = `${namespace}/RECEIVE_REVIEWS_ERROR`;

export const REQUEST_NOT_FOUND_ARTICLES = `${namespace}/REQUEST_NOT_FOUND_ARTICLES`;
export const RECEIVE_NOT_FOUND_ARTICLES_SUCCESS = `${namespace}/RECEIVE_NOT_FOUND_ARTICLES_SUCCESS`;
export const RECEIVE_NOT_FOUND_ARTICLES_ERROR = `${namespace}/RECEIVE_NOT_FOUND_ARTICLES_ERROR`;

export const REQUEST_BLOG_ARTICLES = `${namespace}/REQUEST_BLOG_ARTICLES`;
export const RECEIVE_BLOG_ARTICLES_SUCCESS = `${namespace}/RECEIVE_RBLOG_ARTICLES_SUCCESS`;
export const RECEIVE_BLOG_ARTICLES_ERROR = `${namespace}/RECEIVE_BLOG_ARTICLES_ERROR`;
