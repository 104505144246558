import React from 'react';
import {
  FlexCol,
  TextP,
  DsiSvgIcon as SvgIcon,
  DsiButtonPrimary as Button,
} from 'eshop-defaults';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __r } from 'react-i18n/lib';
import API, { ThenArg } from '../../services/API';
import { formatPrice } from '../../utilities';

interface Props {
  numberOfProducts: number;
  totalPrice: number;
  currency: string;
  cartWidth?: number;
  cartData: ThenArg<typeof API.getCart>;
  dispatch: any;
}

function HeaderCart({
  numberOfProducts,
  totalPrice,
  currency,
  cartWidth,
}: Props) {
  return (
    <CartWrapper color={'#FF3300'} padding={'13px'}>
      <StyledLink to={__r('routes:kosik', '/kosik')}>
        <SvgIcon
          icon={IconType.cart}
          alt="Prejsť na košík"
          cursor={'pointer'}
          fill={'#FFFFFF'}
        />
        <NameLinkWrapper>
          <TotalPrice>{formatPrice(totalPrice, currency)}</TotalPrice>
        </NameLinkWrapper>
      </StyledLink>
    </CartWrapper>
  );
}

const CartWrapper = styled(Button)`
  width: initial;
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  min-width: ${rem(151)};

  ${({ theme }) => theme.mediab.l925`
    width: initial;
    max-width: 50px !important;
    height: initial;
    min-width: initial;
    background: transparent;
    padding: 0;

    svg {
      fill: ${({ theme }) => theme.colors.primary}
    }
  `};
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const TotalPrice = styled(TextP)`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(20)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`;

export default HeaderCart;
