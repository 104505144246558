import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { getImagePath } from '../../../utilities/product';

const Image = styled.img`
  /* width: ${rem(280)};
  height: ${rem(280)}; */
  max-width: initial;
  border-radius: ${({ circle }) => (circle ? '50%' : 'initial')};

  ${({ theme }) => theme.mediab.m580`
    width: 100%;
    height: auto;
  `}
`;

export interface Props {
  image: string;
  alt: string;
  width: number;
  height: number;
  noSize?: boolean;
  circle?: boolean;
}

export class Picture extends React.Component<Props> {
  public render() {
    const { image, alt, width, height, noSize, circle } = this.props;

    return (
      <picture>
        <source
          type="image/webp"
          srcSet={
            image !== ''
              ? getImagePath(image, { width, height }, noSize, true)
              : 'https://via.placeholder.com/290x160.png'
          }
        />
        <source
          type="image/jpeg"
          srcSet={
            image !== ''
              ? getImagePath(image, { width, height }, noSize, false)
              : 'https://via.placeholder.com/290x160.png'
          }
        />
        <Image
          loading="lazy"
          circle={circle}
          alt={alt}
          src={
            image !== ''
              ? getImagePath(image, { width, height }, noSize)
              : 'https://via.placeholder.com/290x160.png'
          }
        />
      </picture>
    );
  }
}
