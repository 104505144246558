import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import APIClass, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { langSelector, userSelector } from '../App/selectors';
import { prop, arrayToObject, round } from '../../utilities';
import {
  getProductDefaultInfo,
  getElasticCategoryProductInfo,
} from 'eshop-defaults/lib/utilities/selectors';
import {
  categoryDataSelector,
  categoryProductsSelector,
} from '../Category/selectors';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth/lib/containers/Auth/constants';
import { resolveDomainByHostname } from '../../configureTrans';
import { hostnameSelector, currencySelector } from '../App/selectors';
import { heurekaPurchase } from '@bart.sk-ecommerce/react-online-marketing';
import { mapCategoriesTreeByIdRecursive } from '../Header/reducer';
import { __ } from 'react-i18n';

interface CartState {
  webContents: any;
  giftCardInfo: {
    giftCard: any;
    products: any;
  } | null;
  contentsCart: any;
  showCartProblems: boolean;
  orderInfo: any;
  isFetching: boolean;
  wrongCode: boolean;
}

const initialState: CartState = {
  webContents: null,
  giftCardInfo: null,
  contentsCart: null,
  showCartProblems: false,
  orderInfo: null,
  isFetching: false,
  wrongCode: false,
};

const giftCardContentsSlice = createSlice({
  name: 'giftCardContents',
  initialState,
  reducers: {
    setWebContents(
      state,
      action: PayloadAction<{ webContents: CartState['webContents'] }>,
    ) {
      state.webContents = action.payload.webContents;
    },
    setGiftCardInfo(
      state,
      action: PayloadAction<{ giftCardInfo: CartState['giftCardInfo'] }>,
    ) {
      state.giftCardInfo = action.payload.giftCardInfo;
    },
    setContentsCart(
      state,
      action: PayloadAction<{ contentsCart: CartState['contentsCart'] }>,
    ) {
      state.contentsCart = action.payload.contentsCart;
    },
    setCartProblems(state: any, action: PayloadAction<{ problems: any[] }>) {
      state.contentsCart = {
        ...state.contentsCart,
        problems: action.payload.problems,
      };
      state.showCartProblems = true;
    },
    setOrderInfo(
      state: any,
      action: PayloadAction<{ orderInfo: CartState['orderInfo'] }>,
    ) {
      state.orderInfo = action.payload.orderInfo;
    },
    setIsFetching(
      state: any,
      action: PayloadAction<{ isFetching: CartState['isFetching'] }>,
    ) {
      state.isFetching = action.payload.isFetching;
    },
    setWrongCode(
      state: any,
      action: PayloadAction<{ wrongCode: CartState['wrongCode'] }>,
    ) {
      state.wrongCode = action.payload.wrongCode;
    },
  },
});

const {
  setWebContents,
  setGiftCardInfo,
  setContentsCart,
  setCartProblems,
  setOrderInfo,
  setIsFetching,
  setWrongCode,
} = giftCardContentsSlice.actions;
export default giftCardContentsSlice.reducer;

export const fetchWebContents = (): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const lang = langSelector(getState());
  const hostname = hostnameSelector(getState());

  const otherText = await API.loadOtherTexts('KETCHUP_CONTENTS', {
    langId: lang,
    domainId: +resolveDomainByHostname(hostname),
    fallbackDomainId: 0,
  });
  dispatch(setWebContents({ webContents: [otherText.content] }));
};

export const checkContestCode = (contestCode): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    //const lang = 'sk'; //langSelector(getState());
    //const currency = 'EUR'; //currencySelector(getState());

    const lang = langSelector(getState());
    const currency = currencySelector(getState());
    const hostname = hostnameSelector(getState());

    dispatch(setIsFetching({ isFetching: true }));
    const giftCardsResponse = await API.loadEshopGiftCards({
      cardNr: contestCode,
      withSales: 1,
    });
    if (giftCardsResponse.giftcards && giftCardsResponse.giftcards.length) {
      const giftCard = giftCardsResponse.giftcards[0];
      const productIds = giftCard.sales?.map(
        (sale: any) => sale.sale_product_id,
      );
      if (productIds && productIds.length) {
        let products = await Promise.all(
          productIds.map(async productId => {
            const product = await API.loadProduct(
              productId,
              {
                published: '0',
              },
              { xAcceptLanguage: lang, xCurrency: currency },
            );
            return product;
          }),
        );

        const domainId = resolveDomainByHostname(hostname);
        const { id } = await API.createCart(
          { domainId },
          {
            xAcceptLanguage: lang,
            xCurrency: currency,
          },
        );
        //const contentsCart = await API.getCart(id, { vatGroups: 1 });
        const cart = await API.addCartItem(
          id,
          products[0].main_good.good_id,
          products[0].product_id,
          {
            count: 1,
            currencyId: currency ? currency : 'EUR',
          },
          {},
          { xAcceptLanguage: lang },
        );
        const contentsCart = await API.giftCardPrepareCart(
          giftCard.card_number,
          id,
        );

        dispatch(setContentsCart({ contentsCart }));
        dispatch(
          setGiftCardInfo({
            giftCardInfo: {
              giftCard: giftCard,
              products: products,
            },
          }),
        );
        dispatch(setWrongCode({ wrongCode: false }));
      }
    } else {
      dispatch(setWrongCode({ wrongCode: true }));
    }
  } catch (e) {
    dispatch(setWrongCode({ wrongCode: true }));
  } finally {
    dispatch(setIsFetching({ isFetching: false }));
  }
};

export const updateContentsCart = (data): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(setIsFetching({ isFetching: true }));
    const hostname = hostnameSelector(getState());
    const contentsCart = giftContestCartSelector(getState());
    const updatedCart = await API.updateCart(
      contentsCart.id,
      { vatGroups: 1 },
      { ...data, domain_id: resolveDomainByHostname(hostname) },
    );
    dispatch(setContentsCart({ contentsCart: updatedCart }));
  } catch (e) {
  } finally {
    dispatch(setIsFetching({ isFetching: false }));
  }
};

export const finishContents = (contentsCart): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  dispatch(setIsFetching({ isFetching: true }));
  try {
    const response = await API.giftCardCreateOrder(
      contentsCart.id,
      {},
      contentsCart,
    );
    const orderInfo = {
      orderPublicId: response.orderPublicId,
    };
    dispatch(setOrderInfo({ orderInfo }));
  } catch (e) {
    const err: any = e;
    if (err?.details?.payload?.cartProblems) {
      dispatch(setCartProblems({ problems: err.details.payload.cartProblems }));
    } else if (prop(err, 'details.name')) {
      dispatch(
        setCartProblems({
          problems: [{ readable: prop(err, 'details.name') }],
        }),
      );
    }
  } finally {
    dispatch(setIsFetching({ isFetching: false }));
  }
};

const giftCardContestSelector = (state: RootState) => state.giftCardContest;

export const giftContestCartSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'contentsCart', null),
);

export const giftContestWebContentsSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'webContents', null),
);

export const giftContestGiftCardInfoSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'giftCardInfo', null),
);

export const giftContestShowCartProblemsSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'showCartProblems', null),
);

export const giftContestOrderInfoSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'orderInfo', null),
);

export const giftIsFetchingSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'isFetching', null),
);

export const giftWrongCodeSelector = createSelector(
  giftCardContestSelector,
  substate => prop(substate, 'wrongCode', null),
);
