import {
  IrequestCategoriesTree,
  IreceiveCategoriesTreeError,
  IreceiveCategoriesTreeSuccess,
  CategoriesTree,
  IrequestSubcategoriesTree,
  IreceiveSubcategoriesTreeSuccess,
  SubcategoriesTree,
  IrereceiveSubcategoriesTreeError,
} from './types';
import { hostnameSelector, langSelector } from '../App/selectors';
import {
  DEFAULT_CATEGORY_ID,
  resolveDomainByHostname,
} from '../../configureTrans';
import { filterEmptyCategories } from '../../utilities';
import { defaultSortOption } from '../../components/Category/Category';

export const namespace = 'Header';

const requestCategoriesTree = (): IrequestCategoriesTree => ({
  type: 'Header/REQUEST_CATEGORIES',
});

const receiveCategoriesTreeSuccess = (
  categories: CategoriesTree,
): IreceiveCategoriesTreeSuccess => ({
  type: 'Header/RECEIVE_CATEGORIES_SUCCESS',
  payload: {
    categories,
  },
});

const receiveCategoriesTreeError = (error): IreceiveCategoriesTreeError => ({
  type: 'Header/RECEIVE_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCategoriesTree = () => {
  return async (dispatch, getState, API) => {
    try {
      if (!getState().header.categories.productCategories) {
        dispatch(requestCategoriesTree());
        const lang = langSelector(getState());
        const hostname = hostnameSelector(getState());
        const categories = await API.loadSubTree(
          DEFAULT_CATEGORY_ID[hostname][lang],
          {
            withProductCounts: '1',
            withPublish: '1',
            onlyVisible: '1',
            withContent: '1',
          },
          { xAcceptLanguage: lang },
        );

        let stack = [...categories];

        /*
        const redirectedCategoryIds: number[] = [];

        while (stack.length > 0) {
          const obj = stack.pop();
          if (obj.redirect_category_id) {
            redirectedCategoryIds.push(obj.redirect_category_id);
          }

          if (obj.children.length > 0) {
            stack.push(...obj.children);
          }
        }

        const redirectCategoryPromises = redirectedCategoryIds.map(
          async categoryId => {
            return API.loadElasticCategory(categoryId, {
              sort: defaultSortOption,
              langId: lang,
              onlySubcategories: '1',
              domainId: resolveDomainByHostname(hostname),
            });
          },
        );

        const redirectCategoryResults = await Promise.all(
          redirectCategoryPromises,
        );
        stack = [...categories];
        while (stack.length > 0) {
          const obj = stack.pop();
          const redirectCategoryItem: any = redirectCategoryResults.find(
            (pr: any) =>
              pr.categoryInfo.category_id === obj.redirect_category_id,
          );
          if (redirectCategoryItem) {
            obj.isRedirected = true;
            obj.redirectCounts = redirectCategoryItem.products.total;
            obj.redirectChildren = redirectCategoryItem.subTree;
          }

          if (obj.children.length > 0) {
            stack.push(...obj.children);
          }
        }
        */

        // temporarily filter categories with redirect in CZ
        if (lang === 'cz') {
          dispatch(
            receiveCategoriesTreeSuccess(filterEmptyCategories(categories)),
          );
        } else {
          dispatch(
            receiveCategoriesTreeSuccess(filterEmptyCategories(categories)),
          );
        }

        // load homepage products
        if (categories && categories[0]) {
          const id = categories[0].category_id;
          return id;
        }
      }
    } catch (e) {
      dispatch(receiveCategoriesTreeError(e));
    }
  };
};

const requestSubcategoriesTree = (
  parentCategoryId: number,
): IrequestSubcategoriesTree => ({
  type: 'Header/REQUEST_SUBCATEGORIES',
  payload: {
    parentCategoryId,
  },
});

const receiveSubcatergoriesTreeSuccess = (
  subcategoriesTree: SubcategoriesTree,
  parentCategoryId: number,
): IreceiveSubcategoriesTreeSuccess => ({
  type: 'Header/RECEIVE_SUBCATEGORIES_SUCCESS',
  payload: {
    subcatgories: subcategoriesTree,
    parentCategoryId,
  },
});

const receiveSubcatergoriesTreeError = (
  error: any,
): IrereceiveSubcategoriesTreeError => ({
  type: 'Header/RECEIVE_SUBCATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadSubcategoriesTree = (categoryId: number) => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      dispatch(requestSubcategoriesTree(categoryId));
      const subcategoriesTree: SubcategoriesTree = await API.loadSubTree(
        categoryId,
        {},
        { xAcceptLanguage: lang },
      );
      dispatch(receiveSubcatergoriesTreeSuccess(subcategoriesTree, categoryId));
    } catch (e) {
      dispatch(receiveSubcatergoriesTreeError(e));
    }
  };
};

export const changeDimmerVisibility = (newValue: boolean) => ({
  type: 'Header/CHANGE_TOGGLE_VISIBLE',
  payload: {
    newValue,
  },
});

// const requestSearchTerm = (searchTerm: string): IrequestSearchTerm => ({
//   type: 'Header/REQUEST_SEARCH_TERM',
//   payload: {
//     searchTerm,
//   },
// });

// const receiveSearchTermResultsSuccess = (
//   searchTerm,
//   articles,
// ): IreceiveSearchTermResultSuccess => ({
//   type: 'Header/RECEIVE_SEARCH_TERM_SUCCESS',
//   payload: {
//     articles,
//     searchTerm,
//   },
// });

// export const loadSearchTermResults = (
//   searchTerm: string,
//   limit: number = 5,
//   offset: number = 0,
//   sort: string = '',
//   sortDir: string = '',
// ) => {
//   return async (dispatch, getState, API) => {
//     try {
//       dispatch(requestSearchTerm(searchTerm));
//       // const lang: string = langSelector(getState());
//       // const currency: string = currencySelector(getState());
//       const articlesResult = await API.loadArticles({
//         sitemapId: 135,
//       });

//       dispatch(receiveSearchTermResultsSuccess(searchTerm, articlesResult));
//     } catch (e) {
//       console.error(e);
//     }
//   };
// };
