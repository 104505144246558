// import * as React from 'react';
import styled from 'styled-components';
// import { rem } from 'polished'

// Styled Components

/*****************/

export const Modal = styled.div`
  width: fit-content;
  height: fit-content;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  margin: auto;
`;
