import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ZemplinLoaderWrapper,
  DsiOrderDetail as OrderDetail,
} from 'eshop-defaults';
import {
  fetchCustomerDetails,
  fetchCustomerFinancialDetails,
  fetchOrderDetail,
  requestDetailDataSelector,
  requestDetailIsFetchingSelector,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
// import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { StyledTitle } from '../../components/MyAccount/MyDashboard';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

class MyOrderDetail extends React.Component<Props> {
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDER_DETAIL));
      await Promise.all([
        await dispatch(fetchOrderDetail(orderPublicId)),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public render() {
    const { data, isFetching, user } = this.props;
    if (isFetching) {
      return <ZemplinLoaderWrapper />;
    }

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Detail objednávky') }} />
        <StyledTitle marginTop={0}>{__('Detail objednávky')}</StyledTitle>
        <OrderDetail user={user} data={data} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    data: requestDetailDataSelector(state),
    isFetching: requestDetailIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyOrderDetail' })(MyOrderDetail),
);
