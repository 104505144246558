import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { css } from '../../theme/styled-components';
import { FlexRowCenter, FlexRowCenterVertical, Icon } from 'eshop-defaults';

interface Props {
  links: Array<{ name: string; link: string; redirectLink?: string }>;
  location: any;
}

export const Navigation = ({ links, location }: Props): JSX.Element => {
  const renderLinks = () => {
    if (!location.pathname || links.length === 0) return null;
    return links.map(link => {
      const isActive = location.pathname.includes(link.link);
      return (
        <NavigationItem key={link.name}>
          {link.redirectLink ? (
            <StyledA
              target="_blank"
              href={link.redirectLink}
              isActive={isActive}
            >
              {link.name}
              <OustideLinkIcon>
                <Icon src="/images/corporate/external-link.svg" />
              </OustideLinkIcon>
            </StyledA>
          ) : (
            <StyledLink to={link.link} isActive={isActive}>
              {link.name}
            </StyledLink>
          )}
        </NavigationItem>
      );
    });
  };

  return (
    <Wrapper>
      <NavigationList>{renderLinks()}</NavigationList>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
`;

const NavigationItem = styled.li`
  list-style: none;
  height: ${rem(48)};
  margin-bottom: ${rem(2)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const linkCss = css`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : theme.colors.textPrimary};
  text-decoration: none;
  padding: ${rem(14)} ${rem(16)} ${rem(15)};
  background: ${({ theme, isActive }) =>
    isActive
      ? rgba(theme.colors.textPrimary, 1)
      : rgba(theme.colors.textPrimary, 0.04)};
  display: block;
  border-radius: ${rem(4)};

  &:hover,
  &:focus {
    background: ${({ theme }) => rgba(theme.colors.textPrimary, 1)};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledLink = styled(Link)<{ isActive: boolean }>`
  ${linkCss}
`;

const StyledA = styled.a`
  ${linkCss};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OustideLinkIcon = styled(FlexRowCenter)`
  width: ${rem(16)};
  height: ${rem(16)};
  & > img {
    fill: white;
  }
`;
