import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiPurchase as Purchase,
  PaginationSimple,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { StyledTitle } from './MyDashboard';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  user: any;
}

function MyPurchase({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  user,
}: Props) {
  const { limit, offset, total } = ordersData;
  const handleAddToCart = () => {};

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {__('Moje nákupy')}
      </StyledTitle>
      {options && options.length > 1 && (
        <Select
          value={currentId}
          options={options}
          onChange={handleSelectChange}
        />
      )}
      <Purchase
        user={user}
        isFetching={isFetchingOrders}
        orderedProducts={orders}
        handleAddToCart={handleAddToCart}
        isB2B={false}
      />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyPurchase;
