import * as React from 'react';
import styled from '../../theme/styled-components';
import { FlexCol, prop } from 'eshop-defaults';
import { rem } from 'polished';
import { StyledTitle } from './MyDashboard';
import { __ } from 'react-i18n';
import APIClass, { ThenArg } from '../../services/API';
import { DsiSerialNumber } from 'eshop-defaults';
import { PaginationSimple } from 'eshop-defaults';
import API from '../../services/API';

interface Props {
  user: any;
  serialNumbers: ThenArg<
    typeof APIClass.loadCustomerSerialNumbers
  >['serial_numbers'];
  infoContent: ThenArg<typeof APIClass.loadArticleDetail>;
  limit: number;
  offset: number;
  total: number;
  isFetching: boolean;
  addSerialNumberError: string;
  handleOffsetChange: (e) => void;
  handleAddSerialNumber: (serialNumber: string) => void;
  handleApplyBenefit: (
    serialNumberId: number,
    serialNumberBenefitId: number,
  ) => void;
}

function MySerialNumbers({
  user,
  serialNumbers,
  infoContent,
  total,
  isFetching,
  limit,
  offset,
  addSerialNumberError,
  handleOffsetChange,
  handleApplyBenefit,
  handleAddSerialNumber,
}: Props) {
  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Moje sériové čísla')}`}
      </StyledTitle>
      <DsiSerialNumber
        serialNumbers={serialNumbers}
        infoContent={infoContent}
        isFetching={isFetching}
        user={user}
        handleAddSerialNumber={handleAddSerialNumber}
        handleApplyBenefit={handleApplyBenefit}
        addSerialNumberError={addSerialNumberError}
      />
      {total > limit && (
        <PaginationSimple
          totalItems={total}
          limit={limit}
          offset={offset}
          handleOffsetChange={handleOffsetChange}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MySerialNumbers;
