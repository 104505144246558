import API from './services/API';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './rootReducer';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';

let composeEnhancers;

if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

// export const history = createBrowserHistory();
const middleware = routerMiddleware(browserHistory);

const enhancer = (apiClass?: any) =>
  composeEnhancers(
    applyMiddleware(thunkMiddleware.withExtraArgument(apiClass || API)),
    applyMiddleware(middleware),
  );

export default function configureStore(initialState?: object, apiClass?: any) {
  return createStore(rootReducer, initialState!, enhancer(apiClass));
}
