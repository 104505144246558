import API, { ThenArg } from '../../services/API';
import * as React from 'react';
import { useState } from 'react';
import {
  defaultSortOption,
  FilterHeaderItemName,
  FilterHeaderItemWrapper,
  // LeftSideWrapper,
  MobileFilterHeaderItem,
  MobileFilterWrapper,
  MobileHeaderFilterWrapper,
  RightSideWrapper,
  SortSelect,
  SortWrapper,
  StyledOutsideClick,
} from '../Category/Category';
import {
  DsiProductFilter as ProductFilter,
  DsiViewSwitcher as ViewSwitcher,
  DsiProductList as ProductList,
  DsiProductTableList as ProductTableList,
  DsiPagination as Pagination,
  DsiSearchArticlesList as SearchArticlesList,
} from 'eshop-defaults';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddModal } from '../Product/Product';
import { AddTo } from '../Product/AddTo';
import { prop, setProductViewCookie } from '../../utilities';
import { __, __r } from 'react-i18n/lib';
import { FlexRow, ZemplinLoaderWrapper } from 'eshop-defaults/lib';
import { useLocalStorage } from '../../utilities/hooks';
import { addProductToFavorites } from '../../containers/App/actions';
import { rem } from 'polished';
import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { attribsObjectToUrl } from '../../containers/Category/categorySlice';
import styled from 'styled-components';
import { JBL_PRODUCT_VIEW_COOKIE } from '../../containers/App/constants';
import * as cookie from 'react-cookies';
import { push } from 'react-router-redux';

enum FilterType {
  RANGE = 'RANGE',
  OPTIONS = 'CHECKBOX',
  LINKS = 'LINKS',
}

interface Props {
  user: any;
  apiUrl: string;
  query: string;
  dispatch: any;
  isFetching: boolean;
  products: ThenArg<typeof API.searchProducts>;
  addToCart: any;
  router: any;
  searchTerm: string;
  location: any;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  tagId: number;
  domainId: number;
}

function TagsResults({
  user,
  apiUrl,
  query,
  dispatch,
  isFetching,
  products,
  addToCart,
  router,
  searchTerm,
  location,
  articles,
  isFetchingArticles,
  tagId,
  domainId,
}: Props) {
  const sortOptions: Array<{ name: string; value: string }> = [
    {
      value: 'alphabetically-asc',
      name: __('Názov A-Z'),
    },
    {
      value: 'alphabetically-desc',
      name: __('Názov Z-A'),
    },
    {
      value: 'price-asc',
      name: __('Od najlacnejšieho'),
    },
    {
      value: 'price-desc',
      name: __('Od najdrahšieho'),
    },
  ];

  const [currentView, setCurrentView] = useLocalStorage(
    'product-view',
    cookie.load(JBL_PRODUCT_VIEW_COOKIE) || 'card',
  );
  const cookieProductView = cookie.load(JBL_PRODUCT_VIEW_COOKIE);

  React.useEffect(() => {
    if (cookieProductView) {
      setCurrentView(cookieProductView);
    }
  }, [cookieProductView]);

  const isCardView = currentView !== 'list';

  const [addToFavoriteOpen, setAddToFavoriteOpen] = useState(false);
  const [addToCartOpen, setAddToCartOpen] = useState(false);

  const [isActiveCategory, setIsActiveCategory] = useState(true);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });

  const addToRequest = () => {
    //console.log('add to request');
  };

  const addProductToFavorite = id => {
    dispatch(addProductToFavorites(user.id, id));
    setAddToFavoriteOpen(true);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };

  const changeView = () => {
    const newView = currentView === 'card' ? 'list' : 'card';
    setProductViewCookie(newView);
    setCurrentView(newView);
  };

  const closeModals = () => {
    setAddToFavoriteOpen(false);
  };

  const changeAddToFavoriteOpen = () => {
    setAddToFavoriteOpen(!addToFavoriteOpen);
  };

  const handleAddToCart = (product: any, count: number) => {
    const { name, plu, price, currency, picture } = getProductDefaultInfo(
      product,
      undefined,
      undefined,
      true,
    );

    setAddedProduct({
      name: name,
      plu: plu,
      price: price,
      currency: currency,
      picture: picture,
    });
    setAddToCartOpen(true);

    if (count === 0) {
      count = 1;
    }

    addToCart(product, count);
  };

  // const handleFilterChange = (
  //   type: FilterType,
  //   newData:
  //     | { min: number; max: number; attribId?: string }
  //     | {
  //         attribId: string;
  //         valueId: string;
  //         newValue: boolean;
  //         sameIds?: string[];
  //       },
  // ) => {
  //   switch (type) {
  //     case FilterType.RANGE: {
  //       const attribId = prop(newData, 'attribId');
  //       if (attribId) {
  //         const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
  //         const min = parseInt(prop(newData, 'min').toFixed(0));
  //         const max = parseInt(prop(newData, 'max').toFixed(0));
  //         const { attribs } = filterData;
  //         const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
  //         const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

  //         let rangeAttribsObj = currentRangeAttribFilters
  //           ? currentRangeAttribFilters
  //           : {};

  //         if (
  //           Math.floor(attribDataMin) !== min ||
  //           Math.ceil(attribDataMax) !== max
  //         ) {
  //           rangeAttribsObj[attribId] = {
  //             min: parseInt(prop(newData, 'min').toFixed(0)),
  //             max: parseInt(prop(newData, 'max').toFixed(0)),
  //           };
  //         } else {
  //           delete rangeAttribsObj[attribId];
  //         }
  //         router.push(
  //           attribsObjectToUrl(catUrl, {
  //             ...urlAttribsObj,
  //             rangeAttribs: rangeAttribsObj,
  //           }),
  //         );
  //       } else {
  //         urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
  //         urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
  //         router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
  //       }

  //       break;
  //     }

  //     case FilterType.OPTIONS: {
  //       const attribId = prop(newData, 'attribId');
  //       const valueId = prop(newData, 'valueId');
  //       const newValue = prop(newData, 'newValue');
  //       const currentAttribFilters = prop(urlAttribsObj, 'attribs');

  //       let updatedAttribsObj = currentAttribFilters;
  //       let values = updatedAttribsObj
  //         ? prop(currentAttribFilters[attribId], 'values')
  //         : null;
  //       if (values) {
  //         if (newValue) {
  //           values.push(valueId);
  //         } else {
  //           values = values.filter(v => valueId.toString() !== v);
  //         }
  //         if (values.length === 0) {
  //           updatedAttribsObj[attribId].values = null;
  //           delete updatedAttribsObj[attribId];
  //         } else {
  //           updatedAttribsObj[attribId].values = values;
  //         }
  //       } else {
  //         updatedAttribsObj = {
  //           ...updatedAttribsObj,
  //           [attribId]: { values: [valueId] },
  //         };
  //       }

  //       router.push(
  //         attribsObjectToUrl(catUrl, {
  //           ...urlAttribsObj,
  //           attribs: updatedAttribsObj,
  //         }),
  //       );

  //       break;
  //     }

  //     default:
  //       break;
  //   }
  // };

  const renderMobileFilter = () => {
    const resetStateFilter = () => {
      if (isOpenFilter) {
        setIsOpenFilter(!isOpenFilter);
        setIsActiveCategory(!isActiveCategory);
      } else {
        if (isOpenCategory) {
          setIsOpenCategory(!isOpenCategory);
          setIsActiveCategory(!isActiveCategory);
          setIsActiveFilter(!isActiveFilter);
          setIsOpenFilter(!isOpenFilter);
        } else {
          setIsOpenFilter(!isOpenFilter);
          setIsActiveCategory(!isActiveCategory);
        }
      }
    };

    return (
      <>
        <MobileHeaderFilterWrapper>
          <MobileFilterHeaderItem
            isOpen={isOpenFilter}
            isActive={isActiveFilter}
            onClick={resetStateFilter}
          >
            <FilterHeaderItemWrapper>
              <FilterHeaderItemName>Filter</FilterHeaderItemName>
            </FilterHeaderItemWrapper>
          </MobileFilterHeaderItem>
        </MobileHeaderFilterWrapper>
        {/* <MobileFilterWrapper isOpen={isOpenFilter}>
          <ProductFilter isMobile={true} />
        </MobileFilterWrapper> */}
      </>
    );
  };

  const renderAddToFavoritesModal = () => {
    return (
      addToFavoriteOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'favorite'}
                addToFavoriteOpen={changeAddToFavoriteOpen}
                productName={'product_name'}
                picture={''}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  // const renderLeftSide = () => {
  //   return (
  //     <LeftSideWrapper>
  //       <ProductFilter isMobile={false} />
  //     </LeftSideWrapper>
  //   );
  // };

  const renderRightSide = () => {
    const offset = prop(products, 'offset', 0);
    const limit = prop(products, 'limit', 0);
    const total = prop(products, 'total', 0);

    const onSelectChange = e => {
      const { pathname } = location;
      const value = e.target.value;
      const [sort, sortDir] = value.split('-');
      router.push({
        pathname,
        query: { sort, sortDir, q: searchTerm },
      });
    };

    const currentSortValue = prop(location, 'query.sort', '');
    const currentSortDirValue = prop(location, 'query.sortDir', '');

    const localSortOptions: Array<{ name: string; value: string }> = [
      {
        value: 'alphabetically-asc',
        name: __('Názov A-Z'),
      },
      {
        value: 'alphabetically-desc',
        name: __('Názov Z-A'),
      },
      {
        value: 'price-asc',
        name: __('Od najlacnejšieho'),
      },
      {
        value: 'price-desc',
        name: __('Od najdrahšieho'),
      },
    ];

    return (
      <RightSideWrapper>
        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !products /* || !products.products*/ ? null : (
          <>
            {renderArticles()}
            {renderNumberOfProducts()}
            <SortWrapper>
              <SortSelect
                name="sort"
                id="sort"
                options={localSortOptions}
                // placeholder={__('Najpredávanejšie')}
                onChange={onSelectChange}
                value={
                  currentSortValue
                    ? `${currentSortValue}-${currentSortDirValue}`
                    : defaultSortOption
                }
              />
              <ViewSwitcher changeView={changeView} currentView={currentView} />
            </SortWrapper>
            {renderProductsCardView(isCardView)}
            {renderProductsListView(isCardView)}
            <Pagination
              query={query}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightSideWrapper>
    );
  };

  const renderArticles = () => {
    const moreArticlesUrl = `${__r('routes:clanky', '/clanky')}?tag=${tagId}`;
    return (
      <SearchArticlesList
        articles={prop(articles, 'articles', [])}
        isFetching={isFetchingArticles}
        moreArticlesUrl={moreArticlesUrl}
        numOfArticles={prop(articles, 'total', 0)}
      />
    );
  };

  const renderNumberOfProducts = () => {
    const total = prop(products, 'total');
    let foundText = __('Našlo sa');
    let resultsText = __('produktov');
    if (products) {
      foundText =
        total === 1
          ? __('Našiel sa')
          : total < 5 && total > 0
          ? __('Našli sa')
          : __('Našlo sa');
      resultsText =
        products.total === 1
          ? __('produkt')
          : total < 5 && total > 0
          ? __('produkty')
          : __('produktov');
    }

    return (
      <ResultsTotal>
        {__(foundText)} <b>{products && total} </b>
        {__(resultsText)}
      </ResultsTotal>
    );
  };

  const renderProductsCardView = (isCardView: boolean) => {
    return (
      <ProductList
        apiUrl={apiUrl}
        isFetching={isFetching}
        products={products.products}
        addToCart={handleAddToCart}
        addToRequest={addToRequest}
        visible={isCardView}
        addToFavorite={addProductToFavorite}
        hasFavourite={false}
        showWithVat={true}
        showMoc={true}
        canOrder={true}
        isCorporate={true}
        domainId={domainId}
      />
    );
  };

  const renderProductsListView = (isCardView: boolean) => {
    return (
      <ProductTableList
        apiUrl={apiUrl}
        isFetching={isFetching}
        products={products.products}
        addToCart={handleAddToCart}
        addToRequest={addToRequest}
        visible={!isCardView}
        showSumOfVariants={true}
        hasFavourite={false}
        hasWatchdog={false}
        showWithVat={true}
        showMoc={true}
        canOrder={true}
        isCorporate={true}
        domainId={domainId}
      />
    );
  };

  const renderAddToCartModal = () => {
    return (
      addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={addedProduct.name}
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  return (
    <>
      {/* {renderAddToCartModal()} */}
      {renderAddToFavoritesModal()}
      <Wrapper className="container container--wide">
        {/* {renderLeftSide()} */}
        {renderRightSide()}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-bottom: ${rem(80)} !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(32)} !important;
  `}
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m810`
      margin-bottom: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

export { TagsResults };
