import * as React from 'react';
import { __, __r } from 'react-i18n';
import { withTheme } from 'styled-components';
import { rem, rgba } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  FlexRow,
  // IconImg,
  // NoItemsWrapper,
  TextP,
  // DsiFavoriteCategory as FavoriteCategory,
  DsiSvgIcon,
  Action,
  IconImg,
  DsiRegistrationModal as RegistrationModal,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import SliderComponent from '../_helpers/Slider/Slider';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { getImagePath } from '../../utilities/product';
// import { FavoriteBrands } from './FavoriteBrands';
// import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { useState } from 'react';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddModal } from '../Product/Product';
import { AddTo } from '../Product/AddTo';
import { StyledOutsideClick } from '../Category/Category';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import styled from 'styled-components';
import { Banner } from './Banner';
import { prop } from '../../utilities';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import {
  extractBaseDomainUppercase,
  resolveBaseUrlByLang,
  resolveBrandByHostname,
} from '../../configureTrans';
import { SmallBanner } from './SmallBanner';
import { push } from 'react-router-redux';
import { Loader } from '../_helpers/Loader/Loader';
import { ResponsiveImage } from '../_helpers/Image/ResponsiveImage';

interface Props {
  dispatch: any;
  phone?: string;
  theme: any;
  categories: ThenArg<typeof API.loadCategories>;
  productCategoriesIsFetching: boolean;
  addToCart: any;
  addToRequest: any;
  productList1: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  productList2: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  adBanner: any;
  brands: any;
  reviews: any;
  themes: any;
  socialUrls: {
    [key: string]: string;
  };
  lang: string;
  hostname: string;
  blogArticles: any;
  favoriteProducts: any;
  recommendedProducts: any;
  shoppingConsultants: any;
  topTechnologies: any;
  mainSlideInterval: number;
  title: string;
}

function Home(props: Props & WithRouterProps) {
  const [addToCartOpen, setAddToCartOpen] = useState(false);
  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });

  const closeModals = () => {
    setAddToCartOpen(false);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };

  const renderAppropriateBanners = (device: string) => {
    if (
      !prop(props, 'adBanner', []) ||
      !prop(props, 'adBanner', []).filter(
        b => b.device && b.device.includes(device),
      ).length
    ) {
      return null;
    }

    const bannerImages = prop(props, 'adBanner', []).filter(
      b => b.b_type === 'IMAGE',
    );
    if (!bannerImages || !bannerImages.length) {
      return null;
    }

    return (
      <BannerWrapper>
        <BannerContainer>
          <BannerSliderWrapper>
            <Slider
              slides={bannerImages.filter(
                b => b.device && b.device.includes(device),
              )}
              type={'banner'}
              isPlaying={true}
              interval={props.mainSlideInterval}
            />
          </BannerSliderWrapper>
        </BannerContainer>
      </BannerWrapper>
    );
  };

  const renderBannerSlider = () => {
    return (
      <>
        <BannerDesktopWrapper>
          {renderAppropriateBanners('desktop')}
        </BannerDesktopWrapper>
        <BannerTabletWrapper>
          {renderAppropriateBanners('tablet')}
        </BannerTabletWrapper>
        <BannerMobileWrapper>
          {renderAppropriateBanners('phone')}
        </BannerMobileWrapper>
      </>
    );
  };

  const renderFavourites = () => {
    if (
      props &&
      props.favoriteProducts &&
      props.favoriteProducts.products &&
      props.favoriteProducts.products.length
    ) {
      return (
        <FavouritesWrapper>
          <SectionTitle> {__('Najobľúbenejšie produkty')} </SectionTitle>
          <SwipperWrapper>
            <Slider
              slides={props.favoriteProducts.products}
              type={'category-product'}
              addToCart={product => {
                props.dispatch(push(product.product_publish_url));
              }}
              isPlaying={true}
            />
          </SwipperWrapper>
        </FavouritesWrapper>
      );
    } else {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
  };

  const renderRecommended = () => {
    if (
      props &&
      props.recommendedProducts &&
      props.recommendedProducts.products &&
      props.recommendedProducts.products.length
    ) {
      return (
        <FavouritesWrapper>
          <SectionTitle>
            {' '}
            {__('Odborníci z')} {resolveBrandByHostname(hostname)}{' '}
            {__('doporučujú')}
          </SectionTitle>
          <SwipperWrapper>
            <Slider
              slides={props.recommendedProducts.products}
              type={'category-product'}
              addToCart={product => {
                props.dispatch(push(product.url));
              }}
              isPlaying={true}
            />
          </SwipperWrapper>
        </FavouritesWrapper>
      );
    } else {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
  };

  const renderConsultantsAndTopTechnologies = () => {
    return (
      <ConsultantsAndTopTechnologiesWrapper>
        {prop(props, 'shoppingConsultants.articles.length') > 0 && (
          <ConsultantsWrapper>
            <ConsultantsAndTopTechnologiesBoxLeft>
              <ConsultantsAndTopTechnologiesTitle>
                {__('Nákupný radca')}
              </ConsultantsAndTopTechnologiesTitle>
              <ConsultantsAndTopTechnologiesContent>
                {props.shoppingConsultants &&
                props.shoppingConsultants.articles &&
                props.shoppingConsultants.articles.length ? (
                  <ShoppingConsultantsList>
                    {props.shoppingConsultants.articles.map(article => {
                      return (
                        <StyledLink to={`/${article.url}`}>
                          <CMSContent>
                            <CMSImage
                              src={
                                article.image
                                  ? getImagePath(
                                      article.image,
                                      { width: 100, height: 100 },
                                      false,
                                      false,
                                      true,
                                    )
                                  : '/images/icons/placeholder.svg'
                              }
                              lazy={true}
                            />
                            <CMSName>
                              {article.json_content &&
                              article.json_content.alternative_name
                                ? article.json_content.alternative_name
                                : article.name}
                            </CMSName>
                          </CMSContent>
                        </StyledLink>
                      );
                    })}
                  </ShoppingConsultantsList>
                ) : (
                  <></>
                )}
              </ConsultantsAndTopTechnologiesContent>
            </ConsultantsAndTopTechnologiesBoxLeft>
          </ConsultantsWrapper>
        )}
        <TopTechnologies>
          {prop(props, 'topTechnologies.articles.length') > 0 && (
            <ConsultantsAndTopTechnologiesBoxRight>
              <ConsultantsAndTopTechnologiesTitleRight>
                {__('Naše technológie')}
                <ConsultantsAndTopTechnologiesContent>
                  {props.topTechnologies &&
                  props.topTechnologies.articles &&
                  props.topTechnologies.articles.length ? (
                    <TopTechnologiesItemList>
                      {props.topTechnologies.articles.map(tt => {
                        return (
                          <TopTechnologiesItem href={tt.url}>
                            {' '}
                            {tt.json_content && tt.json_content.alternative_name
                              ? tt.json_content.alternative_name
                              : tt.name}{' '}
                          </TopTechnologiesItem>
                        );
                      })}
                    </TopTechnologiesItemList>
                  ) : (
                    <></>
                  )}
                </ConsultantsAndTopTechnologiesContent>
              </ConsultantsAndTopTechnologiesTitleRight>
            </ConsultantsAndTopTechnologiesBoxRight>
          )}
        </TopTechnologies>
      </ConsultantsAndTopTechnologiesWrapper>
    );
  };

  const renderAdBanners = () => {
    return adBanner ? (
      <AdBannerWrapper>
        <AdBanner>
          {adBanner
            .filter(a => a.device === 'outside-slider')
            .map((banner, i) => renderBanner(banner, true))}
        </AdBanner>
      </AdBannerWrapper>
    ) : null;
  };

  const renderBanner = (
    { b_name, b_description, b_path, b_url, id, b_buttontext },
    isRight: boolean,
  ): JSX.Element | null => {
    return (
      <BannerClassicWrapper key={id}>
        <SmallBanner
          isRight={isRight}
          title={b_name}
          description={b_description}
          image={b_path}
          imagePath={getImagePath(b_path, undefined, true, true, false)}
          redirectUrl={b_url}
          buttonDescription={b_buttontext || __('Zobraziť ponuku')}
        />
      </BannerClassicWrapper>
    );
  };

  const renderAddToCartModal = () => {
    return (
      addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={addedProduct.name}
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  const renderSocialIcons = (socialUrls: {
    [key: string]: string;
  }): JSX.Element => {
    const social: Array<JSX.Element> = [];
    // const facebookIcon = 'images/blog_assets/Facebook48px.svg';
    // const instagramIcon = 'images/blog_assets/Instagram48px.svg';
    // const youtubeIcon = 'images/blog_assets/Youtube48px.svg';
    for (let [objkey, value] of Object.entries(socialUrls)) {
      const elm = (
        <SocialLink
          href={value}
          target="_blank"
          title={__('Link na sociálnu sieť')}
          key={objkey}
        >
          {objkey === 'fbUrl' && (
            <DsiSvgIcon
              icon={IconType.facebook}
              viewBox="12 12 24 24"
              alt="Facebook link"
              cursor="pointer"
            />
          )}
          {objkey === 'instagramUrl' && (
            <DsiSvgIcon
              icon={IconType.instagram}
              viewBox="12 12 24 24"
              alt="Instagram link"
              cursor="pointer"
            />
          )}
          {objkey === 'youtubeUrl' && (
            <DsiSvgIcon
              icon={IconType.youtube}
              viewBox="12 12 24 24"
              alt="Youtube link"
              cursor="pointer"
            />
          )}
        </SocialLink>
      );
      social.push(elm);
    }
    return <SocialUrlsWrapper>{social}</SocialUrlsWrapper>;
  };

  const renderFollowUsItem = (path: string, link: string): JSX.Element => {
    // console.log({
    //   path: getImagePath(path, { width: 500, height: 500 }, false, false, true),
    //   link,
    // });

    return (
      <FollowUsItemBanner>
        <FollowUsImageWrapper>
          <ResponsiveImage
            path={
              path
                ? path.replace('banners/', '')
                : '/images/zemplin/placeholder.svg'
            }
            externalPath={true}
            lazy={true}
          />
        </FollowUsImageWrapper>
        <FollowUsItemLink target="__blank" href={link ? link : '#'}>
          <FollowUsItemLinkIcon>
            <DsiSvgIcon
              icon={IconType.instagram}
              viewBox="12 12 24 24"
              alt="Instagram link"
              cursor="pointer"
            />
          </FollowUsItemLinkIcon>
        </FollowUsItemLink>
      </FollowUsItemBanner>
    );
  };

  const renderFollowUs = (): JSX.Element | null => {
    const { socialUrls } = props;

    const { adBanner } = props;
    if (!adBanner) {
      return null;
    }

    const instagramImages = prop(props, 'adBanner', []).filter(
      b => b.b_type === 'INSTAGRAM',
    );
    if (!instagramImages || !instagramImages.length) {
      return null;
    }

    return (
      <FollowUsWrapper>
        <FollowUsInnerWrapper>
          <FollowUsSocialItem>
            <FollowUsSocialItemInner>
              <FollowUsSocialItemTitle>
                {__('Sledujte nás')}
              </FollowUsSocialItemTitle>
              {socialUrls &&
                Object.keys(socialUrls).length > 0 &&
                renderSocialIcons(socialUrls)}
            </FollowUsSocialItemInner>
          </FollowUsSocialItem>
          {instagramImages.map(ad => {
            return renderFollowUsItem(ad.b_path, ad.b_url);
          })}
        </FollowUsInnerWrapper>
        <MobileInstagramWrapper>
          <Slider
            slides={instagramImages.map(i => ({
              ...i,
              b_path: i.b_path.replace('banners/', ''),
            }))}
            type={'instagram'}
          />
        </MobileInstagramWrapper>
      </FollowUsWrapper>
    );
  };

  // console.log(props.reviews);
  const renderCMSArticles = () => {
    const hasArticles =
      props.blogArticles &&
      props.blogArticles.articles &&
      props.blogArticles.articles.length > 0;

    let articlesPerColumn = 0;
    if (hasArticles) {
      articlesPerColumn = Math.ceil(props.blogArticles.articles.length / 3);
    }

    return (
      <ContentWrapperFull>
        <ProductTitleWrapper>
          <TitleLink to={'/blog'}>
            <Title>{__('Blog')}</Title>
          </TitleLink>
        </ProductTitleWrapper>
        <StyledFlexRow>
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles
                .slice(0, articlesPerColumn)
                .map(article => (
                  <StyledLink to={`/${article.url}`}>
                    <CMSContent>
                      <CMSImage
                        src={
                          article.image
                            ? getImagePath(
                                article.image,
                                { width: 100, height: 100 },
                                false,
                                false,
                                true,
                              )
                            : '/images/icons/placeholder.svg'
                        }
                        lazy={true}
                      />
                      <CMSName>{article.name}</CMSName>
                    </CMSContent>
                  </StyledLink>
                ))}
            </StyledFlexCol>
          )}
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles
                .slice(articlesPerColumn, articlesPerColumn * 2)
                .map(article => (
                  <StyledLink to={`/${article.url}`}>
                    <CMSContent>
                      <CMSImage
                        src={
                          article.image
                            ? getImagePath(
                                article.image,
                                { width: 100, height: 100 },
                                false,
                                false,
                                true,
                              )
                            : '/images/icons/placeholder.svg'
                        }
                      />
                      <CMSName>{article.name}</CMSName>
                    </CMSContent>
                  </StyledLink>
                ))}
            </StyledFlexCol>
          )}
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles
                .slice(articlesPerColumn * 2)
                .map(article => (
                  <StyledLink to={`/${article.url}`}>
                    <CMSContent>
                      <CMSImage
                        src={
                          article.image
                            ? getImagePath(
                                article.image,
                                { width: 100, height: 100 },
                                false,
                                false,
                                true,
                              )
                            : '/images/icons/placeholder.svg'
                        }
                      />
                      <CMSName>{article.name}</CMSName>
                    </CMSContent>
                  </StyledLink>
                ))}
            </StyledFlexCol>
          )}
        </StyledFlexRow>
      </ContentWrapperFull>
    );
  };

  const { adBanner, lang, hostname, title } = props;

  return (
    <HomeWrapper>
      <RegistrationModal />
      <MainWrapper>
        <MetaTags
          tags={{ title: title }}
          concatDefaultTitle={title ? false : true}
          canonicalUrl={resolveBaseUrlByLang(hostname, lang)}
          hrefLangs={[
            { lang_id: 'sk', url: '' },
            { lang_id: 'cz', url: '' },
          ]}
          hostname={hostname}
        />
        <HiddenH1>{`${extractBaseDomainUppercase(hostname)}`}</HiddenH1>
        {renderBannerSlider()}
        <Wrapper className={'container container--wide'}>
          {renderFavourites()}
        </Wrapper>
      </MainWrapper>
      {renderAdBanners()}
      <MainWrapper>
        <Wrapper className={'container container--wide'}>
          {renderRecommended()}
        </Wrapper>
        <Wrapper className={'container container--wide'}>
          {renderConsultantsAndTopTechnologies()}
        </Wrapper>
        {/*
        <Wrapper className={'container container--wide'}>
          {renderFollowUs()}
        </Wrapper>
        */}
        {/* {renderAddToCartModal()} */}
        <Wrapper className={'container container--wide'}>
          {renderCMSArticles()}
        </Wrapper>
      </MainWrapper>
    </HomeWrapper>
  );
}
const HomeWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const MainWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ContentWrapperFull = styled.div`
  margin-top: ${rem(28)};
  margin-bottom: ${rem(28)};

  ${({ theme }) => theme.mediab.l1150`
     margin-left: 0;
  `}
`;

const StyledFlexRow = styled(FlexRow)`
  ${({ theme }) => theme.mediab.m810`
     flex-direction: column;
  `}
`;

const StyledFlexCol = styled(FlexCol)`
  max-width: 33%;
  ${({ theme }) => theme.mediab.m810`
     max-width: 100%;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  margin-top: ${rem(18)};

  ${({ theme }) => theme.mediab.l925`
     margin-top: ${rem(16)};
     text-align: center;
  `}

  svg {
    fill: ${({ theme }) => theme.colors.primary};

    * {
      fill: inherit;
    }
  }
`;

const CMSContentWrapper = styled(Link)`
  text-decoration: none;
`;

const CMSContent = styled(FlexRow)`
  margin: ${rem(0)} ${rem(0)} ${rem(16)} 0;
  padding-right: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
     padding: 0;
     margin-bottom: ${rem(16)}; 
     margin-right: 0;
  `}
`;

const CMSImage = styled(IconImg)<{ src: string }>`
  width: ${rem(80)};
  height: ${rem(80)};
  margin-right: ${rem(16)};
  flex-shrink: 0;
  cursor: pointer;
`;

const CMSName = styled(TextP)`
  width: 100%;
  font-weight: 500;
  margin: auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(14)};
  `}
`;

const BannerWrapper = styled.div`
  width: 100%;

  display: flex;
  /* max-height: ${rem(540)}; */

  /* @media only screen and (max-width: 925px) {
    background-color: transparent;
    padding: 0;
    height: ${rem(400)};
  } */

  margin-bottom: ${rem(40)};
`;

const BannerClassicWrapper = styled.div``;

const BannerClassicWrapperBackup = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${rem(40)};
  max-width: ${({ theme }) => `${theme.grid.container.full}px`};
  /* padding-left: ${rem(16)};
  padding-right: ${rem(16)}; */

  ${({ theme }) => theme.mediab.l925`
   margin-bottom: ${rem(16)};
   padding-left: ${rem(16)};
   padding-right: ${rem(16)};
  `}
`;

const BannerContainer = styled.div`
  width: 100%;
`;

const BannerSliderWrapper = styled.div`
  width: 100%;
`;

export const Slider = styled(SliderComponent)``;

// const ProductWrapper = styled(FlexRowCenterVertical)`
//   border-left: 0;
//   border-radius: 0 ${rem(4)} ${rem(4)} 0;
//   width: 100%;
//   justify-content: center;

//   ${({ theme }) => theme.mediab.l925`
//    display: none;
//   `}
// `;

// const MobileProductWrapper = styled.div`
//   display: none;
//   margin-right: ${rem(16)};

//   ${({ theme }) => theme.mediab.l925`
//    display: block;
//   `}
// `;

export const ProductTitleWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};

  ${({ theme }) => theme.mediab.l1050`
     margin-bottom: ${rem(32)};
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};

  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled(TextP)`
  text-decoration: none;
  font-size: ${rem(36)};
  font-family: ${({ theme }) => theme.font.primary};

  line-height: ${rem(48)};
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;

  ${({ theme }) => theme.mediab.l925`
   font-size: ${rem(24)};
   line-height: ${rem(28)};
  `}
`;

// const ProductCategoryWrapper = styled(FlexRow)`
//   ${({ theme }) => theme.mediab.l1050`
//      flex-flow: column;
//      margin-right: -${rem(16)};
//   `}
// `;

const FollowUsWrapper = styled.div`
  width: 100%;
`;

const FollowUsInnerWrapper = styled.div`
  max-width: ${({ theme }) => `${theme.grid.container.full}px`};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(4)};
  overflow: hidden;
  margin-bottom: ${rem(40)};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: 0;
  `};
`;

const MobileInstagramWrapper = styled.div`
  display: none;
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
      display: block;
  `};
`;

const FollowUsItem = styled.div<{ imgUrl?: string }>`
  position: relative;
  flex: 1 1 25%;
  max-width: ${rem(400)};

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  ${({ theme }) => theme.mediab.l925`
      &::before {
        display: none;
      }
  `};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const FollowUsItemBanner = styled(FollowUsItem)`
  margin-right: ${rem(8)};
  ${({ theme }) => theme.mediab.l925`
      display:none;
  `};
`;

const FollowUsImageWrapper = styled(FollowUsItem)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FollowUsSocialItem = styled(FollowUsItem)`
  margin-right: ${rem(8)};
  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(32)} ${rem(8)};
      padding-bottom: 0;
  `};
`;

const FollowUsItemLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.24);

    > div {
      display: flex;
    }
  }
`;

const FollowUsSocialItemInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FollowUsSocialItemTitle = styled.h3`
  font-weight: 500;
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  margin-top: 0;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin-bottom: ${rem(24)};
    font-size: ${rem(24)};
  `}
`;

const SocialUrlsWrapper = styled(FlexRowCenterVertical)`
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l925`
      order: 1;
      margin-top: 0;
      margin-bottom: ${rem(32)};
  `};
`;

const SocialLink = styled.a`
  display: inline-flex;
  width: ${rem(56)};
  height: ${rem(56)};
  justify-content: center;
  align-items: center;
  border-radius: ${rem(4)};
  border: ${({ theme }) => theme.borders.banners};
  margin-left: ${rem(8)};

  &:first-child {
    margin-left: 0;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};

    * {
      fill: ${({ theme }) => theme.colors.textPrimary};
    }
  }
`;

const FollowUsItemLinkIcon = styled.div`
  width: ${rem(56)};
  height: ${rem(56)};
  background: ${({ theme }) => rgba(theme.colors.white, 0.08)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  display: none;
`;

const BannerMobileWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.m600`
    display: block;
  `}
`;

const BannerTabletWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.l1150`
    display: block;
  `}

  ${({ theme }) => theme.mediab.m600`
    display: none;
  `}
`;

const BannerDesktopWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const HiddenH1 = styled.h1`
  display: none;
`;

const TitleEmptyWithMargin = styled(Title)`
  height: ${rem(38)};
  margin-bottom: ${rem(40)};

  ${({ theme }) => theme.mediab.l1050`
     margin-bottom: ${rem(32)};
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    display: none;
  }
`;

const AdBannerWrapper = styled.div`
  margin: ${rem(20)} 0;
  background-color: ${({ theme }) => theme.colors.primary + '10'};
`;

const AdBanner = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  padding: ${rem(60)} ${rem(24)};
  margin: 0 auto;
  max-width: 90.375rem;

  ${({ theme }) => theme.media(0, 'md')} {
    padding: ${rem(60)} ${rem(16)};
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: ${rem(60)} ${rem(16)};
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FavouritesWrapper = styled.div`
  margin-bottom: 15px;
`;

const SwipperWrapper = styled.div``;

const SectionTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
`;

const ConsultantsAndTopTechnologiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  ${({ theme }) => theme.mediab.m600`
     flex-direction: column;
  `}
`;

const ConsultantsWrapper = styled.div`
  flex: 1;
  margin-right: 15px;

  ${({ theme }) => theme.media(0, 'sm')} {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const TopTechnologies = styled.div``;

const ConsultantsAndTopTechnologiesBoxLeft = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  height: 100%;
`;

const ConsultantsAndTopTechnologiesBoxRight = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  height: 100%;
`;

const ConsultantsAndTopTechnologiesContent = styled.div`
  margin-top: 15px;
`;

const ConsultantsAndTopTechnologiesTitle = styled.h3`
  font-weight: 500;
  font-size: 23px;
  margin: 0;
  margin-bottom: 15px;
`;

const ConsultantsAndTopTechnologiesTitleRight = styled(
  ConsultantsAndTopTechnologiesTitle,
)``;

const TopTechnologiesItemList = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

const ShoppingConsultantsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.media(0, 'sm')} {
    grid-template-columns: 1fr;
  }
`;

const TopTechnologiesItem = styled.a`
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: underline;
  }
`;

const LoaderWrapper = styled.div`
  height: ${rem(487)};
  display: flex;
  align-items: center;
`;

export default withTheme(withRouter(Home));
