import React, { useRef } from 'react';
import {
  GoogleMap,
  Marker,
  useLoadScript,
  MarkerClusterer,
} from '@react-google-maps/api';
import { ZemplinLoaderWrapper } from 'eshop-defaults';
import { MarkerInterface } from './Sellers';
import { useWindowSize } from '../../utilities/hooks';
import { isEqual } from 'lodash';

interface Props {
  markers: Array<MarkerInterface>;
  lang: string;
  handleZoom: any;
}

const containerStyle = {
  width: '100%',
  height: '550px',
};

// center of map based on lang
const center = {
  sk: {
    lat: 48.669,
    lng: 19.699,
  },
  cz: {
    lat: 49.869,
    lng: 15.099,
  },
};

function Map({ markers, lang, handleZoom }: Props) {
  const isCzech = lang === 'cz';
  const [map, setMap] = React.useState(null);

  const size = useWindowSize();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
  });

  if (!isLoaded) return <ZemplinLoaderWrapper />;

  const openInNewTab = location => {
    // const url = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
    // const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${location.name},${location.address},${location.city}&place_id=${location.placeId}`;
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  };

  function handleZoomChanged(map) {
    setMap(map);
  }

  const visibleMarkersLength = markers.filter(m => m.visibility).length;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center[lang]}
      onLoad={map => {
        handleZoomChanged(map);
      }}
      zoom={
        isCzech
          ? size && size.width && (size.width as any) < 700
            ? size && size.width && (size.width as any) < 550
              ? size && size.width && (size.width as any) < 400
                ? 6.0
                : 6.7
              : 7
            : 7.4
          : size && size.width && (size.width as any) < 700
          ? size && size.width && (size.width as any) < 550
            ? size && size.width && (size.width as any) < 400
              ? 6.0
              : 6.7
            : 7.1
          : 8
      }
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
      onBoundsChanged={() => {
        handleZoom(map);
      }}
    >
      <MarkerClusterer
        key={visibleMarkersLength}
        onClick={handleZoom}
        options={{
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        }}
      >
        {clusterer => {
          return markers.map((marker, index) => {
            if (marker.visibility === false) return;
            return (
              <Marker
                clusterer={clusterer}
                onClick={() => openInNewTab(marker)}
                key={`${marker.id}`}
                position={{
                  lat: +marker.location.lat,
                  lng: +marker.location.lng,
                }}
                animation={undefined}
                noClustererRedraw={true}
              />
            );
          }) as any;
        }}
      </MarkerClusterer>
    </GoogleMap>
  );
}

export default React.memo(Map);
