import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  DsiHeaderLogo as HeaderSmallLogo,
  DsiSearchForm as SearchForm,
  OutsideClick,
  DsiMobileNavMenu as MobileNavMenu,
  DsiSvgIcon as SvgIcon,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
// import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { HeaderLangButton } from './HeaderLangButton';
import { __ } from 'react-i18n';
import * as cookies from 'react-cookies';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  clearSearchInput: () => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  partialSearchArticles: any;
  partialSearchArticlesIsFetching: boolean;
  headerArticles: any;
  byId: any;
  logoSrc: string;
  cartData: ThenArg<typeof API.getCart>;
  domainId: string;
  user: any;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  /* border-bottom: ${({ theme }) => theme.borders.primary}; */
  box-shadow: 0 ${rem(1)} ${rem(8)} #00000014;
  margin: 0 auto;
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(16)};
  height: ${rem(72)};
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

const SubHeaderWrapper = styled(FlexRowCenterVertical)<{
  isInnerSearchMenuVisible: boolean;
}>`
  width: 100%;
  padding: ${rem(16)};
  justify-content: flex-end;
  border-top: ${({ theme }) => theme.borders.primary};

  ${props => (props.isInnerSearchMenuVisible ? `padding: 0;` : '')}
`;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  clearSearchInput,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  lang,
  partialSearchArticles,
  partialSearchArticlesIsFetching,
  headerArticles,
  dispatch,
  byId,
  logoSrc,
  cartData,
  domainId,
  user,
}: Props) {
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const searchWrapper = React.createRef<HTMLDivElement>();

  const showSearch = () => {
    setIsSearchVisible(true);
    setIsMenuVisible(false);
    // document.body.style.overflow = 'visible';
  };

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(menuVisible => !menuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setTimeout(() => {
      setIsMenuVisible(false);
    }, 10);
  };

  React.useEffect(() => {
    if (!resultsDropdownVisible) {
      setIsSearchVisible(false);
    }
  }, [resultsDropdownVisible]);

  React.useEffect(() => {
    const node = searchWrapper.current;
    if (isSearchVisible && node) {
      const input = node?.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, [isSearchVisible, searchWrapper]);

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={hideSearchResultsViewer}>
        <MobileMenuWrapper>
          <LeftWrapper>
            <HeaderSmallLogo
              isSmall={logoSrc.includes('jbl')}
              logo={logoSrc}
              alt={'Logo webu'}
            />
          </LeftWrapper>
          <RightWrapper>
            {/*
            {cookies.load('IrhjUTUTnB') === 'RAD6YwnIpj' ? (
              <HeaderProfile user={user} dispatch={dispatch} />
            ) : (
              <></>
            )}
             */}
            <HeaderProfile user={user} dispatch={dispatch} />
            <HeaderCart
              cartData={cartData}
              numberOfProducts={2}
              totalPrice={900.99}
              currency={'EUR'}
              cartWidth={50}
              dispatch={dispatch}
            />
            <>
              <HeaderLangButton dispatch={dispatch} lang={lang} />
              <RightWrapper>
                <SvgIcon
                  marginLeft={16}
                  marginRight={16}
                  icon={IconType.search}
                  alt={__('Otvoriť vyhľadávanie')}
                  cursor={'pointer'}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() =>
                    setIsSearchVisible(isSearchVisible => !isSearchVisible)
                  }
                  fill="#000000"
                />
              </RightWrapper>{' '}
              <RightWrapper>
                <SvgIcon
                  icon={IconType.listMenu}
                  alt="Otvoriť menu"
                  cursor={'pointer'}
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={toggleMenu}
                  fill="#000000"
                />
              </RightWrapper>
            </>
          </RightWrapper>
        </MobileMenuWrapper>
        {(isSearchVisible || isMenuVisible) && (
          <SubHeaderWrapper
            isInnerSearchMenuVisible={isSearchVisible || isMenuVisible}
          >
            {isSearchVisible && (
              <>
                <SearchWrapper ref={searchWrapper}>
                  <SearchForm
                    showWithVat={true}
                    showMoc={true}
                    partialSearchArticlesIsFetching={
                      partialSearchArticlesIsFetching
                    }
                    searchResults={{
                      productsResult: partialSearchProducts,
                      categoriesResult: partialSearchCategories,
                      articlesResult: partialSearchArticles,
                    }}
                    partialSearchProductsIsFetching={
                      partialSearchProductsIsFetching
                    }
                    partialSearchCategoriesIsFetching={
                      partialSearchCategoriesIsFetching
                    }
                    redirectToSearchResultPage={redirectToSearchResultsPage}
                    searchTerm={searchTerm}
                    handleSearchTermChange={handleSearchTermChange}
                    handleFocus={handleSearchFocus}
                    clearSearchInput={clearSearchInput}
                    hideResultViewer={hideSearchResultsViewer}
                    resultsDropdownVisible={resultsDropdownVisible}
                    isMobile={true}
                    buttonHasArrow={true}
                  />
                </SearchWrapper>
              </>
            )}
            {isMenuVisible && (
              <>
                <MobileNavMenu closeMenu={closeMenu}>
                  <MobileNavigation
                    closeMenu={closeMenu}
                    items={items}
                    showSearch={showSearch}
                    lang={lang}
                    navArticles={headerArticles}
                    byId={byId}
                  />
                </MobileNavMenu>
                <Dimmer height={100} zIndex={-1} />
              </>
            )}
          </SubHeaderWrapper>
        )}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default MobileHeader;
