import * as actionTypes from './constants';

const initialState = {
  productDetail: {},
  isFetching: false,
  connections: {
    alternatives: {
      isFetching: false,
      products: {},
    },
    crosssell: {
      isFetching: false,
    },
    accessories: {
      isFetching: false,
    },
  },
  articles: {
    isFetching: false,
    articles: [],
    total: 0,
    offset: 0,
  },
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        productDetail: action.payload.productDetail,
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case actionTypes.REQUEST_PRODUCT_BAZAR: {
      return {
        ...state,
        connections: {
          ...state.connections,
          bazar: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_BAZAR_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          bazar: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_BAZAR_ERROR: {
      return {
        ...state,
        connections: {
          ...state.connections,
          bazar: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }

    case actionTypes.REQUEST_PRODUCT_RELATED_DETAIL: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_RELATED_COLORS_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            products: {
              ...state.connections.alternatives.products,
              colors: action.payload.colors,
            },
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_RELATED_CONNECTIONS_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            products: {
              ...state.connections.alternatives.products,
              connections: action.payload.connections,
            },
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_RELATED_ERROR: {
      return {
        ...state,
        connections: {
          ...state.connections,
          alternatives: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }

    case actionTypes.REQUEST_PRODUCT_CROSSSELL_DETAIL: {
      return {
        ...state,
        connections: {
          ...state.connections,
          crosssell: {
            isFetching: true,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_CROSSSELL_DETAIL_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          crosssell: {
            isFetching: false,
            products: action.payload.connections,
          },
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_CROSSSELL_ERROR: {
      return {
        ...state,
        connections: {
          ...state.connections,
          crosssell: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }

    case actionTypes.REQUEST_PRODUCT_DETAIL_ARTICLES: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: true,
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          articles: action.payload.articles,
          total: action.payload.total,
        },
      };
    }

    case actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          isFetching: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
};
