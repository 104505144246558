import { getImagePath } from './product';

export const getAddressesFromOrder = order => {
  const addresses: any = [];

  // fakturacna adresa
  const { address, company, delivery } = order;
  const billingAddress = {
    isBillingAddress: true,
    company: company.name || address.company,
    name: `${address.person.name} ${address.person.surname}`,
    street: `${address.street} ${address.street_number}`,
    zip: address.zip,
    city: address.city,
    country:
      address.country.toLowerCase() === 'slovensko' ? '' : address.country, // ak je krajina Slovensko, tak ju nezobrazujeme
    phone: address.person.phone,
    email: address.person.email,
    ico: company.ico,
    dic: company.dic,
    icDph: company.ic_dph,
  };
  addresses.push(billingAddress);

  if (order.use_delivery_address) {
    // adresa dorucenia
    const deliveryAddress = {
      isBillingAddress: false,
      company: delivery.delivery_firm,
      name: `${delivery.person.name} ${delivery.person.surname}`,
      street: `${delivery.delivery_addr_street} ${delivery.delivery_addr_street_number}`,
      zip: delivery.delivery_addr_zip,
      city: delivery.delivery_addr_city,
      country:
        delivery.delivery_addr_country === 'slovensko'
          ? ''
          : delivery.delivery_addr_country, // ak je krajina Slovensko, tak ju nezobrazujeme
      phone: delivery.person.phone,
      email: delivery.person.email,
      ico: company.ico,
      dic: company.dic,
      icDph: company.ic_dph,
    };
    addresses.push(deliveryAddress);
  }

  return addresses;
};

export const getPaymentUrl = (paymentType: string, orderPublicId: string) => {
  switch (paymentType) {
    case 'cardpay':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/cardpay/${orderPublicId}`;
    case 'tatrapay':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/tatrapay/${orderPublicId}`;
    case 'sporopay':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/sporopay/${orderPublicId}`;
    case 'vubpay':
      return `${process.env.REACT_APP_API_BASE_URL}/payment/vubpay/${orderPublicId}`;
    default:
      return null;
  }
};

export const getOrderItemImage = images => {
  const mainImg: any = Object.values(images).find((img: any) => img.primary);
  let url = '';
  if (mainImg) {
    url = getImagePath(mainImg.filename);
  }
  return url;
};

export const redirectVub = (publicId: string) => {
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.origin &&
    window.location.pathname
  ) {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/payment/vubpay/${publicId}?return_url=${window.location.origin}${window.location.pathname}`;
  }
};

export const redirectCsob = (publicId: string) => {
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.origin &&
    window.location.pathname
  ) {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/payment/csob/${publicId}?return_url=${window.location.origin}${window.location.pathname}`;
  }
};

export const redirectComgate = (publicId: string) => {
  if (
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.origin &&
    window.location.pathname
  ) {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/payment/comgate/${publicId}?return_url=${window.location.origin}${window.location.pathname}`;
  }
};

export const isPaymentTypeComgate = (paymentType: string) => {
  if (paymentType && paymentType.startsWith('COMGATE')) return true;
  return false;
};
