import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
  DsiCheckBox as CheckBox,
  DsiInput as Input,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { FlexCol } from 'eshop-defaults/lib';

interface Props {
  watchDogOpen: () => void;
}

function WatchDog(props) {
  const { watchDogOpen } = props;
  return (
    <>
      <Wrapper>
        <Heading>
          <FlexRowCenterVertical>
            <SvgIcon
              icon={IconType.dog}
              width={32}
              height={32}
              fill={'#414141'}
            />
            <Title>{`Strážny pes`}</Title>
          </FlexRowCenterVertical>
          <IconWrapper onClick={watchDogOpen}>
            <StyledSvgIcon
              icon={IconType.close}
              width={24}
              height={24}
              fill={'#414141'}
            />
          </IconWrapper>
        </Heading>
        <div>
          <MiddleTitle>{`Chcem byť informovaný e-mailom:`}</MiddleTitle>
          <SubWrapper>
            <CheckBoxWrapper>
              <CheckBox
                name="productAvailable"
                type={'checkbox'}
                selected={true}
              />
              <StyledLabel htmlFor="productAvailable" selected={true}>
                {`Keď bude tovar skladom`}
              </StyledLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox name="priceChange" type={'checkbox'} selected={false} />
              <StyledLabel htmlFor="priceChange" selected={false}>
                {`Pri zmene ceny`}
              </StyledLabel>
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox name="priceUnder" type={'checkbox'} selected={false} />
              <StyledLabel htmlFor="priceUnder" selected={false}>
                {`Pri znížení ceny pod`}
              </StyledLabel>
              <PriceLimitInput type="number" value={0} />
              <TextP>{`€`}</TextP>
            </CheckBoxWrapper>
          </SubWrapper>
          <Inputs>
            <InputWrapper>
              <StyledLabel htmlFor="productAvailable">{`E-mail*`}</StyledLabel>
              <Input type="email" value={'@'} required />
            </InputWrapper>
            <InputWrapper>
              <StyledLabel htmlFor="productAvailable">
                {`Strážiť do`}
              </StyledLabel>
              <Input type="date" />
            </InputWrapper>
          </Inputs>
        </div>
        <Buttons>
          <StyledButton>{`Potvrdiť`}</StyledButton>
        </Buttons>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  margin-left: ${rem(8)};
  font-size: ${rem(20)};
  font-weight: 500;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const Buttons = styled(FlexRowCenterVertical)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;
`;

const MiddleTitle = styled(TextP)`
  margin-bottom: ${rem(36)};
`;

const CheckBoxWrapper = styled(FlexRowCenterVertical)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(24)};
`;

const StyledLabel = styled.label<{ selected: boolean }>`
  font-size: ${rem(16)};
  margin-right: ${rem(12)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const PriceLimitInput = styled(Input)`
  width: ${rem(100)};
  height: ${rem(40)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: ${rem(1)} solid #cccccc;
  border-radius: ${rem(4)};
  margin-right: ${rem(12)};
`;

const SubWrapper = styled.div`
  margin-bottom: ${rem(32)};
`;

const Inputs = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(24)};
`;

const InputWrapper = styled(FlexCol)`
  margin-right: ${rem(16)};
  width: 90%;
  &:last-child {
    margin-right: 0;
  }
`;

export { WatchDog };
