import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import { loadSupportTree } from '../CMS/actions';
import Actions from '../../components/Actions/Actions';
import { FlexCol, FlexRow, MetaTags, DsiTitle as Title } from 'eshop-defaults';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { Navigation } from '../../components/Navigation/Navigation';
import { withRouter, WithRouterProps } from 'react-router';
import { supportSelector } from '../CMS/selectors';
import { prop } from '../../utilities';
import RenderedBody from '../../components/Article/RenderedBody';
import { resolveArticleContentDetails } from '../../utilities/article';
import { setIsBreadcrumbVisible } from '../App/actions';

interface Props {
  dispatch: any;
  lang: string;
  saleProducts: any;
  support: any;
  article: any;
}

class SupportContainer extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, location, lang } = props;
    dispatch(setBreadCrumbPath(BreadCrumbType.SUPPORT));
    await dispatch(loadSupportTree());

    const urlMap = await API.getUrlMap(
      { url: location.pathname },
      { xAcceptLanguage: lang },
    );
    dispatch(setIsBreadcrumbVisible(false));

    if (urlMap && prop(urlMap, 'data.content_id')) {
      const article = await API.loadArticleDetail(
        prop(urlMap, 'data.content_id'),
        { withLangVersions: '1', replaceCurrentLangTags: '1' },
        { xAcceptLanguage: lang },
      );
      return { article };
    } else {
      return { article: null };
    }
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
  }

  public renderNavigation = () => {
    const { support } = this.props;
    const links = support
      ? support.map(supp => ({
          name: supp.name,
          link: `/${supp.url}`,
          redirectLink:
            typeof prop(supp, 'json_content.redir', '') === 'string'
              ? prop(supp, 'json_content.redir', '')
              : '',
        }))
      : [];
    return <Navigation links={links} location={this.props.location} />;
  };

  public renderContent = () => {
    const { article } = this.props;

    let name = '';
    let body = '';
    if (article) {
      const data = resolveArticleContentDetails(article);
      body = data.body;
    }

    return (
      <ContentWrapper>
        <NavigationWrapper>{this.renderNavigation()}</NavigationWrapper>
        {article && (
          <InnerContentWrapper>
            <StyledTitle marginTop={0} marginBottom={32}>
              {article.name}
            </StyledTitle>
            <RenderedBody
              inputBody={body}
              articleTitle={name}
              articleType={article.url}
              filesToDownloads={article.downloads}
            />
          </InnerContentWrapper>
        )}
      </ContentWrapper>
    );
  };

  public render() {
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: `Podpora` }} />
          {this.renderContent()}
        </Wrapper>
      </>
    );
  }
}

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0 ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(16)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${rem(80)};
  padding-top: ${rem(56)};
  flex-direction: row;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const InnerContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(56)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(32)};
  `}

  ${({ theme }) => theme.mediab.l925`
    padding: 0;
  `}

  ${({ theme }) => theme.mediab.m580`
    padding-top: ${rem(16)};
  `}
`;

const NavigationWrapper = styled(FlexRow)`
  max-width: ${rem(304)};
  flex: 1 1 ${rem(304)};

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

export const StyledTitle = styled(Title)`
  font-weight: 500;
  font-size: ${rem(36)};
  line-height: ${rem(48)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    support: supportSelector(state),
  };
};

const Support = withRouter(SupportContainer);

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SupportContainer' })(Support),
);
