import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import Sellers from '../../components/Sellers/Sellers';
import { MetaTags } from 'eshop-defaults';
import { __ } from 'react-i18n';
import { hostnameSelector } from '../App/selectors';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  dispatch: any;
  lang: string;
  hostname: string;
}

class SellersContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
  }

  public render() {
    const { dispatch, lang, hostname } = this.props;

    return (
      <>
        <MetaTags tags={{ title: __('Autorizovaní predajcovia') }} />
        <Sellers dispatch={dispatch} lang={lang} hostname={hostname} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SellersContainer' })(SellersContainer),
);
