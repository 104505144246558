import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
  DsiCountSetter as CountSetter,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { formatPrice } from '../../utilities';
import { addItemToCart } from '../../containers/Cart/cartSlice';
import { Loader } from '../_helpers/Loader/Loader';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import OutsideClick from '../_helpers/Default/OutsideClick';
import { Modal } from '../_helpers/Modal/Modal';

interface Props {
  handleClose: () => void;
  handleLeftButtonClick: () => void;
  handleRightButtonClick: () => void;
  titleText: string;
  body: string;
  leftButtonText: string;
  rightButtonText: string;
}

function ConfirmModal(props) {
  const { titleText, body, buttonText, handleClose, handleButtonClick } = props;

  return (
    <>
      {/* <Dimmer height={100} zIndex={990} topZero={true} /> */}
      <OutsideClick handleFunction={handleClose}>
        <AddModal>
          <Wrapper>
            <Heading>
              <Title>{titleText}</Title>
              <IconWrapper onClick={handleClose}>
                <StyledSvgIcon
                  icon={IconType.close}
                  width={24}
                  height={24}
                  fill={'#414141'}
                />
              </IconWrapper>
            </Heading>
            <BodyWrapper>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </BodyWrapper>
            <Buttons>
              <MiddleButton onClick={handleButtonClick}>
                {buttonText}
              </MiddleButton>
            </Buttons>
          </Wrapper>
        </AddModal>
      </OutsideClick>
    </>
  );
}

const BodyWrapper = styled.div`
  color: black;
`;

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
`;

const AddModal = styled(StyledModal)`
  max-width: ${rem(500)};
  width: 95%;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 500;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(12)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};
  justify-content: space-between;
  flex-flow: row wrap;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: ${rem(10)};
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
  `}
`;

const MiddleButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
    margin-bottom:${rem(16)};
  `}
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const Plu = styled(Name)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.inactiveFontColor};
`;

const Price = styled(Name)`
  min-width: ${rem(56)};
  text-align: left;

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
  `}
`;

const AddToCartButton = styled(Button)`
  text-decoration: none;
`;

export { ConfirmModal };
