import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { FlexCol, MetaTags } from 'eshop-defaults';
import {
  customerInfoSelector,
  fetchDeliveryAddresses,
  deliveryAddressesSelector,
  deliveryAddressesIsFetchingSelector,
  fetchSerialNumbers,
  serialNumbersSelector,
  fetchSerialNumbersInfoContent,
  setSerialNumbersLoading,
} from './myAccountSlice';
import MyDeliveryAddresses from '../../components/MyAccount/MyDeliveryAddresses';
import API, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { cartDataSelector, replaceCurrentCart } from '../Cart/cartSlice';
import MySerialNumbers from '../../components/MyAccount/MySerialNumbers';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n';

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
  serialNumbers: any;
}

interface State {
  addSerialNumberError: string;
}

class MySerialNumbersContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      addSerialNumberError: '',
    };
  }

  public static async getInitialProps(props) {
    const { dispatch } = props;
    dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_SERIAL_NUMBERS));
    try {
      await dispatch(fetchSerialNumbers(20, 0));
      await dispatch(fetchSerialNumbersInfoContent());
    } catch (exp) {
      return;
    }
  }

  public render() {
    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Sériové čísla') }} />
        <MySerialNumbers
          user={this.props.user}
          serialNumbers={this.props.serialNumbers.data}
          infoContent={this.props.serialNumbers.infoContent}
          limit={this.props.serialNumbers.limit}
          offset={this.props.serialNumbers.offset}
          total={this.props.serialNumbers.total}
          isFetching={this.props.serialNumbers.isFetching}
          addSerialNumberError={this.state.addSerialNumberError}
          handleOffsetChange={this.handleOffsetChange}
          handleAddSerialNumber={this.handleAddSerialNumber}
          handleApplyBenefit={this.handleApplyBenefit}
        />
      </>
    );
  }

  public handleOffsetChange = async e => {
    const num = parseInt(e.target.text, 10) - 1;
    const offset = 20 * num;

    await this.props.dispatch(fetchSerialNumbers(20, offset));
  };

  public handleAddSerialNumber = async serialNumber => {
    try {
      this.props.dispatch(setSerialNumbersLoading(true));
      const createCustomerSerialNumbersResponse = await API.createCustomerSerialNumbers(
        this.props.user.id,
        {},
        { serial_number: serialNumber },
      );
      this.setState({
        addSerialNumberError: '',
      });
      this.refreshAll();
    } catch (e) {
      this.props.dispatch(setSerialNumbersLoading(false));
      if (e.details.status === 404) {
        this.setState({
          addSerialNumberError: __('Neznáme sériové číslo'),
        });
      } else if (e.details.status === 409) {
        this.setState({
          addSerialNumberError: __('Toto sériové číslo už máte priradené'),
        });
      } else {
        this.setState({
          addSerialNumberError: __('Chyba priradenia sériového čísla'),
        });
      }
    }
  };

  public handleApplyBenefit = async (
    clubUserSerialNumberId,
    serialNumberBenefitId,
  ) => {
    this.setState({ addSerialNumberError: '' });

    this.props.dispatch(setSerialNumbersLoading(true));
    try {
      const response = await API.applySerialNumberBenefit(
        this.props.user.id,
        {},
        {
          club_user_serial_number_id: clubUserSerialNumberId,
          serial_number_benefit_id: serialNumberBenefitId,
        },
      );
      this.refreshAll();
      return response;
    } catch (e) {
      this.props.dispatch(setSerialNumbersLoading(false));
    }
    return null;
  };

  public refreshAll() {
    this.setState({ addSerialNumberError: '' });
    this.props.dispatch(fetchSerialNumbers(20, 0));
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetching: deliveryAddressesIsFetchingSelector(state),
    serialNumbers: serialNumbersSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MySerialNumbersContainer' })(
    MySerialNumbersContainer,
  ),
);
