import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FlexRow, DsiBreadCrumb as BreadCrumb } from 'eshop-defaults';
import { breadCrumbSelector, BreadCrumbPath } from './breadCrumbSlice';
import { rem } from 'polished';
import { BreadcrumbsMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { langSelector } from '../App/selectors';
import { resolveBaseUrlByLang } from '../../configureTrans';

interface Props {
  breadCrumb: BreadCrumbPath;
  lang: string;
}

function BreadCrumbContainer({ breadCrumb, lang }: Props) {
  const showBreadCrumbs = breadCrumb && breadCrumb.length > 0;

  return (
    <>
      {showBreadCrumbs && (
        <Wrapper>
          {
            <BreadcrumbsMicrodata
              items={breadCrumb.map(b => ({
                name: b.name,
                url: `${resolveBaseUrlByLang(lang)}${b.url}`,
              }))}
            />
          }
          <BreadCrumb breadCrumb={breadCrumb} />
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled(FlexRow)`
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    breadCrumb: breadCrumbSelector(state),
    lang: langSelector(state),
  };
};

// @ts-ignore
export default connect(mapStateToProps)(BreadCrumbContainer);
