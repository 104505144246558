import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { setRedirect } from '@bart.sk-ecommerce/react-eshop-redirects';

interface Props {
  urlMapData: any;
  dispatch: any;
}

class Redirect extends React.Component<Props> {
  public static async getInitialProps(props) {
    return props;
  }

  public componentDidMount() {
    const urlMapData = this.props.urlMapData;
    if (urlMapData && urlMapData.data && urlMapData.data.new_url && window) {
      window.location.replace(urlMapData.data.new_url);
      this.props.dispatch(setRedirect(urlMapData.data.new_url, 301));
    }
  }

  public render() {
    return <Loader loading={true} />;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Redirect' })(Redirect),
);
