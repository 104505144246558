import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  fetchCustomerDetails,
  fetchCustomerFinancialDetails,
  fetchInvoices,
  invoicesSelector,
  invoicesFetchingSelector,
  invoicesPaginationSelector,
} from './myAccountSlice';
import MyInvoices from '../../components/MyAccount/MyInvoices';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import API from '../../services/API';
import { currencySelector } from '../App/selectors';
import { prop } from '../../utilities';

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  invoices: any[];
  isFetching: boolean;
  token: string;
  currency: string;
}

interface State {
  currentId: string;
  isDownloading: boolean;
}

class MyInvoicesContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_INVOICES));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchOrders(null, LIMIT, 0)),
        await dispatch(fetchInvoices({})),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
      isDownloading: false,
    };
  }

  public refetchData = async ({ fromDate, toDate, type, paid, limit }) => {
    await this.props.dispatch(
      fetchInvoices({ type, fromDate, toDate, paid, limit }),
    );
  };

  public downloadFile = order => {
    const { token } = this.props;

    this.setState({ isDownloading: true });
    fetch(`${process.env.REACT_APP_API_BASE_URL}${order.link}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${order['@_InvCus_Code']}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        this.setState({ isDownloading: false });

        // Clean up and remove the link
        (link as any).parentNode.removeChild(link);
      });
  };

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
      invoices,
      isFetching,
      currency,
    } = this.props;
    const { currentId, isDownloading } = this.state;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: __('Všetky pobočky'), value: user.id.toString() },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Faktúry a dobropisy') }} />
        <MyInvoices
          orders={invoices}
          ordersData={ordersData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetching}
          downloadFile={this.downloadFile}
          currency={currency}
          isDownloading={isDownloading}
          refetchData={this.refetchData}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ currentId: id });
    const { invoices } = this.props;
    this.props.dispatch(
      fetchInvoices({
        ...prop(invoices, 'activeFilters'),
        fromDate: prop(invoices, 'activeFilters.filter_from'),
        toDate: prop(invoices, 'activeFilters.filter_to'),
        offset: prop(invoices, 'activeFilters.offset' || 0),
        idUser: id,
      }),
    );
  };

  public handleOffsetChange = async e => {
    const { invoices } = this.props;
    const num = parseInt(e.target.text, 10) - 1;

    const offset = prop(invoices, 'pagination.limit') * num;
    await this.props.dispatch(
      fetchInvoices({
        ...prop(invoices, 'activeFilters'),
        fromDate: prop(invoices, 'activeFilters.filter_from'),
        toDate: prop(invoices, 'activeFilters.filter_to'),
        offset,
      }),
    );
  };
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    currency: currencySelector(state),
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: invoicesPaginationSelector(state),
    childUsers: childUsersSelector(state),
    invoices: invoicesSelector(state),
    isFetching: invoicesFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyInvoicesContainer' })(MyInvoicesContainer),
);
