import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

export interface Props {
  path: string;
  externalPath?: boolean;
  alt?: string;
  lazy?: boolean;
  preload?: boolean;
  preloadQuality?: number;
  preloadQualityTimeout?: number;
  imageStyle?: CSSProperties;
  defaultWidth?: number;

  autoWidth?: {
    increment: number;
    min: number;
    max: number;
    addition?: number;
  };

  manualWidth?: {
    cssMaxWidth: number;
    cssMinWidth: number;
    loadWidth: number;
  }[];
}

const MainImage = styled.img``;

export const ResponsiveImage = (props: Props) => {
  const [mainImageLoaded, setMainImageLoaded] = useState(false);

  const {
    path,
    alt,
    lazy,
    preloadQuality,
    preloadQualityTimeout,
    imageStyle,
    defaultWidth,
    preload,
    externalPath,
  } = props;

  React.useEffect(() => {
    if (window) {
      if (preloadQualityTimeout) {
        setTimeout(() => {
          setMainImageLoaded(true);
        }, preloadQualityTimeout);
      } else {
        setMainImageLoaded(true);
      }
    }
  }, []);

  const onLoadMainImage = () => {
    if (window) {
      if (preloadQualityTimeout) {
        setTimeout(() => {
          setMainImageLoaded(true);
        }, preloadQualityTimeout);
      } else {
        setMainImageLoaded(true);
      }
    }
  };

  const getImagePath = (params: {
    path: string;
    width?: number;
    height?: number;
    quality?: number;
  }) => {
    if (!externalPath) {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const parameters: string[] = [];
      parameters.push(`query=${params.path}`);
      parameters.push(`webp=1`);
      parameters.push(`type=cover`);
      if (params.width) {
        parameters.push(`width=${params.width}`);
      }
      if (params.height) {
        parameters.push(`height=${params.height}`);
      }
      if (params.quality) {
        parameters.push(`quality=${params.quality}`);
      }
      return `${baseUrl}/image?${parameters.join('&')}`;
    } else {
      return params.path;
    }
  };

  const generateAutoWidthSource = quality => {
    const { autoWidth, preload } = props;

    const cza = autoWidth?.addition ? autoWidth.addition : 0;

    if (autoWidth) {
      const components: any[] = [];
      let iteraction = 0;
      for (
        let width = autoWidth?.min;
        width <= autoWidth?.max;
        width += autoWidth.increment
      ) {
        iteraction++;
        const imagePath = getImagePath({ path, width: width + cza, quality });

        const cssMaxWidth = width;
        const cssMinWidth =
          iteraction === 1 ? 0 : cssMaxWidth - autoWidth.increment + 1;

        components.push(
          <source
            media={`(min-width: ${cssMinWidth}px) and (max-width: ${cssMaxWidth}px)`}
            srcSet={imagePath}
          />,
        );
        if (preload) {
          components.push(
            <Helmet>
              <link
                rel="preload"
                href={imagePath}
                as="image"
                media={`(min-width: ${cssMinWidth}px) and (max-width: ${cssMaxWidth}px)`}
              />
            </Helmet>,
          );
        }
      }
      return components;
    } else {
      return <></>;
    }
  };

  const generateManualWidthSource = quality => {
    const { manualWidth } = props;
    if (manualWidth && manualWidth.length) {
      const components: any[] = [];
      for (const manualWidthItem of manualWidth) {
        const imagePath = getImagePath({
          path,
          width: manualWidthItem.loadWidth,
          quality,
        });
        const cssMaxWidth = manualWidthItem.cssMaxWidth;
        const cssMinWidth = manualWidthItem.cssMinWidth;
        components.push(
          <source
            media={`(min-width: ${cssMinWidth}px) and (max-width: ${cssMaxWidth}px)`}
            srcSet={imagePath}
          />,
        );
        if (preload) {
          components.push(
            <Helmet>
              <link
                rel="preload"
                href={imagePath}
                as="image"
                media={`(min-width: ${cssMinWidth}px) and (max-width: ${cssMaxWidth}px)`}
              />
            </Helmet>,
          );
        }
      }
      return components;
    } else {
      return <></>;
    }
  };

  const quality =
    preloadQuality && !lazy && !mainImageLoaded ? preloadQuality : undefined;
  const mainImagePath = getImagePath({
    path,
    quality,
    width: defaultWidth ? defaultWidth : undefined,
  });

  return (
    <picture>
      {generateAutoWidthSource(quality)}
      {generateManualWidthSource(quality)}
      <MainImage
        loading={lazy ? 'lazy' : undefined}
        alt={alt}
        src={mainImagePath}
        onLoad={onLoadMainImage}
        style={imageStyle}
      />
    </picture>
  );
};
