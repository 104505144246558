import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  DsiDashboardColumn as DashboardColumn,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { FlexRow } from 'eshop-defaults/lib';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
}

function MyDashboard({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
}: Props) {
  const content = [
    {
      content: [
        {
          name: 'Obrat za posledných 12 mesiacov',
          number: 999900,
        },
        {
          name: 'Obrat za rok 2020',
          number: 999900,
        },
      ],
      title: 'Faktúry',
      link: 'moj-ucet/faktury',
    },
    {
      content: [
        {
          name: 'Pridelený',
          number: 999900,
        },
        {
          name: 'Čerpaný',
          number: 999900,
        },
        {
          name: 'Zostáva',
          number: 999900,
        },
      ],
      title: 'Kredit',
      link: '',
    },
  ];
  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={32}>
        {__('Dashboard')}
      </StyledTitle>
      <FlexRow>
        {content &&
          content.map((item, key) => (
            <DashboardColumn
              key={key}
              title={item.title}
              content={item.content}
              url={item.link}
            />
          ))}
      </FlexRow>
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export const StyledTitle = styled(Title)`
  font-weight: 500;
  font-size: ${rem(36)};
`;

export default MyDashboard;
