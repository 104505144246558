import { hostnameSelector } from './../App/selectors';
import {
  DEFAULT_SITEMAP_ID,
  resolveDomainByHostname,
  resolveTagByHostname,
} from '../../configureTrans';
import { arrayToObject } from '../../utilities';
import { langSelector } from '../App/selectors';

export const namespace = 'CMS';

const requestCMSCategoriesTree = () => ({
  type: 'CMS/REQUEST_CMS_CATEGORIES',
});

const receiveCMSCategoriesTreeSuccess = (sitemap, sitemapObj) => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_SUCCESS',
  payload: {
    sitemap,
    sitemapObj,
  },
});

const receiveCMSCategoriesTreeError = error => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSCategoriesTree = (sitemapId?: any) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCMSCategoriesTree());
      const { sitemap_tree } = await API.loadSitemapSubtree(
        sitemapId || 'AKTUALITY',
        {},
        { xAcceptLanguage: getState().general.lang },
      );
      const sitemapObj = arrayToObject(sitemap_tree, 'id');
      dispatch(receiveCMSCategoriesTreeSuccess(sitemap_tree, sitemapObj));
    } catch (e) {
      dispatch(receiveCMSCategoriesTreeError(e));
    }
  };
};

const requestCMSArticles = () => ({
  type: 'CMS/REQUEST_CMS_ARTICLES',
});

const receiveCMSArticlesSuccess = articles => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_SUCCESS',
  payload: {
    articles,
  },
});

const receiveCMSArticlesError = (error: any) => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_ERROR',
  payload: {
    error,
  },
});

const requestBlogMenu = () => ({
  type: 'CMS/REQUEST_BLOG_MENU',
});

const receiveBlogMenuSuccess = links => ({
  type: 'CMS/RECIEVE_BLOG_MENU_SUCCESS',
  payload: {
    links,
  },
});

const receiveCurrentCmsArticleName = name => ({
  type: 'CMS/RECIEVE_CURRENT_ARTICLE_NAME',
  payload: {
    name,
  },
});

const receiveBlogMenuError = (error: any) => ({
  type: 'CMS/RECEIVE_BLOG_MENU_ERROR',
  payload: {
    error,
  },
});

export const loadCMSArticles = (
  sitemapId: number | null = null,
  limit: number = 15,
  offset: number = 0,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCMSArticles());

      const paramsObject: any = { limit, offset };
      if (!sitemapId) {
        paramsObject.sitemapId = 135;
      } else {
        paramsObject.sitemapId = sitemapId;
      }

      const articles = await API.loadArticles({
        ...paramsObject,
        sort: 'created_date',
        sortDir: 'desc',
        sfForm: 'Article',
        onlyActual: '1',
        isPublished: '1',
      });
      dispatch(receiveCMSArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveCMSArticlesError(e));
    }
  };
};

const requestSupportCategoriesTree = () => ({
  type: 'CMS/REQUEST_SUPPORT_TREE',
});

const receiveSupportTreeSuccess = (sitemap, sitemapObj) => ({
  type: 'CMS/RECEIVE_SUPPORT_TREE_SUCCESS',
  payload: {
    sitemap,
    sitemapObj,
  },
});

const receiveSupportTreeError = error => ({
  type: 'CMS/RECEIVE_SUPPORT_TREE_ERROR',
  payload: {
    error,
  },
});

export const loadSupportTree = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSupportCategoriesTree());
      const { articles } = await API.loadArticles(
        {
          sitemapUniqueId: 'JBL_PODPORA',
          isPublished: '1',
          sfForm: 'JblArticle',
          limit: 100,
          offset: 0,
          sort: 'sorting',
          onlyActual: '1',
          sortDir: 'asc',
        },
        { xAcceptLanguage: getState().general.lang },
      );
      const sitemapObj = arrayToObject(articles, 'id');

      dispatch(receiveSupportTreeSuccess(articles, sitemapObj));
    } catch (e) {
      dispatch(receiveSupportTreeError(e));
    }
  };
};

export const loadBlogMenu = currentSitemapId => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(requestBlogMenu());
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());

      const sitemapTree = await API.loadSitemapSubtree(
        `BLOG_${resolveTagByHostname(hostname)}`,
        {},
        { xAcceptLanguage: lang },
      );
      const links: Array<{ name: string; link: string }> = [];
      for (let treeItem of sitemapTree.sitemap_tree) {
        links.push({
          name: treeItem.name,
          link: '/blog/' + treeItem.id + '/' + treeItem.url,
        });

        if (currentSitemapId && treeItem.id === parseInt(currentSitemapId)) {
          dispatch(receiveCurrentCmsArticleName(treeItem.name));
        }
      }

      dispatch(receiveBlogMenuSuccess(links));
    } catch (e) {
      dispatch(receiveBlogMenuError(e));
    }
  };
};

const requestSearchCMSArticles = () => ({
  type: 'CMS/REQUEST_SEARCH_CMS_ARTICLES',
});

const receiveSearchCMSArticlesSuccess = articles => ({
  type: 'CMS/RECEIVE_SEARCH_CMS_ARTICLES_SUCCESS',
  payload: {
    articles,
  },
});

const receiveSearchCMSArticlesError = (error: any) => ({
  type: 'CMS/RECEIVE_SEARCH_CMS_ARTICLES_ERROR',
  payload: {
    error,
  },
});

export const loadSearchCMSArticles = (
  query: string,
  limit: number = 15,
  offset: number = 0,
  tag?: number,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSearchCMSArticles());

      const lang: string = langSelector(getState());
      const hostname = hostnameSelector(getState());

      let articles = null;
      if (tag) {
        articles = await API.loadArticles({
          query,
          offset,
          limit,
          sitemapId: DEFAULT_SITEMAP_ID[hostname][lang],
          sort: 'created_date',
          sortDir: 'desc',
          sfForm: 'Article',
          onlyActual: '1',
          tagId: tag ? tag : undefined,
        });
      } else {
        const hostname = hostnameSelector(getState());
        articles = await API.loadElasticArticles({
          limit,
          langId: lang,
          domainId: resolveDomainByHostname(hostname),
          q: query,
          offset,
          productsDomainId: resolveDomainByHostname(hostname),
        });
      }

      dispatch(receiveSearchCMSArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveSearchCMSArticlesError(e));
    }
  };
};
