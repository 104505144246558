const namespace = 'Product';

export const REQUEST_PRODUCT_DETAIL = `${namespace}/REQUEST_PRODUCT_DETAIL`;
export const RECEIVE_PRODUCT_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_ERROR`;

export const REQUEST_PRODUCT_RELATED_DETAIL = `${namespace}/REQUEST_PRODUCT_RELATED_DETAIL`;
export const RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_RELATED_COLORS_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_RELATED_COLORS_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_RELATED_CONNECTIONS_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_RELATED_CONNECTIONS_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_RELATED_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_RELATED_ERROR`;

export const REQUEST_PRODUCT_BAZAR = `${namespace}/REQUEST_PRODUCT_BAZAR`;
export const RECEIVE_PRODUCT_BAZAR_SUCCESS = `${namespace}/RECEIVE_PRODUCT_BAZAR_SUCCESS`;
export const RECEIVE_PRODUCT_BAZAR_ERROR = `${namespace}/RECEIVE_PRODUCT_BAZAR_ERROR`;

export const REQUEST_PRODUCT_CROSSSELL_DETAIL = `${namespace}/REQUEST_PRODUCT_CROSSSELL_DETAIL`;
export const RECEIVE_PRODUCT_CROSSSELL_DETAIL_SUCCESS = `${namespace}/RECEIVE_PRODUCT_CROSSSELL_DETAIL_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_CROSSSELL_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_CROSSSELL_ERROR`;

export const REQUEST_PRODUCT_DETAIL_ARTICLES = `${namespace}/REQUEST_PRODUCT_DETAIL_ARTICLES`;
export const RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS = `${namespace}/RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS`;
export const RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR = `${namespace}/RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR`;

export const SET_PRODUCT_DETAIL_ARTICLES_FILTERS = `${namespace}/SET_PRODUCT_DETAIL_ARTICLES_FILTERS`;
