import * as React from 'react';
import {
  resetToken,
  setCurrency,
  setDomainId,
  setHostname,
  setLanguage,
} from './actions';
import {
  resolveCurrencyByHostname,
  resolveDomainByHostname,
  resolveLangByHostname,
} from '../../configureTrans';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { prop } from '../../utilities';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { resetCart } from '../Cart/cartSlice';

class PreApp extends React.Component<{ isLoaded: boolean }, {}> {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  public static async getInitialProps(props) {
    const { dispatch, cookies, token, user } = props;

    await Promise.all([
      dispatch(setHostname(props.hostname)),
      dispatch(setLanguage(resolveLangByHostname(props.hostname))),
      dispatch(setCurrency(resolveCurrencyByHostname(props.hostname))),
      dispatch(setDomainId(resolveDomainByHostname(props.hostname))),
    ]);

    try {
      const userCookie =
        prop(cookies, `${USER_COOKIE}`) || !isSSR()
          ? cookie.load(USER_COOKIE)
          : null;
      await dispatch(
        loginUser(userCookie, false, () => {
          dispatch(resetCart(false));
          if (window && window.location && window.location.reload) {
            window.location.reload();
          }
        }),
      );

      if (!token || !user) {
        if (userCookie) {
          await dispatch(
            loginUser(userCookie, false, () => {
              dispatch(resetCart(false));
              if (window && window.location && window.location.reload) {
                window.location.reload();
              }
            }),
          );
        } else {
          await dispatch(resetToken());
        }
      }
    } catch (e) {}

    return {
      props,
      isLoaded: true,
      hostname: props.hostname,
    };
  }

  render() {
    return <> {this.props.isLoaded ? this.props.children : <></>} </>;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'PreApp' })(PreApp),
);
