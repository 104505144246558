import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { DsiRegisterForm as RegisterForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { registerUser } from '../App/actions';
import {
  registrationIsFetchingSelector,
  registrationSuccessMessageSelector,
  registrationErrorMessageSelector,
} from '../App/selectors';
import { onlyPublic } from '../../utilities/auth';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(16)};
     margin: 0 auto;
  `}
`;

class RegisterPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public render() {
    const { cartId, isFetching, successMessage, errorMessage } = this.props;
    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: 'Registrácia' }} />
          <RegisterForm
            cartId={cartId}
            handleFormSubmit={this.handleUserFormSubmit}
            handleGoogleLogin={this.handleGoogleLogin}
            isFetching={isFetching}
            successMessage={successMessage}
            errorMessage={errorMessage}
            lang={'sk'}
            enableEndUser={true}
            enableWarehouse={false}
            showPreRegister={false}
            sendAsBody={true}
          />
        </Wrapper>
      </>
    );
  }

  public handleUserFormSubmit = body => {
    const {
      email,
      password,
      confirmPassword,
      termsAccept,
      newsletter_accept,
    } = body;
    this.props.dispatch(
      registerUser(
        email,
        password,
        confirmPassword,
        termsAccept,
        newsletter_accept,
      ),
    );
  };

  public handleFacebookLogin = () => {
    if (
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      window.location.href
    ) {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
    }
  };

  public handleGoogleLogin = () => {
    if (
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      window.location.href
    ) {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
    }
  };
}

const mapStateToProps = state => {
  return {
    cartId: state.general.lang,
    isFetching: registrationIsFetchingSelector(state),
    successMessage: registrationSuccessMessageSelector(state),
    errorMessage: registrationErrorMessageSelector(state),
  };
};

export default connect(mapStateToProps)(RegisterPage);
