import { DsiInfoText, DsiInfoType } from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { __, __r } from 'react-i18n';
import { WithRouterProps, withRouter } from 'react-router';

interface Props {
  text: string;
}

function TopNavigationStrip({ text }: Props & WithRouterProps) {
  return (
    <InfoWrapper>
      <DsiInfoText
        type={DsiInfoType.INFO}
        info={text ? text.replace(/<p>/g, '').replace(/<\/p>/g, '') : ''}
        marginBottom={0}
        isDismissable={false}
        useDangerouslySetInnerHTML={true}
      />
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  /* position: absolute;
    top: 0; */
  z-index: 100;
  width: 100%;
  color: white;

  a {
    color: white;
    text-decoration: underline;
  }

  a:link {
    color: white;
    text-decoration: underline;
  }

  a:visited {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }

  a:active {
    color: white;
    text-decoration: underline;
  }
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(TopNavigationStrip));
