import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import { css } from 'styled-components';
import { __ } from 'react-i18n';
import { getImagePath } from '../../utilities/product';
import { ResponsiveImage } from '../_helpers/Image/ResponsiveImage';
interface Props {
  title: string;
  description: string;
  imagePath: string;
  redirectUrl: string;
  buttonDescription: string;
  isRight?: boolean;
  image: string;
}

export const SmallBanner = ({
  title,
  description,
  imagePath,
  redirectUrl,
  isRight = false,
  image,
  buttonDescription,
}: Props) => {
  const isFullUrl = Boolean(redirectUrl.match(/http|https/g));

  return (
    <BannerOutsideWrapper title={title}>
      <ImageWrapper>
        <ResponsiveImage
          path={image}
          imageStyle={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
          preloadQuality={1}
          preloadQualityTimeout={1000}
          defaultWidth={684}
          autoWidth={{
            min: 500,
            max: 1000,
            increment: 50,
            addition: -40,
          }}
          manualWidth={[
            { cssMinWidth: 1001, cssMaxWidth: 1050, loadWidth: 478 },
            { cssMinWidth: 1051, cssMaxWidth: 1150, loadWidth: 528 },
            { cssMinWidth: 1151, cssMaxWidth: 1250, loadWidth: 578 },
            { cssMinWidth: 1251, cssMaxWidth: 1350, loadWidth: 628 },
            { cssMinWidth: 1351, cssMaxWidth: 1450, loadWidth: 678 },
          ]}
        />
      </ImageWrapper>
      <BannerContent isRight={isRight}>
        <BannerSectionContainer>
          <Title>{title ? `${title}` : ' '}</Title>
          <BannerText>{description ? `${description}` : ' '}</BannerText>
        </BannerSectionContainer>
        <BannerSectionContainerRight>
          {isFullUrl ? (
            <StyledLink href={redirectUrl} title={__('Link')}>
              {buttonDescription || ''}
            </StyledLink>
          ) : (
            <BannerButton to={redirectUrl}>
              {buttonDescription || ''}
            </BannerButton>
          )}
        </BannerSectionContainerRight>
      </BannerContent>
    </BannerOutsideWrapper>
  );
};

const ImageWrapper = styled.div`
  height: ${rem(292)};
`;

const BannerSectionContainer = styled.div`
  padding: ${rem(20)};
`;

const BannerSectionContainerRight = styled.div`
  padding: ${rem(20)};

  ${({ theme }) => theme.media(0, 'sm')} {
    & > button,
    & > a {
      width: auto;
    }
  }

  ${({ theme }) => theme.media(0, 'xs')} {
    padding-left: ${rem(20)};
    padding-top: 0;
    width: 100%;

    & > button,
    & > a {
      width: auto;
    }
  }
`;

const BannerText = styled.p`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  margin: 0;
  font-weight: 400;

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(14)};
    line-height: ${rem(18)};
  }
`;

const Title = styled.h3`
  font-size: ${rem(36)};
  color: ${({ theme }) => theme.colors.black};
  line-height: ${rem(48)};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(24)};
    line-height: ${rem(28)};
  }
`;

const BannerContent = styled.div<{
  isRight?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${rem(108)} ${({ theme }) => theme.media(0, 'xs')} {
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    display: block;
  }
`;

const BannerOutsideWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

// obrazok banneru musi obsahovat aj farbu
const Background = styled.div<{
  backgroundUrl: string;
  nonWebpImage: string;
  isRight?: boolean;
}>`
  background-image: url('${props => props.backgroundUrl}');
  background-position: center center;
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-size: cover;
`;

const buttonLikeCss = css`
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  padding: ${rem(18)} ${rem(24)};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  height: initial;
  border-radius: ${rem(4)};
  text-decoration: none;
  width: max-content;

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: ${rem(10)} ${rem(24)};
    margin-bottom: 0;
    width: min-content;
    padding: ${rem(14)} ${rem(24)};
  }
`;

const BannerButton = styled(Link)`
  ${buttonLikeCss};
`;

const StyledLink = styled.a`
  ${buttonLikeCss};
`;
