export class UrlHashService {
  public values: object;

  constructor(hashInput: string | null = null) {
    let hash = hashInput;
    if (!hash) {
      hash = window.location.hash.substr(1); // odstranime # zo zaciatku
    }

    this.values = {};
    const keyValuePairs = hash.split('&');
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split('=');
      this.values[key] = value;
    }
  }

  public get(key: string, defaultValue: any = null): string {
    return this.values[key] || defaultValue;
  }

  public clear(): void {
    window.location.hash = '';
  }
}
