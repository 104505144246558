import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import Actions from '../../components/Actions/Actions';
import { MetaTags } from 'eshop-defaults';
import {
  actionSelector,
  fetchAction,
  SingleActionItemInterface,
} from './actionSlice';
import ParallaxImage from '../../components/Article/ParallaxImage';
import { getImagePath } from '../../utilities/product';
import RenderedBody from '../../components/Article/RenderedBody';
import { __ } from 'react-i18n';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { getCmsMetaTags } from '../../utilities/metatags';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { Helmet } from 'react-helmet';

const MainContent = styled.div`
  max-width: ${rem(1020)};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(30)};
  padding-bottom: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.m580`
     padding: 0 ${rem(16)};
  `}
`;

const CmsTitle = styled.h1`
  margin: 0;
  margin-bottom: ${rem(48)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  font-size: ${rem(36)};
  color: ${({ theme }) => theme.blogColors.primary};
  text-align: center;
  line-height: 48px;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  `}
`;

const SliderWrapper = styled.div`
  /* width: 1000px; */
  margin: auto;
  margin-top: ${rem(48)};
  /* @media only screen and (max-width: 1130px) {
    width: 800px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 650px) {
    width: 90vw;
  }
  @media only screen and (max-width: 380px) {
    width: 80vw;
  } */
`;

const Also = styled.h3`
  text-align: left;
  font: 24px/32px Barlow;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-top: ${rem(36)};

  @media only screen and (max-width: 1130px) {
    padding-left: 30px;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 10px;
  }

  @media only screen and (max-width: 580px) {
    padding-left: 0;
  }
`;

interface Props {
  dispatch: any;
  lang: string;
  id: number;
  action: SingleActionItemInterface;
}

class ActionContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, params } = props;
    dispatch(fetchAction(params.id));
  }

  public render() {
    const { dispatch, action } = this.props;

    return (
      <>
        <MetaTags tags={getCmsMetaTags(action)} />
        <div>
          {action ? (
            <>
              {action.imageUrl && (
                <ParallaxImage articleTitle={true} src={action.imageUrl} />
              )}

              <MainContent>
                <CmsTitle>{action.title}</CmsTitle>
                <RenderedBody
                  inputBody={action.description}
                  articleTitle={action.title}
                />

                {action.products && action.products.length > 0 && (
                  <SliderWrapper>
                    <Also>{__('Súvisiace produkty')}</Also>
                    <SliderComponent
                      type="blog-product"
                      slides={action.products}
                    />
                  </SliderWrapper>
                )}
              </MainContent>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    action: actionSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ActionContainer' })(ActionContainer),
);
