/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LanguageProvider } from 'react-i18n';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import 'balloon-css/balloon.min.css';
import { syncHistoryWithStore } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import './theme/globalStyles';
import configureStore from './configureStore';
import Routes from './routes';
import { getProp, prop } from './utilities/';
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import {
  loadTranslations,
  resolveDomainByHostname,
  resolveLangByHostname,
  resolveLangFromCookies,
} from './configureTrans';
import { FullscreenLoading } from 'eshop-defaults';
import { default as API } from './services/API';
import { theme } from './theme/theme';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import ReactGA from 'react-ga4';
// import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    __INIT_DATA__: any;
    __REDUX_STATE__: any;
    __ANALYTICS_CONFIG_DATA__: any;
  }
}

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL || '');

// Sentry.init({
//   dsn:
//     'https://24808acb0485d10285a05846a05159b7@o1222353.ingest.sentry.io/4505930939432960',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/jbl\.sk/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const reduxState = getProp(window, '__REDUX_STATE__');
if (!reduxState || reduxState === '{{SSR_INITIAL_STATE}}') {
  window.__REDUX_STATE__ = {};
}
const store = configureStore(window.__REDUX_STATE__);
const historyObj = syncHistoryWithStore(browserHistory, store);

if (!isSSR()) {
  if (
    window &&
    window.__REDUX_STATE__ &&
    window.__REDUX_STATE__.general &&
    window.__ANALYTICS_CONFIG_DATA__
  ) {
    try {
      ReactGA.initialize(
        window.__ANALYTICS_CONFIG_DATA__[
          `Google_analyticsCode-${window.__REDUX_STATE__.general.lang ||
            '-'}-${resolveDomainByHostname(window.location.hostname)}`
        ],
      );
    } catch (e) {}

    // ReactGA.set({ page: window.location.pathname });
    // ReactGA.pageview(window.location.pathname + window.location.search);

    // historyObj.listen(location => {
    //   setTimeout(() => {
    //     ReactGA.set({ page: location.pathname }); // Update the user's current page
    //     ReactGA.pageview(location.pathname); // Record a pageview for the given page
    //   }, 2000);
    // });
  }
}

const loadTranslationsData = () => loadTranslations(location);
if (typeof window !== 'undefined') {
  if (window.__REDUX_STATE__) {
    API.setToken(prop(window.__REDUX_STATE__, 'auth.token', undefined));
  }

  ReactDOM.hydrate(
    <ThemeProvider theme={theme}>
      <LanguageProvider
        translations={window.__INIT_DATA__.translations}
        loadCatalogues={loadTranslationsData}
        loadingContainer={<FullscreenLoading />}
      >
        <ErrorBoundary>
          <Provider store={store}>
            <Routes history={historyObj} />
          </Provider>
        </ErrorBoundary>
      </LanguageProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
}

export { store };
