import { FlexCol, OrderCancelComponent } from 'eshop-defaults';
import { rem } from 'polished';
import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { sendCancelOrder, sendCancelOrderRequest } from './actions';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
  params: {
    orderPublicId: string;
    token: string;
  };
}

interface State {
  cancelled: boolean;
  isFetching: boolean;
}

class OrderCancel extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      cancelled: false,
      isFetching: true,
    };
  }

  public static async getInitialProps(props) {
    const { dispatch } = props;
    await dispatch(fetchFinishedOrder(props.params.orderPublicId));
  }

  componentDidMount() {
    if (window) {
      sendCancelOrder(this.props.params.orderPublicId, this.props.params.token)
        .then(response => {
          this.setState({
            isFetching: false,
            cancelled: true,
          });
        })
        .catch(e => {
          console.log(e);
          this.setState({
            isFetching: false,
            cancelled: false,
          });
        });
    }
  }

  public render() {
    const { orderData } = this.props;

    return (
      <OrderCancelComponent
        order={orderData}
        cancelled={this.state.cancelled}
        isFetching={this.state.isFetching}
      />
    );
  }
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${rem(36)};
`;

const mapStateToProps = state => {
  return {
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrderCancel' })(OrderCancel),
);
