import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import { __ } from 'react-i18n';
import { getImagePath } from '../../../utilities/product';
import { css } from 'styled-components';
import { ResponsiveImage } from '../Image/ResponsiveImage';

interface Props {
  position: number; // starts with 0
  title: string;
  description: string;
  redirectUrl: string;
  buttonDescription: string;
  isRight?: boolean;
  image: string;
}

export const SliderBanner = ({
  title,
  description,
  redirectUrl,
  image,
  isRight = false,
  buttonDescription,
  position,
}: Props) => {
  const isFullUrl = redirectUrl
    ? Boolean(redirectUrl.match(/http|https/g))
    : false;

  return (
    <BannerWrapper>
      <ResponseImageWrapper>
        <ResponsiveImage
          path={image}
          lazy={position === 0 ? false : true}
          preload={position === 0 ? true : false}
          preloadQuality={1}
          preloadQualityTimeout={1000}
          autoWidth={{
            increment: 50,
            min: 100,
            max: 1950,
          }}
          imageStyle={{ objectFit: 'contain', width: '100%' }}
        />
      </ResponseImageWrapper>

      <BannerContent>
        <Title>{`${title}`.substring(0, 100)}</Title>
        <BannerText>{`${description}`.substring(0, 150)}</BannerText>
        {isFullUrl ? (
          <StyledLink href={redirectUrl} title={__('Link na externú stránku')}>
            {buttonDescription}
          </StyledLink>
        ) : (
          <BannerButton to={redirectUrl}>{buttonDescription}</BannerButton>
        )}
      </BannerContent>
    </BannerWrapper>
  );
};

// obrazok banneru musi obsahovat aj farbu
// TODO COMMENT FADE
const BannerWrapper = styled.section<{
  backgroundUrl: string;
  isRight?: boolean;
}>`
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: ${props => (props.isRight ? `flex-end` : `flex-start`)};
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediab.l1150`
    padding-bottom: ${rem(64)};
    padding-top: ${rem(64)};
  `}

  ${({ theme }) => theme.mediab.l925`
    // margin-right: ${rem(16)};
    // margin-left: ${rem(16)};
    // margin-bottom: ${rem(16)};
    // width: calc(100% - 32px);
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    padding-bottom: 0;
    padding-top: ${rem(165)};
    background-image: none;

    &::before {
      display: none;
    }
  }  
`;

const MobileImageWrapper = styled.div<{ backgroundUrl: string }>`
  display: none;
  background-image: url("${props => props.backgroundUrl}");
  background-size: cover;
  background-position: center center;
  height: ${rem(288)};
  width: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    ${
      /*
      background: transparent
      linear-gradient(180deg, #000000 0%, #00000000 0%, #000000 100%) 0% 0%
      no-repeat padding-box; 
      */
      ''
    }
    z-index: 0;
  }

  .no-webp &&& {
    background-image: url("${props => props.nonWebpImage}");
  }

  ${({ theme }) => theme.media(0, 'sm')} {
    display: block;
    height: 100%;
  }
`;

const BannerContent = styled.article`
  position: absolute;
  padding: ${rem(152)} 13.5%;
  /* max-width: 40%; */
  max-width: 100%;
  display: inline-block;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ theme }) => theme.mediab.l925`
    justify-content: flex-end;
    height: initial;
  `}

  ${({ theme }) => theme.media(0, 'sm')} {
    max-width: 100%;
    padding: 0px;
    padding-left 15px;
    padding-bottom: 170px;
    position: relative;
    background: ${({ theme }) => theme.mobileBanner.backgroundColor};
  }
`;

const Title = styled.h2`
  font-size: ${rem(49)};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${rem(16)};
  line-height: ${rem(56)};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(24)};
    line-height: ${rem(28)};
    margin-bottom: ${rem(24)};
    color: ${({ theme }) => theme.mobileBanner.textColor};
  }
`;

const buttonLikeCss = css`
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  padding: ${rem(18)} ${rem(24)};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(19)};
  height: initial;
  border-radius: ${rem(4)};
  text-decoration: none;

  ${({ theme }) => theme.media(0, 'sm')} {
    padding: ${rem(10)} ${rem(24)};
    margin-bottom: 0;
    width: initial;
    padding: ${rem(14)} ${rem(24)};

    color: ${({ theme }) => theme.mobileBanner.buttonTextColor};
    background-color: ${({ theme }) =>
      theme.mobileBanner.buttonBackgroundColor};
  }
`;

const BannerButton = styled(Link)`
  ${buttonLikeCss};
`;

const BannerText = styled.p`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(20)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin: 0;
  font-weight: 400;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.media(0, 'sm')} {
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    color: ${({ theme }) => theme.mobileBanner.textColor};
  }
`;

const ResponseImageWrapper = styled.div`
  height: 100%;
`;

const StyledLink = styled.a`
  ${buttonLikeCss};
`;
