import * as React from 'react';
// import { __ } from "react-i18n";

export default class ErrorBoundary extends React.Component {
  public state = { error: null };

  public componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (process.env.NODE_ENV !== 'development') {
      (window as any).Raven.captureException(error, { extra: errorInfo });
    }
  }

  public showError = () =>
    (window as any).Raven.lastEventId() &&
    (window as any).Raven.showReportDialog();

  public render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div>
          <div>Vyskytla sa chyba!</div>
        </div>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
