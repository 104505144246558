import API, { ThenArg } from 'src/services/API';
import { combineReducers } from 'redux';
import { Action } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';
import { generalReducer } from './containers/App/general-reducer';
import { authReducer } from 'react-auth';
import { homeReducer } from './containers/Home/reducer';
import { categoryReducer } from './containers/Category/reducer';
import { searchReducer } from './containers/Search/reducer';
import { productReducer } from './containers/Product/reducer';
import { headerReducer } from './containers/Header/reducer';
import { cmsReducer } from './containers/CMS/reducer';
import myAccountSliceReducer from './containers/MyAccount/myAccountSlice';
import cartReducer from './containers/Cart/cartSlice';
import breadCrumbReducer from './containers/BreadCrumb/breadCrumbSlice';
import { ThunkAction } from 'redux-thunk';
import categoryReducer2 from './containers/Category/categorySlice';
import actionsReducer from './containers/Actions/actionsSlice';
import actionReducer from './containers/Action/actionSlice';
import giftCardContentReducer from './containers/GiftCardContest/giftCardContentSlice';

/*
  DOCS: Eshop reducer
  general: generalReducer, (general + header + footer + search)
  cart: cartReducer,
  user: userReducer (userInfo + orders + demands + addresses + child accounts),
  homepage: homepageReducer,
  header: headerReducer,
  breadCrumbs: breadCrumbsReducer,
  product: productReducer,
  category: categoryReducer (products + filters),
  cms: cmsReducer (articles + detail of article)
  */

const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  general: generalReducer,
  breadCrumb: breadCrumbReducer,
  header: headerReducer,
  category: categoryReducer2,
  categoryDetail: categoryReducer,
  productDetail: productReducer,
  search: searchReducer,
  homepage: homeReducer,
  cms: cmsReducer,
  myAccount: myAccountSliceReducer,
  cart: cartReducer,
  actions: actionsReducer,
  action: actionReducer,
  giftCardContest: giftCardContentReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<
  void,
  RootState,
  ThenArg<typeof API>,
  Action<string>
>;

export type StateType<ReducerOrMap> = ReducerOrMap extends (
  ...args: any[]
) => any
  ? ReturnType<ReducerOrMap>
  : ReducerOrMap extends object
  ? { [K in keyof ReducerOrMap]: StateType<ReducerOrMap[K]> }
  : never;

export type State = StateType<typeof rootReducer>;
