import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const productDetailDomainSelector = state => state.productDetail;

export const productDataSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'productDetail', ''),
);

export const productDetailIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'isFetching', false),
);

export const productDetailErrorSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'error'),
);

export const productDetailArticlesSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'articles.articles', ''),
);

export const productDetailArticlesIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'articles.isFetching', false),
);

export const productDetailArticlesOffsetSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'articles.offset', false),
);

export const productDetailArticlesTotalSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'articles.total', false),
);

export const productAlternativesSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.alternatives.products', []),
);

export const productAlternativesIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.alternatives.isFetching', false),
);

export const productBazarSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.bazar.products', []),
);

export const productCrosssellSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.crosssell.products', []),
);

export const productCrosssellIsFetchingSelector = createSelector(
  productDetailDomainSelector,
  substate => prop(substate, 'connections.crosssell.isFetching', false),
);
