import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  FlexCol,
  FlexRow,
  CorporateArticleList as ArticleList,
  SitemapList,
  DsiPagination as Pagination,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import {
  loadCMSCategoriesTree,
  loadCMSArticles,
  loadBlogMenu,
} from './actions';
import {
  sitemapSelector,
  allArticlesSelector,
  sitemapIsFetchingSelector,
  articlesIsFetchingSelector,
  sitemapByIdSelector,
  linksIsFetchingSelector,
  allLinksSelector,
  currentArticleNameSelector,
} from './selectors';
import API, { ThenArg } from '../../services/API';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { Navigation } from '../../components/Navigation/Navigation';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { langSelector } from '../App/selectors';
import { resolveLangByHostname } from '../../configureTrans';

interface Props {
  dispatch: (action: any) => void;
  sitemap: ThenArg<typeof API.loadSitemapTree>['sitemap_tree'];
  isFetching: boolean;
  allArticles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  sitemapsById: object;
  isFetchingLinks: boolean;
  links: ThenArg<Array<{ name: string; link: string }>>;
  currentArticleName: string;
  lang: string;
  location: any;
}

class CMSArticles2 extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, params, sitemapId, location, lang } = props;
    const {
      query: { offset },
    } = location;

    const sitemap = await API.loadSitemap(
      sitemapId,
      {},
      { xAcceptLanguage: lang },
    );

    if (sitemap && sitemap.id) {
      if (prop(sitemap, 'name') && prop(sitemap, 'url')) {
        dispatch(
          setBreadCrumbPath(BreadCrumbType.CMS_ARTICLES, {
            name: prop(sitemap, 'name'),
            path: location.pathname,
          }),
        );
      }

      await Promise.all([
        await dispatch(loadCMSArticles(sitemapId, 15, offset)),
      ]);

      return { location };
    }

    return { isError: true };
  }

  public render() {
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: this.props.currentArticleName || 'Blog' }}
            noindex={true}
            nofollow={true}
          />
          {/* {this.renderCategories()} */}
          {this.renderArticles()}
        </Wrapper>
      </>
    );
  }

  public renderCategories = () => {
    const { sitemap } = this.props;
    const subid = this.props.location.query.subid;
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
      }));

    return (
      <SitemapList
        currentId={(subid && subid.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
      />
    );
  };

  public renderArticles = () => {
    const {
      allArticles,
      isFetchingArticles,
      location,
      sitemapsById,
      links,
      isFetchingLinks,
    } = this.props;
    const articles = prop(allArticles, 'articles');
    const total = prop(allArticles, 'total', 0);
    // const subid = location.query.subid ? location.query.subid.toString() : '-1';
    const offset = prop(location, 'query.offset', 0);
    if (isFetchingArticles || articles === null) {
      return <ZemplinLoaderWrapper />;
    }

    const showNav = !isFetchingLinks && links && links.length > 0;
    return (
      <ContentWrapper>
        {false && showNav && (
          <NavigationWrapper>{this.renderNavigation()}</NavigationWrapper>
        )}
        <ArticlesWrapper withPadding={showNav}>
          {/* <Title>{`${(this.props as any).route.props.name}`}</Title> */}
          <ArticleList
            sitemapsById={sitemapsById}
            articles={articles}
            isFetching={isFetchingArticles}
            hasListItems={true}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={total}
            limit={15}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </ArticlesWrapper>
      </ContentWrapper>
    );
  };

  public handleOffsetChange = (newOffset: number) => {
    const subid = this.props.location.query.subid;
    const searchQuery = subid
      ? `?subid=${subid}&offset=${newOffset}`
      : `?offset=${newOffset}`;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: searchQuery,
      }),
    );
  };

  public handleCategoryChange = async (newId: number | string) => {
    // const offset = this.props.location.query.offset;
    const { location, dispatch } = this.props;
    const searchQuery = `?subid=${newId}`;
    dispatch(
      push({
        pathname: location.pathname,
        search: searchQuery,
      }),
    );
  };

  public getPaginationQuery = () => {
    const { location } = this.props;

    return `${location.pathname}?`;
  };

  public renderNavigation = () => {
    const { links, isFetchingLinks, location } = this.props;

    if (!location) {
      return null;
    }
    return isFetchingLinks ? (
      <Navigation links={[]} location={location} />
    ) : (
      <Navigation links={links} location={location} />
    );
  };
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0 ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(16)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${rem(80)};
  flex-direction: row;
  margin-top: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     flex-flow: column;
  `}
`;

const ArticlesWrapper = styled.div<{ withPadding: boolean }>`
  width: 100%;
  margin: 0 auto;
  padding: ${({ withPadding }) => (withPadding ? `0 ${rem(56)}` : 0)};

  ${({ theme }) => theme.mediab.l1150`
    padding:  ${({ withPadding }) => (withPadding ? `0 ${rem(32)}` : 0)};
  `}

  ${({ theme }) => theme.mediab.l925`
    padding: 0;
  `}

  ${({ theme }) => theme.mediab.m580`
    padding-top: ${rem(16)};
  `}
`;

const NavigationWrapper = styled(FlexRow)`
  max-width: ${rem(304)};
  flex: 1 1 ${rem(304)};

  ${({ theme }) => theme.mediab.l925`
  flex: 1;
  margin-bottom: ${rem(16)};
  `}
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

const mapStateToProps = state => {
  return {
    sitemap: sitemapSelector(state),
    isFetching: sitemapIsFetchingSelector(state),
    allArticles: allArticlesSelector(state),
    isFetchingArticles: articlesIsFetchingSelector(state),
    sitemapsById: sitemapByIdSelector(state),
    isFetchingLinks: linksIsFetchingSelector(state),
    links: allLinksSelector(state),
    currentArticleName: currentArticleNameSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(CMSArticles2);
