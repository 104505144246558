import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  DsiFinishedOrder as FinishedOrder,
  FlexCol,
  FlexRowCenter,
  InfoText,
  prop,
  TextP,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import styled from '../../theme/styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import {
  isPaymentTypeComgate,
  redirectComgate,
  redirectCsob,
  redirectVub,
} from '../../utilities/order';
import { handleGAPurchase } from './cartSlice';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

interface State {
  redirectInit: boolean;
}

class FinishedOrderContainer extends React.Component<
  Props & WithRouterProps,
  State
> {
  private isSendingToGA: boolean;
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CART, {
        url: `${__r(
          'routes:dokoncena-objednavka',
          '/dokoncena-objednavka',
        )}/${orderPublicId}`,
      }),
    );
    try {
      await dispatch(fetchFinishedOrder(orderPublicId));
      return;
    } catch (exp) {
      console.log(`Order error: ${JSON.stringify(exp)}`);
      return;
    }
  }

  constructor(props) {
    super(props);

    this.isSendingToGA = false;
    this.state = {
      redirectInit: false,
    };
  }

  public async componentDidUpdate() {
    const {
      orderData,
      params: { orderPublicId },
    } = this.props;
    if (orderData && !this.isSendingToGA) {
      this.isSendingToGA = true;
      this.props.dispatch(handleGAPurchase(orderData));
    }
  }

  public render() {
    const {
      orderData,
      isFetching,
      location,
      params: { orderPublicId },
    } = this.props;

    if (!isFetching && !orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    }

    const status = prop(location, 'query.status');
    const isErrorQuery = (prop(location, 'query.status') || '').includes('?');
    const isError = isErrorQuery
      ? status.includes('error')
      : status === 'error';
    const isEmail = isErrorQuery
      ? !isError && status.includes('email')
      : status === 'email';
    const isPaid = isErrorQuery
      ? !isError && status.includes('success')
      : status === 'success';
    const isRedirectedPayment =
      prop(orderData, 'payment.payment_type') === 'VUB' ||
      prop(orderData, 'payment.payment_type') === 'CSOB' ||
      isPaymentTypeComgate(prop(orderData, 'payment.payment_type'));
    const isLoading = isRedirectedPayment && !status;

    if (isRedirectedPayment && !status) {
      if (!this.state.redirectInit) {
        this.setState({
          redirectInit: true,
        });
        setTimeout(() => {
          if (prop(orderData, 'payment.payment_type') === 'VUB') {
            redirectVub(orderPublicId);
          } else if (prop(orderData, 'payment.payment_type') === 'CSOB') {
            redirectCsob(orderPublicId);
          } else if (
            isPaymentTypeComgate(prop(orderData, 'payment.payment_type'))
          ) {
            redirectComgate(orderPublicId);
          }
        }, 5000);
      }
    }

    return (
      <>
        <Wrapper className="container container--wide">
          {isFetching ? (
            <ZemplinLoaderWrapper />
          ) : isLoading ? (
            <>
              <ZemplinLoaderWrapper />
              <FlexRowCenter>
                <TextP>{`${__(
                  'Prebieha presmerovanie na platobnú bránu...',
                )}`}</TextP>
              </FlexRowCenter>
              <FlexRowCenter>
                <TextP>
                  {`${__(
                    'Ak presmerovanie neprebehlo úspešne, kliknite na tento ',
                  )}`}
                  {prop(orderData, 'payment.payment_type') === 'VUB' && (
                    <StyledLink
                      href={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/payment/vubpay/${orderPublicId}?return_url=${
                        typeof window !== 'undefined' &&
                        window &&
                        window.location &&
                        window.location.origin &&
                        window.location.pathname
                          ? `${window.location.origin}${window.location.pathname}`
                          : ''
                      }`}
                    >
                      {__('odkaz')}
                    </StyledLink>
                  )}
                  {prop(orderData, 'payment.payment_type') === 'CSOB' && (
                    <StyledLink
                      href={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/payment/csob/${orderPublicId}?return_url=${
                        typeof window !== 'undefined' &&
                        window &&
                        window.location &&
                        window.location.origin &&
                        window.location.pathname
                          ? `${window.location.origin}${window.location.pathname}`
                          : ''
                      }`}
                    >
                      {__('odkaz')}
                    </StyledLink>
                  )}
                  {isPaymentTypeComgate(
                    prop(orderData, 'payment.payment_type'),
                  ) && (
                    <StyledLink
                      href={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/payment/comgate/${orderPublicId}?return_url=${
                        typeof window !== 'undefined' &&
                        window &&
                        window.location &&
                        window.location.origin &&
                        window.location.pathname
                          ? `${window.location.origin}${window.location.pathname}`
                          : ''
                      }`}
                    >
                      {__('odkaz')}
                    </StyledLink>
                  )}
                </TextP>
              </FlexRowCenter>
            </>
          ) : orderData ? (
            <FinishedOrder
              order={orderData}
              isFetching={isFetching}
              error={isError}
              paid={isPaid}
              isEmail={isEmail}
              retryPayment={this.retryPayment}
            />
          ) : (
            <InfoText
              type={InfoType.ERROR}
              info={__('Takáto objednávka neexistuje')}
            />
          )}
        </Wrapper>
      </>
    );
  }
  public retryPayment = () => {
    const {
      orderData,
      params: { orderPublicId },
    } = this.props;
    const paymentId = prop(orderData, 'payment.payment_type', null);
    const publicId = orderPublicId; //prop(orderData, 'public_id', null);
    if (paymentId && publicId && paymentId === 'VUB') {
      redirectVub(publicId);
    } else if (paymentId && publicId && paymentId === 'CSOB') {
      redirectCsob(publicId);
    } else if (paymentId && publicId && isPaymentTypeComgate(paymentId)) {
      redirectComgate(publicId);
    }
  };
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.link};
  text-decoration: underline;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedOrder' })(
    withRouter(FinishedOrderContainer),
  ),
);
