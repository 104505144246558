import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import API from '../../services/API';
import { __, __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import { ErrorAlert } from 'eshop-defaults';
import { Loader, TextP } from 'eshop-defaults/lib';
import { LogoutButton } from './MyAccount';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { StyledTitle } from '../../components/MyAccount/MyDashboard';
import { resolveLangByHostname } from '../../configureTrans';
import { hostnameSelector, langSelector, userSelector } from '../App/selectors';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  padding: 0;
  border-radius: ${rem(4)};

  ${({ theme }) => theme.mediab.l925`
   padding-top: ${rem(24)};
  `}
`;

const EmailWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

const AuthButtonWrapper = styled.div`
  margin-bottom: ${rem(25)};
  margin-top: ${rem(24)};
`;

const SuccessTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(25)};
`;

const PasswordInput = styled.input<{ isMobile: boolean }>`
  display: block;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(2)};
  padding: ${rem(16)};
  box-shadow: ${({ theme }) => theme.boxShadow.input};
  border: ${({ isMobile }) => (isMobile ? 'none' : '1px solid #cccccc')};
  height: ${rem(48)};
  max-width: ${({ isMobile }) => (isMobile ? 'initial' : rem(450))};
  line-height: ${rem(22)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 300;
  font-family: ${({ theme }) => theme.font.secondary};

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.font.secondary};
    color: ${({ theme }) => theme.colors.placeholder};
    font-weight: 300;
  }
`;

const StyledLabel = styled.label<{ marginBottom?: number }>`
  font-size: ${rem(16)};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? rem(marginBottom) : 0};
`;

const Required = styled(TextP)`
  color: rgba(255, 30, 69, 0.92);
`;

const ChangePasswordButton = styled(LogoutButton)`
  max-width: ${rem(320)};
  font-weight: 500;
  font-size: ${rem(16)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

interface NewPasswordBoxPropsIO {
  token: string;
  onOk: () => void;
  onSignUpLinkClick: () => void;
  dispatch: (a: any) => void;
  user: any;
  hostname: string;
  lang: string;
}

interface State {
  password: string;
  confirmPassword: string;
  oldPassword: string;
  error: string;
  passwordChanged: boolean;
  fetching: boolean;
}

class ChangePassword extends React.Component<
  NewPasswordBoxPropsIO & WithRouterProps,
  State
> {
  public static async getInitialProps({ dispatch }) {
    dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_CHANGE_PASSWORD));
    return;
  }

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      oldPassword: '',
      error: '',
      passwordChanged: false,
      fetching: false,
    };
  }

  public onSetPasswordClick = async () => {
    const { user, lang, hostname } = this.props;
    const { password, confirmPassword, oldPassword } = this.state;
    if (password === confirmPassword) {
      try {
        this.setState({ fetching: true });
        await API.changePassword(
          user.id,
          {},
          {
            new_password: password,
            current_password: oldPassword,
            password_check: confirmPassword,
            lang,
            domain_id: resolveLangByHostname(hostname),
          },
        );
        this.setState({ passwordChanged: true, fetching: false });
      } catch (exp) {
        this.setState({ error: exp.details.description, fetching: false });
      }
    } else {
      this.setState({ error: 'Heslá sa nezhodujú', fetching: false });
    }
  };

  public onPasswordChanged = e => {
    this.setState({ password: e.target.value });
  };

  public onConfirmPasswordChanged = e => {
    this.setState({ confirmPassword: e.target.value });
  };

  public onOldPasswordChanged = e => {
    this.setState({ oldPassword: e.target.value });
  };

  public onOk = () => {
    this.setState({ passwordChanged: false, error: '' });
    this.props.router.push(
      __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
    );
  };

  public render() {
    const {
      password,
      confirmPassword,
      oldPassword,
      error,
      fetching,
    } = this.state;
    return (
      <Loader loading={fetching}>
        <Wrapper>
          {!this.state.passwordChanged ? (
            <>
              {' '}
              {error ? <ErrorAlert message={error} /> : ''}
              <StyledTitle marginTop={0}>{__('Zmena hesla')}</StyledTitle>
              <EmailWrapper>
                <StyledLabel htmlFor="old-password" marginBottom={8}>
                  {`${__('Staré heslo')}`}
                  <Required>{`*`}</Required>
                </StyledLabel>
                <PasswordInput
                  type="password"
                  onChange={this.onOldPasswordChanged}
                  value={oldPassword}
                  name="old-password"
                />
              </EmailWrapper>
              <EmailWrapper>
                <StyledLabel htmlFor="new-password" marginBottom={8}>
                  {`${__('Nové heslo')}`}
                  <Required>{`*`}</Required>
                </StyledLabel>
                <PasswordInput
                  type="password"
                  onChange={this.onPasswordChanged}
                  value={password}
                  name="new-password"
                />
              </EmailWrapper>
              <EmailWrapper>
                <StyledLabel htmlFor="confirm-password" marginBottom={8}>
                  {`${__('Potvrdenie nového hesla')}`}
                  <Required>{`*`}</Required>
                </StyledLabel>
                <PasswordInput
                  type="password"
                  onChange={this.onConfirmPasswordChanged}
                  value={confirmPassword}
                  name="confirm-password"
                />
              </EmailWrapper>
              <AuthButtonWrapper>
                <ChangePasswordButton onClick={this.onSetPasswordClick}>
                  {' '}
                  {__('Uložiť')}{' '}
                </ChangePasswordButton>
              </AuthButtonWrapper>
            </>
          ) : (
            <div>
              <SuccessTextWrapper>
                {__('Heslo bolo úspešne zmenené')}
              </SuccessTextWrapper>
              <AuthButtonWrapper>
                <ChangePasswordButton onClick={this.onOk}>
                  {' '}
                  OK{' '}
                </ChangePasswordButton>
              </AuthButtonWrapper>
            </div>
          )}
        </Wrapper>
      </Loader>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    lang: langSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ChangePassword' })(withRouter(ChangePassword)),
);
