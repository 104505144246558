import API from '../../services/API';

export const sendCancelOrderRequest = async (orderPublicId: string) => {
  const response = await API.orderCancelRequest(orderPublicId);
  return response;
};

export const sendCancelOrder = async (orderPublicId: string, token: string) => {
  const response = await API.orderCancel(orderPublicId, token);
  return response;
};
