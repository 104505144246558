import * as React from 'react';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  tokenSelector,
  titleSelector,
  categoryProductsSelector,
  categoryProductsIsFetchingSelector,
  categoryDataSelector,
  subCategorySelector,
} from './selectors';
import {
  currencySelector,
  hostnameSelector,
  langChangedSelector,
  langSelector,
} from '../App/selectors';
import {
  loadCategoryProducts,
  loadCategory,
  loadCategoriesTree,
} from './actions';
import { WithRouterProps } from 'react-router';
import Category from '../../components/Category/Category';
import { connectSsr } from 'ssr-service';
import { addItemToCart } from '../Cart/cartSlice';
import {
  categoryFilterDataSelector,
  categoryChildrenCategoriesSelector,
  urlAttribsToObject,
} from './categorySlice';
import { productCategoriesAttribsSelector } from '../Category/selectors';
import { productCategoriesSelector } from '../Header/selectors';
import CategoryLanguageRedirectChecker from '../../components/Category/CategoryLanguageRedirectChecker';
import { mapCategoriesTreeByIdRecursive } from '../Header/reducer';
import { prop } from '../../utilities';

export interface CategoryProps {
  user: any;
  location: any;
  category: any;
  parentProps: any;
  loading: boolean;
  dispatch: any;
  lang: string;
  langChanged: boolean;
  defaultTitle: string;
  token: string;
  isLoading: boolean;
  limit: number;
  sort: string;
  sortDir: string;
  isFetching: boolean;
  offset: number;
  products: ThenArg<typeof API.searchProducts>;
  data: ThenArg<typeof API.loadCategory>;
  subTree: any;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  categories: ThenArg<typeof API.loadTree>;
  currency: string;
  byId: any;
  categoryAttribs: any;
  hostname: string;
  productCategories: any;
}

class CategoryContainer extends React.Component<
  CategoryProps & WithRouterProps
> {
  public static async getInitialProps(parentProps) {
    try {
      const {
        dispatch,
        location,
        params: { category_id, category_url, ...restOfUrl },
      } = parentProps;
      const {
        query: { offset, max, min, sort, sortDir },
        query,
      } = location;
      // dispatch(loadCategory(category_id || 0));
      // dispatch(loadCategoriesTree(category_id || 0));

      // await Promise.all([
      //   (dispatch(loadCategory(category_id || 0)),
      //   dispatch(loadCategoriesTree(category_id || 0)),
      //   dispatch(
      //     loadCategoryProducts(
      //       category_id,
      //       offset,
      //       { min, max },
      //       { sort, sortDir },
      //     ),
      //   )),
      // ]);

      // await dispatch(
      //   loadCategoryProducts(
      //     category_id,
      //     offset,
      //     { min, max },
      //     { sort, sortDir },
      //   ),
      // );

      let categoryUrl = category_url;
      if (category_id && !Number.isInteger(category_id)) {
        categoryUrl = `${category_id}/${categoryUrl}${
          prop(restOfUrl, 'splat') ? `/${prop(restOfUrl, 'splat')}` : ''
        }`;
      }

      await dispatch(
        loadCategoryProducts(categoryUrl, urlAttribsToObject(query)),
      );

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };

  public render() {
    const {
      dispatch,
      isFetching,
      products,
      data,
      subTree,
      user,
      filterData,
      childrenCategories,
      lang,
      langChanged,
      currency,
      byId,
      categoryAttribs,
      hostname,
      productCategories,
    } = this.props;
    return (
      <>
        <CategoryLanguageRedirectChecker
          lang={lang}
          langChanged={langChanged}
        />
        <Category
          hostname={hostname}
          apiUrl={process.env.REACT_APP_API_BASE_URL as string}
          query={this.getPaginationQuery()}
          dispatch={dispatch}
          isFetching={isFetching}
          products={products}
          data={data}
          subCategories={subTree}
          user={user}
          addToCart={this.addToCart}
          filterData={filterData}
          currency={currency}
          byId={mapCategoriesTreeByIdRecursive(productCategories)}
          lang={lang}
          categoryAttribs={categoryAttribs}
        />
      </>
    );
  }

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    langChanged: langChangedSelector(state),
    token: tokenSelector(state),
    isFetching: categoryProductsIsFetchingSelector(state),
    products: categoryProductsSelector(state),
    data: categoryDataSelector(state),
    subTree: subCategorySelector(state),
    filterData: categoryFilterDataSelector(state),
    childrenCategories: categoryChildrenCategoriesSelector(state),
    categories: productCategoriesSelector(state),
    categoryAttribs: productCategoriesAttribsSelector(state),
    currency: currencySelector(state),
    hostname: hostnameSelector(state),
    productCategories: productCategoriesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CategoryContainer' })(CategoryContainer),
);
