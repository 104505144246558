import {
  RECEIVE_HOME_BANNER_SUCCESS,
  RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  REQUEST_HOME_PRODUCT_LIST2,
  RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  REQUEST_HOME_PRODUCT_LIST1,
  RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  REQUEST_HOME_CATEGORIES,
  RECEIVE_HOME_CATEGORIES_SUCCESS,
  RECEIVE_HOME_CATEGORIES_ERROR,
  REQUEST_REVIEWS,
  RECEIVE_REVIEWS_SUCCESS,
  RECEIVE_REVIEWS_ERROR,
  REQUEST_NOT_FOUND_ARTICLES,
  RECEIVE_NOT_FOUND_ARTICLES_SUCCESS,
  RECEIVE_NOT_FOUND_ARTICLES_ERROR,
  REQUEST_BLOG_ARTICLES,
  RECEIVE_BLOG_ARTICLES_SUCCESS,
  RECEIVE_BLOG_ARTICLES_ERROR,
  REQUEST_HOME_FAVORITE_PRODUCTS,
  RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR,
  REQUEST_HOME_RECOMMENDED_PRODUCTS,
  RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR,
  REQUEST_HOME_TOP_TECHNOLOGIES,
  RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS,
  RECEIVE_HOME_TOP_TECHNOLOGIES_ERROR,
  REQUEST_HOME_SHOPPING_CONSULTANTS,
  RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS,
  RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR,
} from './constants';

const INITIAL_STATE: any = {
  newProducts: {
    isFetching: false,
    productsData: null,
  },
  saleProducts: {
    isFetching: false,
    productsData: null,
  },
  adBanner: null,
  topBrands: null,
  homeCategories: null,
  reviews: null,
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_HOME_SHOPPING_CONSULTANTS: {
      return {
        ...state,
        shoppingConsultants: {
          ...state.shoppingConsultants,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS: {
      return {
        ...state,
        shoppingConsultants: {
          ...state.shoppingConsultants,
          isFetching: false,
          articles: action.payload.articles,
        },
      };
    }

    case RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR: {
      return {
        ...state,
        shoppingConsultants: {
          ...state.shoppingConsultants,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    // ===================================================================

    case REQUEST_HOME_TOP_TECHNOLOGIES: {
      return {
        ...state,
        topTechnologies: {
          ...state.topTechnologies,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS: {
      return {
        ...state,
        topTechnologies: {
          ...state.topTechnologies,
          isFetching: false,
          articles: action.payload.articles,
        },
      };
    }

    case RECEIVE_HOME_TOP_TECHNOLOGIES_ERROR: {
      return {
        ...state,
        topTechnologies: {
          ...state.topTechnologies,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    // =====================================

    case REQUEST_HOME_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProducts: {
          ...state.recommendedProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        recommendedProducts: {
          ...state.recommendedProducts,
          isFetching: false,
          products: action.payload.products,
        },
      };
    }

    case RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR: {
      return {
        ...state,
        recommendedProducts: {
          ...state.recommendedProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_FAVORITE_PRODUCTS: {
      return {
        ...state,
        favoriteProducts: {
          ...state.favoriteProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        favoriteProducts: {
          ...state.favoriteProducts,
          isFetching: false,
          products: action.payload.products,
        },
      };
    }

    case RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR: {
      return {
        ...state,
        favoriteProducts: {
          ...state.favoriteProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_PRODUCT_LIST1: {
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST1_SUCCESS: {
      const productsData = {
        ...state.productList1.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST1_ERROR: {
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_PRODUCT_LIST2: {
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST2_SUCCESS: {
      const productsData = {
        ...state.productList2.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST2_ERROR: {
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    case RECEIVE_HOME_TOP_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: action.payload.topBrand,
      };
    }

    case REQUEST_HOME_CATEGORIES: {
      return {
        ...state,
        categoriesIsFetching: true,
      };
    }

    case RECEIVE_HOME_CATEGORIES_SUCCESS: {
      return {
        ...state,
        homeCategories: action.payload.homeCategories,
      };
    }

    case RECEIVE_HOME_CATEGORIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case REQUEST_REVIEWS: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: action.payload.reviews,
      };
    }

    case RECEIVE_REVIEWS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case REQUEST_NOT_FOUND_ARTICLES: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_NOT_FOUND_ARTICLES_SUCCESS: {
      return {
        ...state,
        notFoundArticles: action.payload.notFoundArticles,
      };
    }

    case RECEIVE_NOT_FOUND_ARTICLES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case REQUEST_BLOG_ARTICLES: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_BLOG_ARTICLES_SUCCESS: {
      return {
        ...state,
        articlesIsFetching: false,
        articles: action.payload.articles,
      };
    }

    case RECEIVE_BLOG_ARTICLES_ERROR: {
      return {
        ...state,
        articlesIsFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
