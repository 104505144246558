import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  ZemplinButton as Button,
  InfoText,
  // Accounts,
  TextP,
  // AddAccount,
} from 'eshop-defaults';
// import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  userId: number;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  isFetching: boolean;
  refreshUsers: () => void;
}

// function reducer(state, { field, value }) {
//   return { ...state, [field]: value };
// }

function AccountManagement({
  userId,
  childUsers,
  isFetching,
  refreshUsers,
}: Props) {
  // const initialState = {
  //   email: '',
  //   name: '',
  //   surname: '',
  //   password: '',
  // };

  // const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated /*setDataUpdated*/] = React.useState(false);
  const [error /*setError*/] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);

  const handleModal = () => {
    document.body.style.overflow = modalVisible ? 'visible' : 'hidden';
    setModalVisible(!modalVisible);
  };

  // const onChange = e => {
  //   dispatch({ field: e.target.name, value: e.target.value });
  // };
  //
  // const resetStateToInitial = () => {
  //   dispatch({ field: 'email', value: '' });
  //   dispatch({ field: 'name', value: '' });
  //   dispatch({ field: 'surname', value: '' });
  //   dispatch({ field: 'password', value: '' });
  // };

  // const handleAddAccount = async () => {
  //   setIsSaving(true);
  //   try {
  //     await API.createChildUser(
  //       userId,
  //       {},
  //       {
  //         ...state,
  //       },
  //     );
  //     refreshUsers();
  //     resetStateToInitial();
  //   } catch (e) {
  //     setIsSaving(false);
  //     setDataUpdated(false);
  //     setError({ ...e.details });
  //     return;
  //   }
  //
  //   setError(null);
  //   setIsSaving(false);
  //   handleModal();
  //   setDataUpdated(true);
  // };

  // const handleDeleteAccount = async (childId: number) => {
  //   try {
  //     await API.deleteChildUser(userId, childId, {});
  //   } catch (e) {}
  //   refreshUsers();
  // };

  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={32}>
        {__('Správa účtov')}
      </Title>
      <Text>
        {__(
          'Vytvorte si účty v rámci vašej organizácie a majte prehľad o všetkých objednávkach.',
        )}
      </Text>
      {/*<Accounts*/}
      {/*  handleDeleteAccount={handleDeleteAccount}*/}
      {/*  isFetching={isFetching}*/}
      {/*  accounts={childUsers}*/}
      {/*/>*/}
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__(
            'Účet bol úspešne pridaný. Po prihlásení si používateľ bude môcť zmeniť heslo.',
          )}
        />
      )}

      <SaveButton onClick={handleModal}>{__('Pridať účet')}</SaveButton>
      {/*{modalVisible && (*/}
      {/*  <AddAccount*/}
      {/*    isSaving={isSaving}*/}
      {/*    state={state}*/}
      {/*    error={error}*/}
      {/*    onChange={onChange}*/}
      {/*    handleClose={handleModal}*/}
      {/*    handleAddAccount={handleAddAccount}*/}
      {/*  />*/}
      {/*)}*/}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const SaveButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(15)};
  width: 100%;
  max-width: ${rem(160)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${rem(14)};
  border-radius: ${rem(2)};
  margin: 0 0 ${rem(24)};

  &:disabled {
    background-color: #dcd6d6;
  }

  ${({ theme }) => theme.mediab.m580`
    max-width: initial;
    margin-top: ${rem(32)};
  `}
`;

const Text = styled(TextP)`
  font-weight: 300;
  font-size: ${rem(14)};
`;

export default AccountManagement;
