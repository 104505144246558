import { BreadCrumbType } from './../BreadCrumb/breadCrumbSlice';
import * as actionTypes from './constants';
import { langSelector } from '../Category/selectors';
import { prop } from 'eshop-defaults';
import { resolveCurrentThemeFromCategory } from '../../utilities/category';
import { setCurrentTheme } from '../App/actions';
import { currencySelector, hostnameSelector } from '../App/selectors';
import {
  DEFAULT_CATEGORY_ID,
  extractBaseDomain,
  resolveDomainByHostname,
} from '../../configureTrans';
import { setBreadCrumbPath } from '../BreadCrumb/breadCrumbSlice';
import * as cookie from 'react-cookies';
import { cartIdSelector } from '../Cart/cartSlice';
import { loadCartCookie } from '../Cart/helpers';

const requestProductDetail = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL,
});

const receiveProductDetailSuccess = productDetail => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_SUCCESS,
  payload: {
    productDetail,
  },
});

const receiveProductDetailError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetail = id => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(requestProductDetail());

      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const hostname = hostnameSelector(getState());
      const cartId = loadCartCookie();

      const productDetail = await API.loadProduct(
        id,
        {
          withContents: '1',
          withParentCategories: '1',
          topCategoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          domainId: resolveDomainByHostname(hostname),
          published: 0,
          cartId,
          withLangVersions: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );
      dispatch(setBreadCrumbPath(BreadCrumbType.PRODUCT, productDetail));
      await dispatch(receiveProductDetailSuccess(productDetail));
      const productCategories = prop(productDetail, 'parent_categories');
      if (productCategories) {
        const uniqueCatIds: string[] = productCategories.map(
          p => p.category_number,
        );
        const theme = resolveCurrentThemeFromCategory(uniqueCatIds);
        dispatch(setCurrentTheme(theme));
      }
    } catch (e) {
      dispatch(receiveProductDetailError(e));
    }
  };
};

const requestProductDetailRelated = () => ({
  type: actionTypes.REQUEST_PRODUCT_RELATED_DETAIL,
});

const receiveProductDetailRelatedSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_RELATED_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailRelatedConnectionsSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_RELATED_CONNECTIONS_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailRelatedColorsSuccess = colors => ({
  type: actionTypes.RECEIVE_PRODUCT_RELATED_COLORS_DETAIL_SUCCESS,
  payload: {
    colors,
  },
});

const receiveProductDetailRelatedError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_RELATED_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailRelatedColors = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailRelated());

      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const currency = currencySelector(getState());

      const colors = await API.loadProductConnections(
        id,
        {
          connectionType: 'COLORS',
          limit: 10,
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          domainId: resolveDomainByHostname(hostname),
          goodsWithStores: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );

      dispatch(receiveProductDetailRelatedColorsSuccess(colors));
    } catch (e) {
      receiveProductDetailRelatedError(e);
    }
  };
};

export const loadProductDetailRelatedConnections = id => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const hostname = hostnameSelector(getState());
      const cartId = cartIdSelector(getState());

      const connections = await API.loadProductConnections(
        id,
        {
          connectionType: 'ALTERNATIVE',
          limit: 99,
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          domainId: resolveDomainByHostname(hostname),
          goodsWithStores: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );

      const connectionFilterAvailableProducts = connections.products.filter(
        p => p.main_good.on_stock_count > 0,
      );
      const connectionFilterNotAvailableProducts = connections.products.filter(
        p => p.main_good.on_stock_count === 0,
      );

      connections.products = connectionFilterAvailableProducts;
      connections.total = connectionFilterAvailableProducts.length;

      if (connections.total < 6) {
        const product = await API.loadProduct(
          id,
          {
            withParentCategories: '1',
            domainId: resolveDomainByHostname(hostname),
            published: 0,
            cartId,
          },
          { xAcceptLanguage: lang, xCurrency: currency },
        );

        const firstPublish = product.publish[0];
        let saleEnded = false;
        if (firstPublish && !firstPublish.publish) {
          saleEnded = true;
        }

        if (
          product &&
          product.parent_categories &&
          product.parent_categories.length &&
          saleEnded === true
        ) {
          const reversetParentCategories = product.parent_categories.reverse();

          for (const parentCategory of reversetParentCategories) {
            try {
              const elasticCategory1 = await API.loadElasticCategoryProducts(
                parentCategory.category_id,
                {
                  limit: 6,
                  domainId: resolveDomainByHostname(hostname),
                  langId: lang,
                  onlySubcategories: '1',
                  published: 1,
                  withPublish: 1,
                  sort: 'alternative_available_desc_price_desc',
                  max: product.main_good.final_price,
                  onlyWithoutParent: 1,
                  cartId,
                },
              );

              const elasticCategory2 = await API.loadElasticCategoryProducts(
                parentCategory.category_id,
                {
                  limit: 6,
                  domainId: resolveDomainByHostname(hostname),
                  langId: lang,
                  onlySubcategories: '1',
                  published: 1,
                  withPublish: 1,
                  sort: 'alternative_available_desc_price_desc',
                  min: product.main_good.final_price,
                  onlyWithoutParent: 1,
                  cartId,
                },
              );
              const elasticCategoryProducts = elasticCategory1.products.products.concat(
                elasticCategory2.products.products,
              );
              elasticCategoryProducts.sort(function(a, b) {
                if (
                  a.product_detail.main_good.is_on_stock === true &&
                  b.product_detail.main_good.is_on_stock === false
                ) {
                  return -1;
                } else if (
                  a.product_detail.main_good.is_on_stock === false &&
                  b.product_detail.main_good.is_on_stock === true
                ) {
                  return 1;
                } else {
                  return (
                    Math.abs(
                      a.main_good_price - product.main_good.final_price,
                    ) -
                    Math.abs(b.main_good_price - product.main_good.final_price)
                  );
                }
              });

              if (elasticCategoryProducts && elasticCategoryProducts.length) {
                for (const eproduct of elasticCategoryProducts) {
                  if (
                    eproduct.product_id === product.product_id ||
                    connections.products
                      .map(p => p.product_id)
                      .filter(pid => pid === eproduct.product_id).length > 0
                  )
                    continue;

                  let productDetail = eproduct.product_detail;
                  productDetail.name = productDetail.product_name;
                  productDetail.picture = eproduct.picture;
                  connections.products.push(eproduct.product_detail);
                  connections.total += 1;
                  if (connections.total >= 6) {
                    break;
                  }
                }
              }

              if (connections.total >= 6) {
                break;
              }
            } catch (e) {}
          }
        }

        if (connectionFilterNotAvailableProducts) {
          const fAvailableProducts = connections.products.filter(
            p =>
              p.has_stock_count ||
              (p.main_good && p.main_good.on_stock_count > 0),
          );
          const fNotAvailableProducts = connections.products.filter(
            p =>
              !(
                p.has_stock_count ||
                (p.main_good && p.main_good.on_stock_count > 0)
              ),
          );
          const cNotAvailableProducts = connectionFilterNotAvailableProducts.concat(
            fNotAvailableProducts,
          );
          const fProducts = fAvailableProducts.concat(cNotAvailableProducts);
          connections.products = fProducts.slice(0, 6);
          connections.total = connections.products.length;
        }
      }

      dispatch(receiveProductDetailRelatedConnectionsSuccess(connections));
    } catch (e) {
      receiveProductDetailRelatedError(e);
    }
  };
};

const requestProductBazarRelated = () => ({
  type: actionTypes.REQUEST_PRODUCT_BAZAR,
});

const receiveProductBazarRelatedSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_BAZAR_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductBazarRelatedError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_BAZAR_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailBazar = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductBazarRelated());

      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const currency = currencySelector(getState());
      const connections = await API.loadProductConnections(
        id,
        {
          connectionType: 'BAZAR',
          limit: 10,
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          domainId: resolveDomainByHostname(hostname),
          goodsWithStores: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );
      dispatch(receiveProductBazarRelatedSuccess(connections));
    } catch (e) {
      dispatch(receiveProductBazarRelatedError(e));
    }
  };
};

const requestProductDetailCrosssell = () => ({
  type: actionTypes.REQUEST_PRODUCT_CROSSSELL_DETAIL,
});

const receiveProductDetailCrosssellSuccess = connections => ({
  type: actionTypes.RECEIVE_PRODUCT_CROSSSELL_DETAIL_SUCCESS,
  payload: {
    connections,
  },
});

const receiveProductDetailCrosssellError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_CROSSSELL_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailCrosssell = id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailCrosssell());

      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const hostname = hostnameSelector(getState());
      const connections = await API.loadProductConnections(
        id,
        {
          connectionType: 'CROSSSELL',
          limit: 99,
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          domainId: resolveDomainByHostname(hostname),
          goodsWithStores: '1',
        },
        { xAcceptLanguage: lang, xCurrency: currency },
      );
      dispatch(receiveProductDetailCrosssellSuccess(connections));
    } catch (e) {
      dispatch(receiveProductDetailCrosssellError(e));
    }
  };
};

const requestProductDetailArticles = () => ({
  type: actionTypes.REQUEST_PRODUCT_DETAIL_ARTICLES,
});

const receiveProductDetailArticlesSuccess = (articles, total) => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_SUCCESS,
  payload: {
    articles,
    total,
  },
});

const receiveProductDetailArticlesError = error => ({
  type: actionTypes.RECEIVE_PRODUCT_DETAIL_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadProductDetailArticles = (id, offset) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestProductDetailArticles());
      dispatch(setProductDetailArticlesFilters({ offset }));

      const lang = langSelector(getState());
      const currency = currencySelector(getState());
      const productDetailArticles = await API.loadContentConnections(
        id,
        { offset, connectionType: 'READTOO' },
        { xAcceptLanguage: lang, xCurrency: currency },
      );
      const web_content_connections =
        productDetailArticles.web_content_connections;
      const total = prop(productDetailArticles, 'total', 0);

      dispatch(
        receiveProductDetailArticlesSuccess(web_content_connections, total),
      );
    } catch (e) {
      dispatch(receiveProductDetailArticlesError(e));
    }
  };
};

export const setProductDetailArticlesFilters = filters => ({
  type: actionTypes.SET_PRODUCT_DETAIL_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});
