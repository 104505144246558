import React, { useEffect, useState } from 'react';
import { IconImg, DsiSvgIcon as SvgIcon, OutsideClick } from 'eshop-defaults';
import { rem } from 'polished';
import styled from 'styled-components';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import {
  setLangCookie,
  getLangCookie,
  getCurrencyFromLang,
  setCurrencyCookie,
} from '../../utilities';
import {
  setCurrency,
  setLanguage,
  setLanguageWasChanged,
} from '../../containers/App/actions';
import { resolveLangByHostname } from '../../configureTrans';
import { withRouter, WithRouterProps } from 'react-router';
import { __ } from 'react-i18n';

interface Props {
  dispatch: any;
  lang: string;
}

function HeaderLangButton({
  dispatch,
  lang,
  location,
}: Props & WithRouterProps) {
  const countryFlags = {
    sk: 'slovakia.svg',
    cz: 'czech-republic.svg',
  };
  const altText = {
    sk: __('Slovenská verzia'),
    cz: __('Česká verzia'),
  };
  const [isOpen, setIsOpen] = useState(false);

  const closeNav = e => {
    // e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
  };

  const toggleNav = e => {
    // e.preventDefault();
    e.stopPropagation();
    // if (!isOpen) {
    //   resetState();
    // }
    setIsOpen(!isOpen);
  };

  const resetState = () => {
    setIsOpen(!isOpen);
  };

  const handleLangChange = e => {
    const newLang = e.target.id;

    if (lang !== newLang) {
      // setLangCookie(newLang);
      // setActiveLang(newLang);
      closeNav(e);
      // dispatch(setLanguage(newLang));

      // const newCurrency = getCurrencyFromLang(newLang);
      // setCurrencyCookie(newCurrency);
      // dispatch(setCurrency(newCurrency));

      dispatch(setLanguageWasChanged());
    }
  };

  const imgSrc = `/images/countries/${countryFlags[lang]}`;
  return (
    <Wrapper isOpen={isOpen}>
      <OutsideClick handleFunction={closeNav}>
        <LangButton onClick={toggleNav}>
          <StyledIconImg
            title={altText[lang]}
            alt={altText[lang]}
            src={imgSrc}
          />
          {isOpen ? (
            <UpArrowIcon icon={IconType.upArrow}></UpArrowIcon>
          ) : (
            <DownArrowIcon icon={IconType.downArrow}></DownArrowIcon>
          )}
        </LangButton>
        <DropWrapper isOpen={isOpen}>
          {lang !== 'sk' && (
            <DroppedLangButton id="sk" onClick={handleLangChange}>
              <StyledIconImg
                title={__('Prejsť na slovenskú verziu')}
                alt={__('Prejsť na slovenskú verziu')}
                id="sk"
                src={`/images/countries/${countryFlags['sk']}`}
              />
            </DroppedLangButton>
          )}
          {lang !== 'cz' && (
            <DroppedLangButton id="cz" onClick={handleLangChange}>
              <StyledIconImg
                title={__('Prejsť na českú verziu')}
                alt={__('Prejsť na českú verziu')}
                id="cz"
                src={`/images/countries/${countryFlags['cz']}`}
              />
            </DroppedLangButton>
          )}
        </DropWrapper>
      </OutsideClick>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isOpen: boolean }>`
  color: #ffffff;
  border: ${({ theme }) => theme.borders.lang};
  border-radius: ${rem(4)};
  box-shadow: 0px 1px 4px #00000014;
  opacity: 1;
  margin-left: ${rem(16)};
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen
      ? `border-bottom-color: transparent; border-radius: ${rem(4)} ${rem(
          4,
        )} 0 0`
      : ''}
`;
const DownArrowIcon = styled(SvgIcon)`
  position: relative;
  top: 10px;
  left: 10px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.textPrimary};
`;
const UpArrowIcon = styled(SvgIcon)`
  position: relative;
  top: 8px;
  left: 3px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.textPrimary};
`;
const LangButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${rem(15)} ${rem(11)};
  cursor: pointer;
`;

const DroppedLangButton = styled(LangButton)`
  border-top: ${({ theme }) => theme.borders.primary};
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #f6f7fb;
  }
`;

const DropWrapper = styled.div<{ isOpen: boolean }>`
  color: #ffffff;
  border: ${({ theme }) => theme.borders.lang};
  border-top: none;
  background-color: #ffffff;
  position: absolute;
  width: ${rem(70)};
  z-index: 100;
  display: ${({ isOpen }) => (!isOpen ? 'none' : 'block')};
  margin-left: ${rem(-1)};
  border-radius: 0 0 ${rem(4)} ${rem(4)};
`;

const StyledIconImg = styled(IconImg)`
  cursor: pointer;
  width: ${rem(22)};
`;

const WithRouter = withRouter(HeaderLangButton);
export { WithRouter as HeaderLangButton };
