import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  FlexCol,
  FlexRow,
  CorporateArticleList as ArticleList,
  SitemapList,
  DsiPagination as Pagination,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import {
  loadCMSCategoriesTree,
  loadCMSArticles,
  loadBlogMenu,
} from './actions';
import {
  sitemapSelector,
  allArticlesSelector,
  sitemapIsFetchingSelector,
  articlesIsFetchingSelector,
  sitemapByIdSelector,
  linksIsFetchingSelector,
  allLinksSelector,
  currentArticleNameSelector,
} from './selectors';
import API, { ThenArg } from '../../services/API';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import { formatDate, prop, stripHtmlTags } from '../../utilities';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { Navigation } from '../../components/Navigation/Navigation';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { langSelector } from '../App/selectors';
import {
  resolveDomainByHostname,
  resolveLangByHostname,
} from '../../configureTrans';
import { __ } from 'react-i18n';
import { ItemListMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { resolveArticleContentDetails } from '../../utilities/article';
import { getImagePath } from '../../utilities/product';

interface Props {
  dispatch: (action: any) => void;
  sitemap: ThenArg<typeof API.loadSitemapTree>['sitemap_tree'];
  isFetching: boolean;
  allArticles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  sitemapsById: object;
  isFetchingLinks: boolean;
  links: ThenArg<Array<{ name: string; link: string }>>;
  currentArticleName: string;
  lang: string;
}

class CMSArticles extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, location, params } = props;
    const {
      query: { offset },
    } = location;
    const correctLang = props.lang || resolveLangByHostname(props.hostname);
    let sitemapId = params.sitemapId;
    if (!sitemapId) {
      const urlMap = await API.getUrlMap(
        {
          url: props.location.pathname.replace('/preview/', ''),
          domainId: resolveDomainByHostname(props.hostname),
        },
        { xAcceptLanguage: correctLang },
      );
      sitemapId = urlMap.data.sitemap_id;
    }

    dispatch(
      setBreadCrumbPath(BreadCrumbType.CMS_ARTICLES, {
        name: 'Blog',
        path: location.pathname,
      }),
    );

    await Promise.all([
      dispatch(
        loadCMSArticles(sitemapId, 15, offset),
        dispatch(loadBlogMenu(sitemapId)),
      ),
    ]);
  }

  public render() {
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: this.props.currentArticleName || __('Blog') }}
            noindex={false}
            nofollow={false}
          />
          <HiddenH1>{__('Blog')}</HiddenH1>
          {/* {this.renderCategories()} */}
          {this.renderArticles()}
          {this.renderMicrodata()}
        </Wrapper>
      </>
    );
  }

  public renderCategories = () => {
    const { sitemap } = this.props;
    const subid = this.props.location.query.subid;
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
      }));

    return (
      <SitemapList
        currentId={(subid && subid.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
      />
    );
  };

  public renderMicrodata = () => {
    const { allArticles, location, lang } = this.props;

    const url = prop(location, 'pathname');

    if (allArticles && allArticles.articles) {
      const blogPosting = allArticles.articles.map(contentData => {
        const {
          name,
          body,
          annotation,
          anotation,
        } = resolveArticleContentDetails(contentData, null);

        const calculatedImages = prop(
          contentData,
          'calculated_meta.images',
          [],
        );
        const calculatedVideos = prop(
          contentData,
          'calculated_meta.videos',
          [],
        );
        const calculatedYoutubeVideos = prop(
          contentData,
          'calculated_meta.youtubeVideos',
          [],
        );
        const photogallery = prop(contentData, 'photogallery');
        const titleImage = prop(photogallery[0], 'document_path');
        const createdAt = prop(contentData, 'created_date');
        const lastUpdate = prop(contentData, 'last_update');
        const author = prop(contentData, 'author');
        const tags = prop(contentData, 'tags');

        return {
          headline: name,
          imageObject:
            calculatedImages && calculatedImages.length
              ? calculatedImages.map(item => {
                  return {
                    url: item.url,
                    width: item.width,
                    height: item.height,
                  };
                })
              : [],
          thumbnailUrl: titleImage
            ? getImagePath(titleImage, { width: 100, height: 100 }, false)
            : undefined,
          datePublished: createdAt ? formatDate(createdAt, 'MM/DD/YYYY') : '',
          description: stripHtmlTags(annotation),
          dateModified: lastUpdate ? formatDate(lastUpdate, 'MM/DD/YYYY') : '',
          author: author,
          keywords: tags && tags.length ? tags.map(tag => tag.name) : undefined,
          videoObject: calculatedVideos
            .map(item => {
              return { contentUrl: item.url };
            })
            .concat(
              calculatedYoutubeVideos.map(item => {
                return {
                  contentUrl: item.url,
                  thumbnailUrl: item.thumbnailUrl,
                  description: item.description ? item.description : item.name,
                  uploadDate: item.uploadDate,
                  name: item.name,
                };
              }),
            ),
          inLanguage: lang
            ? lang === 'sk'
              ? 'sk-SK'
              : lang === 'cz'
              ? 'cs-CZ'
              : undefined
            : undefined,
        };
      });

      return <ItemListMicrodata url={url} blogPostings={blogPosting} />;
    } else {
      return <></>;
    }
  };

  public renderArticles = () => {
    const {
      allArticles,
      isFetchingArticles,
      location,
      sitemapsById,
    } = this.props;
    const articles = prop(allArticles, 'articles');
    const total = prop(allArticles, 'total', 0);
    // const subid = location.query.subid ? location.query.subid.toString() : '-1';
    const offset = prop(location, 'query.offset', 0);

    if (isFetchingArticles || articles === null) {
      return <ZemplinLoaderWrapper />;
    }

    return (
      <ContentWrapper>
        {/* <NavigationWrapper>{this.renderNavigation()}</NavigationWrapper> */}
        <ArticlesWrapper>
          {/* <Title>{`${(this.props as any).route.props.name}`}</Title> */}
          <ArticleList
            sitemapsById={sitemapsById}
            articles={articles}
            isFetching={isFetchingArticles}
            hasListItems={true}
          />
          <Pagination
            query={this.getPaginationQuery()}
            totalItems={total}
            limit={15}
            offset={offset}
            onOffsetChange={this.handleOffsetChange}
          />
        </ArticlesWrapper>
      </ContentWrapper>
    );
  };

  public handleOffsetChange = (newOffset: number) => {
    const subid = this.props.location.query.subid;
    const searchQuery = subid
      ? `?subid=${subid}&offset=${newOffset}`
      : `?offset=${newOffset}`;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: searchQuery,
      }),
    );
  };

  public handleCategoryChange = async (newId: number | string) => {
    // const offset = this.props.location.query.offset;
    const { location, dispatch } = this.props;
    const searchQuery = `?subid=${newId}`;
    dispatch(
      push({
        pathname: location.pathname,
        search: searchQuery,
      }),
    );
  };

  public getPaginationQuery = () => {
    const { location } = this.props;
    const {
      query: { subid },
    } = location;

    return subid
      ? `${location.pathname}?subid=${subid}`
      : `${location.pathname}?`;
  };

  public renderNavigation = () => {
    const { links, isFetchingLinks } = this.props;
    return isFetchingLinks ? (
      <Navigation links={[]} location={this.props.location} />
    ) : (
      <Navigation links={links} location={this.props.location} />
    );
  };
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0 ${rem(12)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(16)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${rem(80)};
  flex-direction: row;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     flex-flow: column;
  `}
`;

const ArticlesWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(20)};

  ${({ theme }) => theme.mediab.l1150`
    padding: 0 ${rem(20)};
  `}

  ${({ theme }) => theme.mediab.l925`
    padding: 0;
  `}

  ${({ theme }) => theme.mediab.m580`
    padding-top: ${rem(16)};
  `}
`;

const NavigationWrapper = styled(FlexRow)`
  max-width: ${rem(304)};
  flex: 1 1 ${rem(304)};

  ${({ theme }) => theme.mediab.l925`
  flex: 1;
  margin: 0 auto;
  margin-bottom: ${rem(32)};
  width: 100%;
  `}
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

const HiddenH1 = styled.h1`
  display: none;
`;

const mapStateToProps = state => {
  return {
    sitemap: sitemapSelector(state),
    isFetching: sitemapIsFetchingSelector(state),
    allArticles: allArticlesSelector(state),
    isFetchingArticles: articlesIsFetchingSelector(state),
    sitemapsById: sitemapByIdSelector(state),
    isFetchingLinks: linksIsFetchingSelector(state),
    links: allLinksSelector(state),
    currentArticleName: currentArticleNameSelector(state),
    lang: langSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CMSArticles' })(withRouter(CMSArticles)),
);
