import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Picture } from 'eshop-defaults';

interface Props {
  src: string;
  openModal: (array, index) => void;
}

const LightBoxImage: React.SFC<Props> = ({ src, openModal }) => {
  const clickHandler = () => {
    openModal([{ document_path: src }], 0);
  };

  return (
    <PictureWrapper onClick={clickHandler}>
      <BasePicture src={src} />
    </PictureWrapper>
  );
};

const BasePicture = styled.img``;

const PictureWrapper = styled.div`
  cursor: pointer;
`;

export default LightBoxImage;
